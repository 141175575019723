import {WabelModel} from './wabel-model.abstract';
import {Member} from './member';
import {Registration} from './registration';
import {Meeting} from './meeting';
import {EventExpert} from './event_expert';
import {EventWorkshop} from './event_workshop';
import {Summit} from './summit';
import {SummitRegistration} from './summit_registration';
import {EventFile} from './event-file';
import {EventOrganizer} from './event_organizer';
import {MeetingTimeRange} from './meeting_time_range';
import {Tag} from './tag';
import {EventParticipation} from './event_participation';
import {EventType} from './event_type';
import * as moment from 'moment';

export class Event extends WabelModel {
  constructor(obj?: any) {
    super(obj);
    if (obj) {
      this.participants = obj.participants ? obj.participants.map(registration => new Registration(registration)) : [];
      this.rootCategories = obj.rootCategories && obj.rootCategories.items ? obj.rootCategories.items.map(category => new Tag(category)) : [];
      this.categoryCount = obj.rootCategories && obj.rootCategories.count ? obj.rootCategories.count : 0;
      this.categories = obj.categories && obj.categories.items ? obj.categories.items.map(category => new Tag(category)) : [];
      this.summits = obj.summits && obj.summits.items ? obj.summits.items.map(summit => new Summit(summit)) : [];
      this.experts = obj.experts ? obj.experts.map(expert => new EventExpert(expert)) : [];
      this.workshops = obj.workshops ? obj.workshops.map(workshop => new EventWorkshop(workshop)) : [];
      this.summitRegistrations = obj.summitRegistrations ? obj.summitRegistrations.map(sr => new SummitRegistration(sr)) : [];
      this.eventFiles = obj.eventFiles && obj.eventFiles ? obj.eventFiles.map(file => new EventFile(file)) : [];
      this.meetingTimeRanges = obj.meetingTimeRanges && obj.meetingTimeRanges.items ? obj.meetingTimeRanges.items.map(mtr => new MeetingTimeRange(mtr)) : [];
      this.eventOrganizer = obj.eventOrganizer ? new EventOrganizer(obj.eventOrganizer) : null;
      this.potentialOwners = obj.potentialOwners ? obj.potentialOwners.map(saleContact => new Member(saleContact)) : [];
      this.targetConsumers = obj.targetConsumers && obj.targetConsumers.items ? obj.targetConsumers.items.map(targetConsumer => new Tag(targetConsumer)) : [];
      this.rangeOfProducts = obj.rangeOfProducts && obj.rangeOfProducts.items ? obj.rangeOfProducts.items.map(rangeOfProduct => new Tag(rangeOfProduct)) : [];
      this.nutritionalInformations = obj.nutritionalInformations && obj.nutritionalInformations.items ? obj.nutritionalInformations.items.map(nutritionalInformation => new Tag(nutritionalInformation)) : [];
      this.qualityLabels = obj.qualityLabels && obj.qualityLabels.items ? obj.qualityLabels.items.map(qualityLabel => new Tag(qualityLabel)) : [];
      this.exportCertifications = obj.exportCertifications && obj.exportCertifications.items ? obj.exportCertifications.items.map(exportCertification => new Tag(exportCertification)) : [];
      this.eventParticipation = obj.eventParticipation && obj.eventParticipation.items ? obj.eventParticipation.items.map(participation => new EventParticipation(participation)) : [];
      this.eventType = obj.eventType ? new EventType(obj.eventType) : null;
    }
  }

  name: string;
  vimeoUrl: string;
  description: string;
  fullname: string;
  startDate: string;
  endDate: string;
  dates: string;
  eventCode: string;
  catalogReady: boolean;
  anonymousCatalogReady: boolean;
  selectionReady: boolean;
  agendaReady: boolean;
  meetingReschedulerOpened: boolean;
  meetingConfirmationsOpened: boolean;
  removeMeetingAllowed: boolean;
  participants: Registration[] = [];
  participantNumber: number;
  meetings: Meeting[] = [];
  meetingNumber: number;
  meetingNotificationsNumber: number;
  summitRegistrations: SummitRegistration[] = [];
  categories: Tag[] = [];
  rootCategories: Tag[] = [];
  status: string;
  diaryBuyersNumber: number;
  planningImageUrl: string;
  floorMapImageUrl: string;
  experts: EventExpert[] = [];
  workshops: EventWorkshop[] = [];
  selectionNumber: number;
  selectionNumberForFinishedBuyerSelection: number;
  numberOfVisitorBuyers: number;
  suppliersNumber: number;
  buyersNumberNotstartedSelecting: number;
  buyersNumberStartedSelecting: number;
  buyersNumberFinishedSelecting: number;
  dashboardChartValues: string;
  summits: Summit[] = [];
  location: string;
  displayOnSearch: boolean;
  registrationsOpen: boolean;
  timezone: string;
  badgeTemplateUrl: string;
  backgroundImageUrl: string;
  squareImageUrl: string;
  eventFiles: EventFile[] = [];
  isOnline: boolean;
  sendMeetingNotification: boolean;
  eventOrganizer: EventOrganizer;
  generateMeetingStatusNotifications: boolean;
  rescheduleMeetingNotifications: boolean;
  allowSampleRequests: boolean;
  meetingDuration: number;
  meetingBreakTime: number;
  meetingNumberTreshold: number;
  meetingTimeRanges: MeetingTimeRange[];
  helpdeskActivated: boolean;
  meetingExtraTime: number;
  ratingReady: boolean;
  ratingReadyForEssentialAndPremiumOnly: boolean;
  defaultNbOfParticipants: number;
  potentialOwners: Member[];
  hasRegistrationWithoutOwners: boolean;
  ispackaging: boolean;
  categoryCount: number;
  participantCount: number;
  summitRegistrationCount: number;
  eventParticipation: EventParticipation[];
  eventType: EventType;
  adminNotificationCc: string;
  potentialTypes: string[];

  targetConsumers: Tag[];
  rangeOfProducts: Tag[];
  nutritionalInformations: Tag[];
  qualityLabels: Tag[];
  exportCertifications: Tag[];

  // Only used in live/agenda
  eventDates: any;

  lastMeetingGenerationDate: string;

  sendFollowupNotification: boolean;
  sendFollowupNotificationForEssentialPremiumOnly: boolean;
  forceOpenMeetingInNewTab: boolean;

  sendStartNotification: boolean;
  sendEndNotification: boolean;
  hostSurveyUrl: boolean;
  guestSurveyUrl: boolean;
  enableMeetingRoomForGuests: boolean;
  isSourcingDay: boolean;
  registrationDeadline: string;

  private startEventTZ: Date;
  private endEventTZ: Date;

  get id(): string {
    return this.eventCode;
  }

  set id(id: string) {
    this.eventCode = id;
  }

  get formattedStartDate(): string {
    return new Date(this.startDate).toLocaleDateString();
  }

  get start(): Date {
    return new Date(this.startDate);
  }

  get end(): Date {
    return new Date(this.endDate);
  }

  get isFinished(): boolean {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    const comparisonDate = new Date(this.endDate);
    comparisonDate.setHours(0, 0, 0, 0);
    return currentDate > comparisonDate;
  }

  hasStarted(daysToSubtract: number = 0): boolean {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    const comparisonDate = new Date(this.startDate);
    comparisonDate.setHours(0, 0, 0, 0);
    comparisonDate.setDate(comparisonDate.getDate() - daysToSubtract);
    return currentDate >= comparisonDate;
  }

  get calculatedMeetingDuration(): number {
    return this.meetingDuration - this.meetingBreakTime;
  }

  get hasBadgeTemplate(): boolean {
    return !!this.badgeTemplateUrl;
  }

  startDateEvent(): Date {
    if (this.startEventTZ) {
      return this.startEventTZ;
    }
    let computerTimeZone = moment.tz.guess();
    let dateString = this.startDate.split('+')[0];
    const eventDateTz = moment.tz(dateString, this.timezone);
    this.startEventTZ = moment.tz(eventDateTz.format('YYYY-MM-DDTHH:mm:ss'), computerTimeZone).toDate();
    return this.startEventTZ;
  }

  endDateEvent() {
    if (this.endEventTZ) {
      return this.endEventTZ;
    }
    let computerTimeZone = moment.tz.guess();
    let dateString = this.endDate.split('+')[0];
    const eventDateTz = moment.tz(dateString, this.timezone);
    this.endEventTZ = moment.tz(eventDateTz.format('YYYY-MM-DDTHH:mm:ss'), computerTimeZone).toDate();
    return this.endEventTZ;
  }
}
