import {Component, forwardRef, Input} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {InputAbstract} from '../input-abstract';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {ClipboardService} from 'ngx-clipboard';

@Component({
  selector: 'wbo-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./input.component.scss', '../input.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextareaComponent),
      multi: true
    }
  ],
})
export class TextareaComponent extends InputAbstract {
  constructor(clipboardService: ClipboardService) {
    super(clipboardService);
  }

  private _autoresize: boolean;
  get autoresize(): boolean {
    return this._autoresize;
  }

  @Input()
  set autoresize(value: boolean) {
    this._autoresize = coerceBooleanProperty(value);
  }
}
