import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {InfoDirective} from '../directives/info.directive';
import {FooterComponent} from './components/footer/footer.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    InfoDirective,
    FooterComponent,
  ],
  exports: [
    InfoDirective,
    FooterComponent,
  ]
})
export class FooterModule {
}
