import {WabelModel} from './wabel-model.abstract';

export class EventType extends WabelModel {

  constructor(obj: any = null) {
    super(obj);
  }
  name: string;
  description: string;

  get id() {
    return this.name;
  }
}
