import {WabelModel} from './wabel-model.abstract';
import {Permission} from './permission';

export class RolePermission extends WabelModel {
  permission: Permission;
  needToBeVerified: boolean;
  needToBeCompanyOwner: boolean;

  get id() {
    return null;
  }
}
