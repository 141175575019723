import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LayoutComponent} from './components/layout/layout.component';
import {HeaderComponent} from './components/header/header.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatToolbarModule} from '@angular/material/toolbar';
import {TopBarDirective} from './directives/top-bar.directive';
import {SidePanelDirective} from './directives/side-panel.directive';
import {PageComponent} from './components/page/page.component';
import {MatTabsModule} from '@angular/material/tabs';
import {RouterModule} from '@angular/router';
import {SidePanelCloseDirective} from './directives/side-panel-close.directive';
import {OverlayLoaderComponent} from './components/overlay-loader/overlay-loader.component';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {LeftContentDirective} from './directives/left-content.directive';
import {RightContentDirective} from './directives/right-content.directive';


@NgModule({
  declarations: [
    LayoutComponent,
    HeaderComponent,
    TopBarDirective,
    SidePanelDirective,
    SidePanelCloseDirective,
    PageComponent,
    OverlayLoaderComponent,
    LeftContentDirective,
    RightContentDirective
  ],
  exports: [
    LayoutComponent,
    HeaderComponent,
    TopBarDirective,
    SidePanelDirective,
    SidePanelCloseDirective,
    PageComponent,
    OverlayLoaderComponent,
    LeftContentDirective,
    RightContentDirective
  ],
  imports: [
    CommonModule,
    MatSidenavModule,
    MatProgressBarModule,
    MatToolbarModule,
    MatTabsModule,
    RouterModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatIconModule
  ]
})
export class WabelBOLayoutModule {
}
