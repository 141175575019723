import {Injectable} from '@angular/core';
import {WabelService} from './wabel-service.abstract';
import {Member} from '@models/member';
import {MemberGraphQL} from '../graphql/member.graphql';
import {Observable, of} from 'rxjs';
import {debounceTime, map, take} from 'rxjs/operators';
import {Resolve} from '@angular/router';
import {Company} from '@models/company';
import {Score} from '@models/score';

@Injectable({
  providedIn: 'root'
})
export class MemberService extends WabelService implements Resolve<Member> {

  readonly defaultEntity = Member;

  getCurrentMemberForResolver(idmember: number, cache: string = 'cache-and-network') {
    return this.query(
      MemberGraphQL.queries.currentMemberForResolver,
      {
        idmember,
      },
      cache
    )
      .pipe(
        map((data) => this.objToEntity(this.defaultEntity, data.data.member))
      );
  }

  getCurrentMemberForGuard(fetchPolicy: string = 'network-only') {
    return this.query(
      MemberGraphQL.queries.currentMemberForGuard,
      {},
      fetchPolicy
    )
      .pipe(
        map((data) => this.objToEntity(this.defaultEntity, data.data.me))
      );
  }

  getListByFilter(
    limit: number = 100,
    offset: number = 0,
    sort: string = 'idmember ASC',
    search: string = null,
    idcompanytype: number = null,
    status: string[] = null,
    membership: string = null,
    creationSource: string[] = null,
    idcompany: number = null
  ) {
    return this.query(
      MemberGraphQL.queries.findMembersByFilter,
      {
        limit,
        offset,
        order: sort || null,
        search,
        idcompanytype: +idcompanytype || null,
        status,
        membership,
        creationSource,
        idcompany,
      }
    )
      .pipe(
        map((data) => this.objToResultIterator(this.defaultEntity, data.data.findMembersByFilter))
      );
  }

  getListByCompany(
    idcompany: number
  ) {
    return this.query(
      MemberGraphQL.queries.findMembersByCompany,
      {
        idcompany,
      }
    )
      .pipe(
        map((data) => this.objToResultIterator(this.defaultEntity, data.data.findMembersByFilter))
      );
  }

  getByZohoID(zohoId: string) {
    return this.query(
      MemberGraphQL.queries.findMemberByZohoId,
      {
        zoho_id: zohoId,
      },
      'network-only',
      {
        errorAlert: false
      }
    )
      .pipe(
        map((data) => this.objToEntity(this.defaultEntity, data.data.findMemberByZohoId))
      );
  }

  getByEmail(email: string) {
    return this.query(
      MemberGraphQL.queries.memberByEmail,
      {
        email,
      },
      'network-only'
    )
      .pipe(
        debounceTime(500),
        map((data) => data.data.memberByEmail ? this.objToEntity(this.defaultEntity, data.data.memberByEmail) : null)
      );
  }

  getByEmailAndCompanyType(email: string, type: string) {
    return this.query(
      MemberGraphQL.queries.memberByEmailAndCompanyType,
      {
        email,
        type,
      },
      'network-only'
    )
      .pipe(
        debounceTime(500),
        map((data) => this.objToEntity(this.defaultEntity, data.data.memberByEmailAndCompanyType))
      );
  }

  getByEmailAndCompanyAndNotInSourcingRequestRecommendation(email: string, idcompany: number, idsourcingrequest: number) {
    return this.query(
      MemberGraphQL.queries.memberByEmailAndCompanyAndNotInSourcingRequestRecommendation,
      {
        email,
        idcompany,
        idsourcingrequest,
      },
      'network-only')
      .pipe(
        debounceTime(500),
        map((data) => this.objToEntity(this.defaultEntity, data.data.memberByEmailAndCompanyAndNotInSourcingRequestRecommendation))
      );
  }

  getMultipleInTangoByZohoID(zohoIdList: string[]) {
    return this.query(
      MemberGraphQL.queries.findMembersInTangoByZohoId,
      {
        zoho_id_list: zohoIdList,
      }
    )
      .pipe(
        map((data) => {
          const targetsObjects = this.objToEntities(this.defaultEntity, data.data.findMembersInTangoByZohoId.targets);
          return {targets: targetsObjects, errors: data.data.findMembersInTangoByZohoId.errors};
        })
      );
  }

  insert(member: Member, cascade = false) {
    return this.mutation(
      MemberGraphQL.mutations.insertMember,
      {
        zohoId: member.zohoId,
        cascade,
      }
    )
      .pipe(
        map((data) => this.objToEntity(this.defaultEntity, data.data.insertMember))
      );
  }

  insertMultiple(members: Member[], cascade = false) {
    const zohoIdList = members.map((member: Member) => member.zohoId);
    return this.mutation(
      MemberGraphQL.mutations.insertMembers,
      {
        zohoIdList,
        cascade,
      }
    )
      .pipe(
        map((data) => this.objToEntities(this.defaultEntity, data.data.insertMembers))
      );
  }

  sendEmail(member: Member, emailId: number, eventCode: string = null) {
    return this.mutation(
      MemberGraphQL.mutations.sendEmail,
      {
        id: member.idmember,
        email_id: emailId,
        eventCode,
      }
    )
      .pipe(
        map((data) => this.objToEntity(this.defaultEntity, data.data.sendEmail))
      );
  }

  sendEmails(members: Member[], emailId: number, eventCode: string = null) {
    const ids = members.map((member: Member) => member.id);
    return this.mutation(
      MemberGraphQL.mutations.sendEmails,
      {
        ids,
        email_id: emailId,
        eventCode,
      }
    )
      .pipe(
        map((data) => this.objToEntities(this.defaultEntity, data.data.sendEmails))
      );
  }

  changeStatus(idmember: number, status: string, rejectedReason: number = null, customRejectionReason: string = null, rejectCompany: boolean = false) {
    return this.mutation(
      MemberGraphQL.mutations.changeMemberStatus,
      {
        idmember,
        status,
        rejectedReason,
        customRejectionReason,
        rejectCompany,
      }
    )
      .pipe(
        map((data) => this.objToEntity(this.defaultEntity, data.data.changeMemberStatus))
      );
  }

  updateCompanyOwnerStatus(member: Member, status: boolean) {
    return this.mutation(
      MemberGraphQL.mutations.updateCompanyOwnerStatus,
      {
        idmember: +member.idmember,
        status,
      }
    )
      .pipe(
        map((data) => this.objToEntity(this.defaultEntity, data.data.updateCompanyOwnerStatus))
      );
  }

  updateMemberBasicInformation(member: Member) {
    return this.mutation(
      MemberGraphQL.mutations.updateMemberBasicInformation,
      {
        idmember: +member.idmember,
        firstname: member.firstname,
        lastname: member.name,
        email: member.email,
        zohoId: member.zohoId,
        mobile: member.mobile,
      }
    )
      .pipe(
        map((data) => this.objToEntity(this.defaultEntity, data.data.updateMemberBasicInformation))
      );
  }

  updateMemberName(member: Member) {
    return this.mutation(
      MemberGraphQL.mutations.updateMemberName,
      {
        idmember: +member.idmember,
        firstname: member.firstname,
        lastname: member.name,
      }
    )
      .pipe(
        map((data) => this.objToEntity(this.defaultEntity, data.data.updateMemberName))
      );
  }

  changePassword(member: Member, password: string) {
    return this.mutation(
      MemberGraphQL.mutations.changePassword,
      {
        idmember: +member.idmember,
        password,
      }
    )
      .pipe(
        map((data) => this.objToEntity(this.defaultEntity, data.data.savePassword))
      );
  }

  sendEmailPasswordRecovery(email: string) {
    return this.mutation(
      MemberGraphQL.mutations.sendEmailPasswordRecovery,
      {
        email,
      }
    )
      .pipe(
        map((data) => this.objToEntity(this.defaultEntity, data.data.sendEmailPasswordRecovery))
      );
  }

  validateEmailOfMember(idmember: number) {
    return this.mutation(
      MemberGraphQL.mutations.validateEmailOfMember,
      {
        idmember,
      }
    )
      .pipe(
        map((data) => this.objToEntity(this.defaultEntity, data.data.validateEmailOfMember))
      );
  }

  changeMemberCompany(member: Member, company: Company) {
    return this.mutation(
      MemberGraphQL.mutations.changeMemberCompany,
      {
        idmember: member.idmember,
        idcompany: company.idcompany,
      }
    )
      .pipe(
        map((data) => this.objToEntity(this.defaultEntity, data.data.changeMemberCompany))
      );
  }

  getMemberPermissions(member: Member) {
    return this.query(
      MemberGraphQL.queries.getMemberPermissions,
      {
        idmember: member.idmember
      }
    )
      .pipe(
        map((data) => this.objToEntity(this.defaultEntity, data.data.member))
      );
  }

  resolve(route): Observable<Member> {
    return route.params.id ? this.getCurrentMemberForResolver(route.params.id).pipe(take(1)) : of(null);
  }

  updateScore(member: Member) {
    return this.mutation(
      MemberGraphQL.mutations.updateMemberScore,
      {
        memberId: member.idmember
      }
    )
      .pipe(
        map(data => this.objToEntity(Score, data.data.updateMemberScore))
      );
  }

  findMemberInfoAboutRelationships(member: Member) {
    return this.query(
      MemberGraphQL.queries.findMemberInfoAboutRelationships,
      {
        idmember: member.idmember
      }
    )
      .pipe(
        map((data) => this.objToEntity(this.defaultEntity, data.data.member))
      );
  }

  getMemberConnectedDevices(member: Member) {
    return this.query(
      MemberGraphQL.queries.memberSessions,
      {
        idmember: member.idmember
      },
      'network-only'
    )
      .pipe(
        map((data) => +data.data.memberSessions)
      );
  }

  disconnectMember(member: Member) {
    return this.mutation(
      MemberGraphQL.mutations.disconnectMember,
      {
        idmember: member.idmember
      }
    )
      .pipe(
        map(data => this.objToEntity(this.defaultEntity, data.data.disconnectMember))
      );
  }

  getMemberOverview(idMember: number, cache: string = 'cache-and-network') {
    return this.query(
      MemberGraphQL.queries.memberOverview,
      {
        idmember: idMember
      },
      cache
    )
      .pipe(
        map((data) => this.objToEntity(this.defaultEntity, data.data.member))
      );
  }

  getMemberBuyingScope(idMember: number, cache: string = 'cache-and-network') {
    return this.query(
      MemberGraphQL.queries.memberBuyingScope,
      {
        idmember: idMember
      },
      cache
    )
      .pipe(
        map((data) => this.objToEntity(this.defaultEntity, data.data.member))
      );
  }

  getHermesNotifications(member: Member) {
    return this.query(
      MemberGraphQL.queries.hermesMemberNotifications,
      {
        idmember: member.idmember
      },
      'network-only'
    )
      .pipe(
        map((data) => data.data ? JSON.parse(data.data.hermesMemberNotifications) : [])
      );
  }
}
