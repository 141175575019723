import {Component, EventEmitter, Output} from '@angular/core';
import {animate, style, transition, trigger} from '@angular/animations';
import {EventNavigationService} from '@services/navigation-services/event-navigation.service';
import {RightsService} from '@services/rights.service';

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['header.component.scss'],
  animations: [
    trigger('loaded', [
      transition(':enter', [
        style({transform: 'translateY(-100%)'}),
        animate('500ms ease', style({transform: 'translateY(0)'}))
      ])
    ])
  ]
})
export class HeaderComponent {
  @Output() openSidebarEvent: EventEmitter<any> = new EventEmitter();
  @Output() closeSidebarEvent: EventEmitter<any> = new EventEmitter();
  @Output() elasticSearch: EventEmitter<boolean> = new EventEmitter();

  showMenu = true;

  constructor(
    private rightService: RightsService,
    private eNS: EventNavigationService
  ) {

  }

  get liveMode() {
    return this.eNS.liveMode;
  }

  get eventCode() {
    return this.eNS.eventCode;
  }

  get isSuperAdmin() {
    return this.rightService.isSuperAdmin();
  }

  get eventIsOnline() {
    return this.eNS.event.isOnline;
  }
}
