import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AlertDirective} from './directives/alert.directive';
import {ConfirmDirective} from './directives/confirm.directive';
import {ModalDirective} from './directives/modal.directive';
import {MatDialogModule} from '@angular/material/dialog';
import {ConfirmComponent} from './components/confirm/confirm.component';
import {MatButtonModule} from '@angular/material/button';
import {BottomSheetComponent} from './components/bottom-sheet/bottom-sheet.component';
import {BottomSheetDirective} from './directives/bottom-sheet.directive';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {BottomSheetCloseDirective} from './directives/bottom-sheet-close.directive';
import {AlertComponent} from './components/alert/alert.component';
import {ModalComponent} from './components/modal/modal.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatIconModule} from '@angular/material/icon';
import {ModalService} from './services/modal.service';

@NgModule({
  declarations: [
    AlertDirective,
    AlertComponent,
    ConfirmDirective,
    ModalDirective,
    ConfirmComponent,
    ModalComponent,
    BottomSheetComponent,
    BottomSheetDirective,
    BottomSheetCloseDirective,
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatSnackBarModule,
    MatIconModule
  ],
  exports: [
    AlertDirective,
    ConfirmDirective,
    ModalDirective,
    BottomSheetDirective,
    BottomSheetCloseDirective
  ],
  providers: [
    ModalService
  ]
})
export class WabelBODialogModule {
}
