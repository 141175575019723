import {Directive, HostListener} from '@angular/core';
import {SidePanelService} from '../services/side-panel.service';

@Directive({
  selector: 'button[wboSidePanelClose], mat-sidenav a'
})
export class SidePanelCloseDirective {

  constructor(public sidePanelService: SidePanelService) {
  }

  @HostListener('click') onClick() {
    this.sidePanelService.close();
  }
}
