import {Injectable} from '@angular/core';
import {WabelService} from './wabel-service.abstract';
import {map, tap} from 'rxjs/operators';
import {TagTypeGraphql} from '../graphql/tag_type.graphql';
import {TagType} from '@models/tag_type';
import {Tag} from '@models/tag';
import {of} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TagTypeService extends WabelService {

  readonly defaultEntity = Tag;

  public static readonly SPECIALIZATION_INTERNAL_NAME = 'specialization';
  public static readonly MOQ_INTERNAL_NAME = 'minimum_order_quantity';
  public static readonly POINT_OF_SALE_INTERNAL_NAME = 'point_of_sale';
  public static readonly ACTIVITY_INTERNAL_NAME = 'activity';
  public static readonly DISTRIBUTION_CHANNEL_INTERNAL_NAME = 'distribution_channel';
  public static readonly EXPORT_POSITION_INTERNAL_NAME = 'export_position';
  public static readonly CERTIFICATION_INTERNAL_NAME = 'certification';
  public static readonly PRICE_RANGE_INTERNAL_NAME = 'price_range';
  public static readonly TARGET_CONSUMER_INTERNAL_NAME = 'target_consumer';
  public static readonly RANGE_OF_PRODUCT_INTERNAL_NAME = 'range_of_product';
  public static readonly NUTRITIONAL_INFORMATION_INTERNAL_NAME = 'nutritional_information';
  public static readonly QUALITY_LABEL_INTERNAL_NAME = 'quality_label';
  public static readonly EXPORT_CERTIFICATION_INTERNAL_NAME = 'export_certification';
  public static readonly CATEGORY_INTERNAL_NAME = 'category';
  public static readonly LOCATION_INTERNAL_NAME = 'location';
  public static readonly SUSTAINABILITY_INTERNAL_NAME = 'sustainability';
  public static readonly CONSUMPTION_MODE_INTERNAL_NAME = 'consumption_mode';
  public static readonly HEALTH_AND_WELLNESS_INTERNAL_NAME = 'health_and_wellness';
  public static readonly DIETARY_INTERNAL_NAME = 'dietary';
  public static readonly TEMPERATURE_CONTROLLED_INTERNAL_NAME = 'temperature_controlled';
  public static readonly PACKAGING_INTERNAL_NAME = 'packaging';
  public static readonly TURNOVER_INTERNAL_NAME = 'turnover';
  public static readonly GENERATED_KEYWORDS_INTERNAL_NAME = 'generated_keywords';
  public static readonly EVENT_PRIORITIES_INTERNAL_NAME = 'event_priorities';
  public static readonly CURRENCY_INTERNAL_NAME = 'currency';
  public static readonly NB_SELLING_POINTS_NAME = 'nb_selling_points';
  public static readonly NB_EMPLOYEES_NAME = 'nb_employees';
  public static readonly PACKAGING_PRODUCT_CATEGORIES = 'packaging_product_categories';
  public static readonly MARKETING_FOCUS_INTERNAL_NAME = 'marketing_focus';
  public static readonly VOLUME_INTERNAL_NAME = 'volume';
  public static readonly COMPANY_TYPE_LABEL_INTERNAL_NAME = 'company_type_label';
  public static readonly COMPANY_TYPE_INTERNAL_NAME = 'company_type';

  private cachedTagTypes: { [key: string]: TagType } = {};

  getTagTypeByInternalName(tagTypeInternalName: string, onlyRoot: boolean = true, useCache: boolean = false) {
    const cacheKey = tagTypeInternalName + '-' + (onlyRoot ? 'true' : 'false');
    if (useCache) {
      if (this.cachedTagTypes.hasOwnProperty(cacheKey)) {
        return of(this.cachedTagTypes[cacheKey]);
      }
    }
    return this.query(
      TagTypeGraphql.queries.tagTypeByInternalName,
      {
        tagTypeInternalName,
        onlyRoot
      }
    )
      .pipe(
        map((data) => this.objToEntity(TagType, data.data.tagTypeByInternalName)),
        tap((data) => {
          if (useCache) {
            this.cachedTagTypes[cacheKey] = data;
          }
        })
      );
  }

  getFullTagTypeByInternalName(tagTypeInternalName: string) {
    return this.query(
      TagTypeGraphql.queries.fullTagTypeByInternalName,
      {
        tagTypeInternalName
      }
    )
      .pipe(
        map((data) => this.objToEntity(TagType, data.data.tagTypeByInternalName))
      );
  }

  getTwoLevelsTagsByInternalNameAndDepth(tagTypeInternalName: string, depth: number) {
    return this.query(
      TagTypeGraphql.queries.twoLevelsTagsByInternalNameAndDepth,
      {
        tagTypeInternalName,
        depth
      }
    )
      .pipe(
        map((data) => this.objToEntities(this.defaultEntity, data.data.tagsByInternalNameAndDepth.items))
      );
  }

  addNewTag(name: string, type: number, parent: number = null) {
    return this.mutation(
      TagTypeGraphql.mutations.addNewTag,
      {
        name,
        typeId: type,
        parentId: parent
      }
    )
      .pipe(
        map((data) => this.objToEntity(this.defaultEntity, data.data.addNewTag))
      );
  }

  updateTagName(name: string, id: number) {
    return this.mutation(
      TagTypeGraphql.mutations.updateTagName,
      {
        name,
        tagId: id
      }
    )
      .pipe(
        map((data) => this.objToEntity(this.defaultEntity, data.data.updateTagName))
      );
  }

  getAllTags(excludedInternalNames: string[] = []) {
    return this.query(
      TagTypeGraphql.queries.allTags,
      {
        excludedInternalNames
      }
    )
      .pipe(
        map((data) => this.objToEntities(this.defaultEntity, data.data.allTags.items))
      );
  }

  regenerateTagTrees() {
    return this.mutation(
      TagTypeGraphql.mutations.regenerateTagTrees
    )
      .pipe(
        map((data) => data.data.regenerateTagTrees)
      );
  }
}
