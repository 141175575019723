import {
  AfterContentInit, AfterViewChecked,
  Component,
  ContentChild,
  ViewChild
} from '@angular/core';
import {MatFormField, MatFormFieldControl} from '@angular/material/form-field';
import {InputComponent} from '../input/input.component';

@Component({
  selector: 'wbo-input-wrapper',
  templateUrl: './wrapper.component.html',
  styleUrls: ['./wrapper.component.scss']
})
export class WrapperComponent implements AfterContentInit, AfterViewChecked {
  input: InputComponent;
  @ContentChild(InputComponent) _input: InputComponent;

  @ContentChild(MatFormFieldControl) _control: MatFormFieldControl<any>;

  // wrapper: MatFormField;
  // @ViewChild('formField') _wrapper: MatFormField;

  constructor() {
  }

  ngOnInit() {
  }

  ngAfterContentInit(): void {
  }

  ngAfterViewChecked(): void {
    // this.wrapper = this._wrapper;
    // this.wrapper._control = this._control;
    this.input = this._input;
  }
}
