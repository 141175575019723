import {WabelModel} from './wabel-model.abstract';
import {Company} from './company';
import {SubscriptionPack} from './subscription_pack';

export class CompanyMembership extends WabelModel {
  constructor(obj?: any) {
    super(obj);
    if (obj) {
      this.idsubscription = obj.idsubscription ? +obj.idsubscription : 0;
      this.startDate = obj.startDate ? new Date(obj.startDate) : null;
      this.endDate = obj.endDate ? new Date(obj.endDate) : null;
      this.company = obj.company ? new Company(obj.company) : null;
      this.subscriptionpack = obj.subscriptionpack ? new SubscriptionPack(obj.subscriptionpack) : null;
      if (this.status === 'On' && this.startDate > new Date()) {
        this.status = 'Not started';
      }
    }
  }

  idsubscription: number;
  company: Company;
  startDate: Date;
  endDate: Date;
  status: string;
  discount: number;
  subscriptionpack: SubscriptionPack;
  resetDate: string;

  get id(): number {
    return this.idsubscription;
  }

  set id(id: number) {
    this.idsubscription = id;
  }
}
