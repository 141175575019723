import {WabelModel} from './wabel-model.abstract';
import {Product} from './product';
import {Member} from './member';
import {CompanyMembership} from './company_membership';
import {PurchasingGroup} from './purchasing_group';
import {BuyingOffice} from './buying_office';
import {CompanyType} from './company_type';
import {EventParticipation} from './event_participation';
import {Tag} from './tag';
import {Citylocation} from './citylocation';
import {File} from './file';
import {CompanyProfileSubmission} from './company_profile_submission';
import {EcoPackProject} from './eco-pack-project';
import {CompanyRelationship} from './company_relationship';
import {PrivateMessage} from './private_message';
import {SourcingRequest} from './sourcing_request';
import {CompanyClient} from './company_client';
import {SourcingNeed} from './sourcing_need';
import {CompanyFile} from './company_file';
import {Score} from './score';
import {Banner} from './banner';
import {BusinessMapping} from './business_mapping';
import {BusinessMappingPriorityRecommendation} from '@models/business_mapping_priority_recommendation';
import {environment} from '../../environments/environment';
import {CompanyMembershipUpgradeRequest} from '@models/company_membership_upgrade_request';

export class Company extends WabelModel {
  constructor(obj?: any) {
    super(obj);
    if (obj) {
      this.idcompany = obj.idcompany ? +obj.idcompany : 0;
      this.parentCompany = obj.parentCompany ? new Company(obj.parentCompany) : null;
      this.country = obj.country ? new Tag(obj.country) : null;
      this.memberships = obj.memberships ? obj.memberships.map(membership => new CompanyMembership(membership)) : [];
      this.activeMemberships = obj.activeMemberships ? obj.activeMemberships.map(membership => new CompanyMembership(membership)) : [];
      this.banners = obj.banners && obj.banners.items ? obj.banners.items.map(banner => new Banner(banner)) : [];
      this.companytype = obj.companytype ? new CompanyType(obj.companytype) : null;
      this.eventParticipations = obj.eventParticipations ? obj.eventParticipations.map(eventParticipation => new EventParticipation(eventParticipation)) : [];
      this.eventParticipation = obj.participationByEventCode ? new EventParticipation(obj.participationByEventCode) : null;
      this.onMembers = obj.onMembers ? obj.onMembers.map(member => new Member(member)) : [];
      this.consultant = obj.consultant ? new Member(obj.consultant) : null;
      this.meetingPreferencesCount = obj.meetingPreferencesCount && obj.meetingPreferencesCount.count ? obj.meetingPreferencesCount.count : 0;
      this.categories = obj.categories && obj.categories.items ? obj.categories.items.map(category => new Tag(category)) : [];
      this.rootCategories = obj.rootCategories && obj.rootCategories.items ? obj.rootCategories.items.map(category => new Tag(category)) : [];
      this.productCategories = obj.productCategories && obj.productCategories.items ? obj.productCategories.items.map(category => new Tag(category)) : [];
      this.categoriesCount = obj.categories && obj.categories.count !== undefined ? obj.categories.count : null;
      this.productsPriceRanges = obj.productsPriceRanges && obj.productsPriceRanges.items ? obj.productsPriceRanges.items.map(tag => new Tag(tag)) : [];
      this.consultantCsm = obj.consultantCsm ? new Member(obj.consultantCsm) : null;
      this.members = obj.allMembers ? obj.allMembers.map(member => new Member(member)) : [];
      this.files = obj.files ? obj.files.map(file => new File(file)) : [];
      this.distributionChannels = obj.distributionChannel && obj.distributionChannel.items ? obj.distributionChannel.items.map(tag => new Tag(tag)) : [];
      this.matchingProductsForProductRequest = obj.matchingProductsForProductRequest ? obj.matchingProductsForProductRequest.map(p => new Product(p)) : [];
      this.activities = obj.activity && obj.activity.items ? obj.activity.items.map(tag => new Tag(tag)) : [];
      this.companyProductActivities = obj.companyProductActivities && obj.companyProductActivities.items ? obj.companyProductActivities.items.map(tag => new Tag(tag)) : [];
      this.specializations = obj.specialization && obj.specialization.items ? obj.specialization.items.map(tag => new Tag(tag)) : [];
      this.targetConsumers = obj.targetConsumer && obj.targetConsumer.items ? obj.targetConsumer.items.map(tag => new Tag(tag)) : [];
      this.minimumOrderQuantities = obj.minimumOrderQuantity && obj.minimumOrderQuantity.items ? obj.minimumOrderQuantity.items.map(tag => new Tag(tag)) : [];
      this.rangeOfProducts = obj.rangeOfProducts && obj.rangeOfProducts.items ? obj.rangeOfProducts.items.map(tag => new Tag(tag)) : [];
      this.nutritionalInformations = obj.nutritionalInformations && obj.nutritionalInformations.items ? obj.nutritionalInformations.items.map(tag => new Tag(tag)) : [];
      this.qualityLabels = obj.qualityLabels && obj.qualityLabels.items ? obj.qualityLabels.items.map(tag => new Tag(tag)) : [];
      this.companyProfileSubmissions = obj.companyProfileSubmissions && obj.companyProfileSubmissions.items ? obj.companyProfileSubmissions.items.map(item => new CompanyProfileSubmission(item)) : [];
      this.ecoPackProjects = obj.ecoPackProjects && obj.ecoPackProjects.items ? obj.ecoPackProjects.items.map(item => new EcoPackProject(item)) : [];
      this.companyMeetingsByEvents = obj.eventParticipations ? obj.eventParticipations.map(eventParticipation => new EventParticipation(eventParticipation)) : [];
      this.relationships = obj.relationships ? obj.relationships.map(relationship => new CompanyRelationship(relationship)) : [];
      this.companyClients = obj.companyClients && obj.companyClients.items ? obj.companyClients.items.map(companyClient => new CompanyClient(companyClient)) : [];
      this.customCertifications = obj.customCertifications && obj.customCertifications.items ? obj.customCertifications.items.map(certification => new Tag(certification)) : [];
      this.membersPrivateMessages = obj.membersPrivateMessages && obj.membersPrivateMessages.items ? obj.membersPrivateMessages.items.map(message => new PrivateMessage(message)) : [];
      this.sourcingRequests = obj.sourcingRequests && obj.sourcingRequests.items ? obj.sourcingRequests.items.map(sourcingRequest => new SourcingRequest(sourcingRequest)) : [];
      this.sourcingNeeds = obj.sourcingNeeds && obj.sourcingNeeds.items ? obj.sourcingNeeds.items.map(sourcingNeed => new SourcingNeed(sourcingNeed)) : [];
      this.companiesHaveFavoritedTheCompany = obj.companiesHaveFavoritedTheCompany && obj.companiesHaveFavoritedTheCompany.items ? obj.companiesHaveFavoritedTheCompany.items.map(company => new Company(company)) : [];
      this.certifications = obj.certifications && obj.certifications.items ? obj.certifications.items.map(tag => new Tag(tag)) : [];
      this.numemployees = obj.numemployees ? new Tag(obj.numemployees) : null;
      this.ca = obj.ca ? new Tag(obj.ca) : null;
      this.tagsMatchedWithSourcingNeed = obj.tagsMatchedWithSourcingNeed && obj.tagsMatchedWithSourcingNeed.items ? obj.tagsMatchedWithSourcingNeed.items.map(tag => new Tag(tag)) : [];
      this.files = obj.files ? obj.files.map(file => new CompanyFile(file)) : [];
      this.companyImages = obj.companyImages ? obj.companyImages.map(companyImage => new CompanyFile(companyImage)) : [];
      this.companyRewardScore = obj.companyRewardScore ? new Score(obj.companyRewardScore) : null;
      this.companyDistributionChannelsReference = obj.companyDistributionChannelsReference && obj.companyDistributionChannelsReference.items ? obj.companyDistributionChannelsReference.items.map(tag => new Tag(tag)) : [];
      this.activeAndAlreadyLoggedMembersCount = obj.activeAndAlreadyLoggedMembers && obj.activeAndAlreadyLoggedMembers.count ? obj.activeAndAlreadyLoggedMembers.count : null;
      this.companyTypeTag = obj.companyTypeTag ? new Tag(obj.companyTypeTag) : null;
      this.companyTypeTagLevel1 = null;
      this.companyTypeTagLevel2 = null;
      if (this.companyTypeTag) {
        this.companyTypeTagLevel1 = this.companyTypeTag.parent ? this.companyTypeTag.parent.id : this.companyTypeTag.id;
        this.companyTypeTagLevel2 = this.companyTypeTag.parent ? this.companyTypeTag.id : null;
      }
      this.companyTypeLabelsTags = obj.companyTypeLabels && obj.companyTypeLabels.items ? obj.companyTypeLabels.items.map(tag => new Tag(tag)) : [];
      this.companyTypeLabels = this.companyTypeLabelsTags.map(i => i.id);
      this.businessMappings = obj.businessMappings && obj.businessMappings.items ? obj.businessMappings.items.map(bm => new BusinessMapping(bm)) : [];
      this.businessMappingPriorityRecommendations = obj.businessMappingPriorityRecommendations && obj.businessMappingPriorityRecommendations.items ? obj.businessMappingPriorityRecommendations.items.map(businessMappingPriorityRecommendations => new BusinessMappingPriorityRecommendation(businessMappingPriorityRecommendations)) : [];
      this.marketingFocus = obj.marketingFocus && obj.marketingFocus.items ? obj.marketingFocus.items.map(v => new Tag(v)) : [];
      this.sourcingRequestRecommendationApplicationsCount = obj.sourcingRequestRecommendationApplications && obj.sourcingRequestRecommendationApplications.count !== undefined ? obj.sourcingRequestRecommendationApplications.count : null;
      this.sourcingNeedRecommendationApplicationsCount = obj.sourcingNeedRecommendationApplications && obj.sourcingNeedRecommendationApplications.count !== undefined ? obj.sourcingNeedRecommendationApplications.count : null;
      this.sourcingRequestRecommendationApplicationsCurrentRollingMonthCount = obj.sourcingRequestRecommendationApplicationsCurrentRollingMonth && obj.sourcingRequestRecommendationApplicationsCurrentRollingMonth.count !== undefined ? obj.sourcingRequestRecommendationApplicationsCurrentRollingMonth.count : null;
      this.sourcingRequestRecommendationApplicationsCurrentMembershipCount = obj.sourcingRequestRecommendationApplicationsCurrentMembership && obj.sourcingRequestRecommendationApplicationsCurrentMembership.count !== undefined ? obj.sourcingRequestRecommendationApplicationsCurrentMembership.count : null;
      this.membershipEntity = obj.membershipEntity ? new CompanyMembership(obj.membershipEntity) : null;
      this.sourcingNeedRecommendationApplicationsCurrentRollingMonthCount = obj.sourcingNeedRecommendationApplicationsCurrentRollingMonth && obj.sourcingNeedRecommendationApplicationsCurrentRollingMonth.count !== undefined ? obj.sourcingNeedRecommendationApplicationsCurrentRollingMonth.count : null;
      this.sourcingNeedRecommendationApplicationsCurrentMembershipCount = obj.sourcingNeedRecommendationApplicationsCurrentMembership && obj.sourcingNeedRecommendationApplicationsCurrentMembership.count !== undefined ? obj.sourcingNeedRecommendationApplicationsCurrentMembership.count : null;
      this.prospects = obj.prospects && obj.prospects.items ? obj.prospects.items.map(prospect => new Company(prospect)) : [];
      this.profileSubmissionsCurrentRollingMonthCount = obj.profileSubmissionsCurrentRollingMonth && obj.profileSubmissionsCurrentRollingMonth.count !== undefined ? obj.profileSubmissionsCurrentRollingMonth.count : null;
      this.profileSubmissionsCurrentMembershipCount = obj.profileSubmissionsCurrentMembership && obj.profileSubmissionsCurrentMembership.count !== undefined ? obj.profileSubmissionsCurrentMembership.count : null;
      this.profileSubmissionsCount = obj.profileSubmissions && obj.profileSubmissions.count !== undefined ? obj.profileSubmissions.count : null;
      this.website = obj.website ? (obj.website.startsWith('http://') || obj.website.startsWith('https://') ? obj.website : 'http://' + obj.website) : '';
    }
  }

  zohoId: string;
  idcompany: number;
  name: string;
  phone: string;
  description: string;
  formattedAddress: string;
  postalCode: string;
  ca: Tag;
  numemployees: Tag;
  yearEstablished: number;
  privateLabelParts: number;
  productsPriceRanges: Tag[];
  marketingFocus: Tag[];
  citylocation: Citylocation;
  consultantCsm: Member;
  dateRejected: string;
  bookletDescription: string;
  stockAvailability: string;
  stockAvailabilityLastUpdate: string;
  ecopackpolicydescription: string;
  facebook: string;
  linkedin: string;
  instagram: string;
  twitter: string;
  pinterest: string;

  type: string;
  company_type_machine_name: string;
  status: string;
  urlname: string;
  website: string;
  logoUrl: string;
  completionPercentage: number;
  createdDate: string;
  products: Product[];
  matchingProductsForProductRequest: Product[];
  categories: Tag[];
  certifications: Tag[];
  distributionChannels: Tag[];
  activities: Tag[];
  companyProductActivities: Tag[];
  specializations: Tag[];
  targetConsumers: Tag[];
  minimumOrderQuantities: Tag[];
  rangeOfProducts: Tag[];
  nutritionalInformations: Tag[];
  qualityLabels: Tag[];
  companyProfileSubmissions: CompanyProfileSubmission[];
  ecoPackProjects: EcoPackProject[];
  companyMeetingsByEvents: EventParticipation[];
  relationships: CompanyRelationship[];
  customCertifications: Tag[];
  companyDistributionChannelsReference: Tag[];
  companyClients: CompanyClient[];
  files: CompanyFile[] = [];
  companyImages: CompanyFile[] = [];
  membersPrivateMessages: PrivateMessage[];
  sourcingRequests: SourcingRequest[];
  companiesHaveFavoritedTheCompany: Company[];
  categoriesCount: number;
  rootCategories: Tag[];
  productCategories: Tag[];
  totalStores: number;
  eventParticipations: EventParticipation[];
  eventParticipation: EventParticipation;
  members: Member[];
  parentCompany: Company;
  buyingOffices: Company[];
  purchasingGroups: Company[];
  country: Tag;
  membership: boolean;
  memberships: CompanyMembership[];
  activeMemberships: CompanyMembership[] = [];
  onMembers: Member[];
  banners: Banner[] = [];
  companytype: CompanyType;
  purchasingGroup: PurchasingGroup;
  buyingOffice: BuyingOffice;
  consultant: Member;
  isRetailer: boolean;
  isSupplier: boolean;
  meetingPreferencesCount: number;
  eventParticipationCount: number;
  productCount: number;
  buyingOfficeCount: number;
  purchasingGroupCount: number;
  memberCount: number;
  sourcingNeeds: SourcingNeed[];
  supplierWantsToSellInMyCountry: boolean;
  supplierSellsInMyCountry: boolean;
  tagsMatchedWithSourcingNeed: Tag[];
  companyRewardScore: Score;
  activeAndAlreadyLoggedMembersCount: number;
  membersCount: number;
  registrationStep: string;
  companyTypeTag: Tag;
  companyTypeTagLevel1: number;
  companyTypeTagLevel2: number;
  companyTypeLabels: number[] = [];
  companyTypeLabelsTags: Tag[] = [];
  businessMappings: BusinessMapping[];
  businessMappingPriorityRecommendations: BusinessMappingPriorityRecommendation[];
  membershipEntity: CompanyMembership;
  sourcingRequestRecommendationApplicationsCount: number;
  sourcingNeedRecommendationApplicationsCount: number;
  profileSubmissionsCount: number;
  sourcingRequestRecommendationApplicationsCurrentRollingMonthCount: number;
  sourcingRequestRecommendationApplicationsCurrentMembershipCount: number;
  sourcingNeedRecommendationApplicationsCurrentRollingMonthCount: number;
  sourcingNeedRecommendationApplicationsCurrentMembershipCount: number;
  profileSubmissionsCurrentRollingMonthCount: number;
  profileSubmissionsCurrentMembershipCount: number;
  remainingProductRequests: number;
  remainingCategoryBenchmarks: number;
  remainingCompanyProfileSubmissions: number;
  pic: string;
  prospects: Company[];
  reward: number;
  valueProposition: string;
  hasRequestedMemberShip: boolean;
  companyMembershipUpgradeRequestBySourcingRequest: CompanyMembershipUpgradeRequest;
  zohoEntityUrl: string;
  profileUrl: string;
  get id(): number {
    return +this.idcompany;
  }

  set id(id: number) {
    this.idcompany = id;
  }

  isRetailGroup(): boolean {
    return this.company_type_machine_name && this.company_type_machine_name === 'retail_group';
  }

  isFoodService(): boolean {
    return this.company_type_machine_name && this.company_type_machine_name === 'food_service';
  }

  isBuyingOffice(): boolean {
    return this.company_type_machine_name && this.company_type_machine_name === 'buying_office';
  }

  isManufacturer(): boolean {
    return this.company_type_machine_name && this.company_type_machine_name === 'manufacturer';
  }

  isPackaging(): boolean {
    return this.company_type_machine_name && this.company_type_machine_name === 'packaging';
  }

  isAdmin(): boolean {
    return this.company_type_machine_name && this.company_type_machine_name === 'admin';
  }

  get rejectionDate() {
    return this.dateRejected ? new Date(this.dateRejected) : null;
  }

  get stockAvailabilityLastUpdateDate() {
    return this.stockAvailabilityLastUpdate ? new Date(this.stockAvailabilityLastUpdate) : null;
  }

  get createdOn() {
    return this.createdDate ? new Date(this.createdDate) : null;
  }

  set createdOn(createdDate: Date) {
    this.createdDate = createdDate.toString();
  }

  // TODO to migrate
  isConsideredSeller(): boolean {
    return ['manufacturer', 'co_packer', 'packaging', 'admin'].some((v) => v === this.company_type_machine_name);
  }

  get needlCompanyUrl() {
    return environment.needl_front_end_url + '/company/' + this.urlname;
  }

  get zohoCompanyUrl() {
    return this.zohoId ? environment.zoho_crm_entity_url + '/Accounts/' + this.zohoId : environment.needl_front_end_url + '/error';
  }
}

export const LINKEDIN_BASE_PROFILE_URL = 'https://linkedin.com/company/';
export const FACEBOOK_BASE_PROFILE_URL = 'https://facebook.com/';
export const INSTAGRAM_BASE_PROFILE_URL = 'https://instagram.com/';
