import {Injectable, TemplateRef} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {TopBarContent} from '../../types';

@Injectable({
  providedIn: 'root'
})
export class TopBarService {

  private _content: TopBarContent[] = [];
  private content = new BehaviorSubject([])
  opened = true;

  get content$(): Observable<TemplateRef<any>[]> {
    return this.content.asObservable();
  }

  addContent(content: TemplateRef<any>): number {
    const id = this._content.length;
    this._content.push({id: id, content: content});
    this.content.next(this._content);
    return id;
  }

  removeContent(id: number): void {
    const index = this._content.findIndex(content => content.id === id);
    if (index > -1) {
      this._content.splice(index, 1);
      this.content.next(this._content);
    }
  }

  constructor() {
  }
}
