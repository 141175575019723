import {WabelModel} from './wabel-model.abstract';
import {Company} from './company';

export class BusinessMappingPriorityRecommendation extends WabelModel {
  id: number;
  company: Company;

  constructor(obj?: any) {
    super(obj);
    if (obj) {
      this.company = obj.company ? new Company(obj.company) : null;
    }
  }
}
