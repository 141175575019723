<mat-form-field #formField  class="wabel-bo-input" [color]="color" [appearance]="appearance" [class.filled]="appearance === 'fill'" [class.no-hint]="!hint" [class.no-label]="!label" [class.has-errors]="invalid && touched">
  <mat-label *ngIf="label">{{label}}</mat-label>

  <ng-container>
    <mat-select #input #inputSelect="ngModel" [disabled]="disabled" [multiple]="multiple" [required]="required" [(ngModel)]="value" [disableOptionCentering]="true">
      <ng-container *ngIf="options">
        <mat-option *ngIf="searchable">
          <ngx-mat-select-search noEntriesFoundLabel="No option found" placeholderLabel="Search..." [(ngModel)]="search"
                                 (ngModelChange)="_options.notifyOnChanges(); searchValueChanged.emit(this.search)"></ngx-mat-select-search>
        </mat-option>
        <mat-option class="selection-button" [disabled]="true" *ngIf="selectAllOption">
          <button
          mat-raised-button
          class="mat-accent fill text-sm"
          (click)="selectAll(inputSelect, options)">
          Select All
        </button>
          <button
            mat-raised-button
            class="mat-primary fill text-sm"
            (click)="deselectAll(inputSelect)">
            Deselect All
          </button>
        </mat-option>
        <ng-container *ngFor="let option of options">
          <mat-option *ngIf="option.projectedTitle" [value]="option.value" [disabled]="option.disabled"
                      [ngClass]="option.projectedClass" [title]="option.projectedTitle">
            <ng-template [ngTemplateOutlet]="option.content"></ng-template>
          </mat-option>
          <mat-option *ngIf="!option.projectedTitle" [value]="option.value" [disabled]="option.disabled"
                      [ngClass]="option.projectedClass">
            <ng-template [ngTemplateOutlet]="option.content"></ng-template>
          </mat-option>
        </ng-container>
      </ng-container>
    </mat-select>
  </ng-container>

  <wbo-wrapper-suffix matSuffix [touched]="touched" [invalid]="invalid" [unchanged]="unchanged" [required]="required" [dirty]="dirty" [empty]="empty" [saveAction]="saveAction" [clearAction]="clearAction" [historyAction]="historyAction" [copyAction]="copyAction" (copyEvent)="copy()" (cancelEvent)="cancel()" (validateEvent)="validate()" (clearEvent)="clear()" (click)="$event.stopPropagation()">
    <ng-content select="[wboSuffix]"></ng-content>
  </wbo-wrapper-suffix>

  <mat-error *ngIf="touched && invalid">
    <ng-container *ngIf="required">This field cannot be empty.</ng-container>
  </mat-error>

  <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
</mat-form-field>
