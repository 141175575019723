import {WabelModel} from './wabel-model.abstract';
import * as moment from 'moment';

export class RegistrationNight extends WabelModel {
  constructor(obj?: any) {
    super(obj);
    if (obj) {
      this.date = obj.date ? new Date(obj.date) : null;
    }
  }

  date: Date;
  idcertaineventregistration: number;

  get id(): string {
    return this.formattedDate;
  }

  get formattedDate() {
    return moment(this.date).format('YYYY-MM-DD');
  }
}
