import {WabelModel} from './wabel-model.abstract';
import {PrivateMessage} from './private_message';
import {PrivateMessageRecipient} from './private_message_recipient';

export class PrivateMessageThread extends WabelModel {
  idthread: number;
  recipients: PrivateMessageRecipient[];
  messages: PrivateMessage[];
  subject: string;
  unread: boolean;
  lastMessageDate: string;
  privateMessageByInProgressVideoMeeting: PrivateMessage;
  readOnly: boolean;

  constructor(obj: any) {
    super(obj);

    if (obj) {
      this.idthread = obj.idthread ? +obj.idthread : 0;
      this.recipients = obj.recipients && obj.recipients.length > 0 ? obj.recipients.map(recipient => new PrivateMessageRecipient(recipient)) : [];
      this.messages = obj.messages && obj.messages.length > 0 ? obj.messages.map(message => new PrivateMessage(message)) : [];
    }
  }

  get id(): number {
    return this.idthread;
  }
}
