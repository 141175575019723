import {Directive, EventEmitter, HostListener, Input, Output, TemplateRef} from '@angular/core';
import {ConfirmService} from '../services/confirm.service';
import {Subscription} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmComponent} from '../components/confirm/confirm.component';

@Directive({
  selector: 'button[wboConfirm], a[wboConfirm]:not([routerLink])',
  exportAs: 'wboConfirm'
})
export class ConfirmDirective {

  confirmSubscription: Subscription
  cancelSubscription: Subscription
  @Input('wboConfirm') content: TemplateRef<any>
  @Input('wboContext') context: any
  @Input('wboTitle') title: string
  @Input('wboConfirmText') validateText: string = "Accept"
  @Input('wboCancelText') cancelText: string = 'Cancel'

  @Output() wboConfirmed = new EventEmitter<any>()
  @Output() wboCanceled = new EventEmitter<any>()

  @HostListener('click')
  onClick() {
    if (this.content) {
      this.confirmService.modalRef = this.dialog.open(ConfirmComponent, {
        data: {
          content: this.content,
          context: this.context,
          title: this.title,
          validateText: this.validateText,
          cancelText: this.cancelText,
        }
      });
    }

    this.confirmSubscription = this.confirmService.confirm$.subscribe(hasConfirmed => {
      if (hasConfirmed) this.wboConfirmed.emit(hasConfirmed);
      this.unsubscribe();
    })

    this.cancelSubscription = this.confirmService.cancel$.subscribe(hasCanceled => {
      if (hasCanceled) this.wboCanceled.emit();
      this.unsubscribe();
    })
  }

  constructor(public confirmService: ConfirmService, public dialog: MatDialog) {
  }

  unsubscribe(): void {
    if (this.confirmSubscription) this.confirmSubscription.unsubscribe();
    if (this.cancelSubscription) this.cancelSubscription.unsubscribe();
  }
}
