import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSliderModule} from '@angular/material/slider';
import {MatTooltipModule} from '@angular/material/tooltip';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {OptionComponent} from './components/select/option/option.component';
import {InputComponent} from './components/input/input.component';
import {SelectComponent} from './components/select/select.component';
import {TextareaComponent} from './components/input/textarea.component';
import {WrapperComponent} from './components/wrapper/wrapper.component';
import {WrapperSuffixComponent} from './components/wrapper/wrapper-suffix/wrapper-suffix.component';
import {DatetimePickerComponent} from './components/datetime-picker/datetime-picker.component';
import {MatMenuModule} from '@angular/material/menu';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDatetimepickerModule, MatNativeDatetimeModule} from '@mat-datetimepicker/core';
import {DatetimeRangePickerComponent} from './components/datetime-picker/datetime-range-picker.component';
import {ClipboardModule, ClipboardService} from 'ngx-clipboard';
import {UploadComponent} from './components/upload/upload.component';
import {MatFileUploadModule} from '@martyganz/mat-file-upload';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {SuffixDirective} from './directives/suffix.directive';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSlideToggleModule,
    MatRadioModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatChipsModule,
    MatIconModule,
    MatSliderModule,
    MatButtonToggleModule,
    MatMenuModule,
    MatDatepickerModule,
    MatDatetimepickerModule,
    MatNativeDatetimeModule,
    MatFileUploadModule,
    ClipboardModule,
    NgxMatSelectSearchModule,
    MatTooltipModule
  ],
  declarations: [
    InputComponent,
    TextareaComponent,
    SelectComponent,
    OptionComponent,
    WrapperComponent,
    WrapperSuffixComponent,
    DatetimePickerComponent,
    DatetimeRangePickerComponent,
    UploadComponent,
    SuffixDirective
  ],
  exports: [
    InputComponent,
    TextareaComponent,
    SelectComponent,
    OptionComponent,
    DatetimePickerComponent,
    DatetimeRangePickerComponent,
    UploadComponent,
    SuffixDirective
  ]
})
export class WabelBOFormModule {
}
