import {WabelModel} from './wabel-model.abstract';
import {Company} from './company';
import {Member} from './member';
import {Event} from './event';
import {CompanyRelationship} from './company_relationship';
import {Registration} from './registration';
import {MeetingRating} from './meeting_rating';
import {MeetingPreference} from './meeting_preference';
import {RegistrationSharedMeeting} from './registration_shared_meeting';
import {MeetingParticipantHistory} from './meeting_participant_history';
import * as moment from 'moment-timezone';

export class Meeting extends WabelModel {

  constructor(obj?: any) {
    super(obj);
    if (obj) {
      this.idmeeting = obj.idmeeting ? +obj.idmeeting : 0;
      this.certainEvent = obj.certainEvent ? new Event(obj.certainEvent) : null;
      this.memberBuyer = obj.memberBuyer ? new Member(obj.memberBuyer) : null;
      this.memberSupplier = obj.memberSupplier ? new Member(obj.memberSupplier) : null;
      this.companyBuyer = obj.companyBuyer ? new Company(obj.companyBuyer) : null;
      this.companySupplier = obj.companySupplier ? new Company(obj.companySupplier) : null;
      this.startDateStr = obj.startDate ? obj.startDate : null;
      this.endDateStr = obj.endDate ? obj.endDate : null;
      this.startDate = obj.startDate ? new Date(obj.startDate) : null;
      this.endDate = obj.endDate ? new Date(obj.endDate) : null;
      this.memberOppositeRegistration = obj.memberOppositeRegistration ? new Registration(obj.memberOppositeRegistration) : null;
      this.supplierRegistration = obj.supplierRegistration ? new Registration(obj.supplierRegistration) : null;
      this.buyerRegistration = obj.buyerRegistration ? new Registration(obj.buyerRegistration) : null;
      this.supplierRatings = obj.supplierRatings ? obj.supplierRatings.map(rating => new MeetingRating(rating)) : [];
      this.buyerRatings = obj.buyerRatings ? obj.buyerRatings.map(rating => new MeetingRating(rating)) : [];
      this.meetingPreferenceAssociated = obj.meetingPreferenceAssociated ? new MeetingPreference(obj.meetingPreferenceAssociated) : null;
      this.eventRegistrationSharedMeetings = obj.eventRegistrationSharedMeetings && obj.eventRegistrationSharedMeetings.items ? obj.eventRegistrationSharedMeetings.items.map(meeting => new RegistrationSharedMeeting(meeting)) : null;
      this.createdBy = obj.createdBy ? new Member(obj.createdBy) : null;
      this.createdDate = obj.createdDate ? new Date(obj.createdDate) : null;
      this.modifiedBy = obj.modifiedBy ? new Member(obj.modifiedBy) : null;
      this.modifiedDate = obj.modifiedDate ? new Date(obj.modifiedDate) : null;
      this.meetingParticipantsHistory = obj.meetingParticipantsHistory ? obj.meetingParticipantsHistory.map(meetingParticipantHistory => new MeetingParticipantHistory(meetingParticipantHistory)) : [];
      this.countCurrentlyConnectedSuppliers = obj.currentlyConnectedSuppliers && obj.currentlyConnectedSuppliers.count ? obj.currentlyConnectedSuppliers.count : 0;
      this.countCurrentlyConnectedBuyers = obj.currentlyConnectedBuyers && obj.currentlyConnectedBuyers.count ? obj.currentlyConnectedBuyers.count : 0;
      this.countCurrentlyConnectedOthers = obj.currentlyConnectedOthers && obj.currentlyConnectedOthers.count ? obj.currentlyConnectedOthers.count : 0;
      this.countCurrentlyDisconnectedSuppliers = obj.currentlyDisconnectedSuppliers && obj.currentlyDisconnectedSuppliers.count ? obj.currentlyDisconnectedSuppliers.count : 0;
      this.countCurrentlyDisconnectedBuyers = obj.currentlyDisconnectedBuyers && obj.currentlyDisconnectedBuyers.count ? obj.currentlyDisconnectedBuyers.count : 0;
      this.currentlyConnectedSuppliers = obj.currentlyConnectedSuppliers && obj.currentlyConnectedSuppliers.items ? obj.currentlyConnectedSuppliers.items.map(meetingParticipantHistory => new MeetingParticipantHistory(meetingParticipantHistory)) : [];
      this.currentlyConnectedBuyers = obj.currentlyConnectedBuyers && obj.currentlyConnectedBuyers.items && obj.currentlyConnectedBuyers.items.length > 0 ? obj.currentlyConnectedBuyers.items.map(meetingParticipantHistory => new MeetingParticipantHistory(meetingParticipantHistory)) : [];
      this.currentlyConnectedOthers = obj.currentlyConnectedOthers && obj.currentlyConnectedOthers.items && obj.currentlyConnectedOthers.items.length > 0 ? obj.currentlyConnectedOthers.items.map(meetingParticipantHistory => new MeetingParticipantHistory(meetingParticipantHistory)) : [];
      this.countMeetingChats = obj.meetingChats && obj.meetingChats.count ? obj.meetingChats.count : 0;
      if (this.startDate) {
        this.startDate.setSeconds(0);
      }
      if (this.endDate) {
        this.endDate.setSeconds(0);
      }
    }
  }

  idmeeting: number;
  companyBuyer: Company;
  companySupplier: Company;
  memberBuyer: Member;
  memberSupplier: Member;
  certainEvent: Event;
  relation: CompanyRelationship;
  location: string;
  status: string;
  startDate: Date;
  endDate: Date;
  startDateStr: string;
  endDateStr: string;
  lateNotificationSupplierSent: boolean;
  lateNotificationBuyerSent: boolean;
  durationInMinutes: number;
  buyerPresent: boolean;
  supplierPresent: boolean;
  memberOppositeRegistration: Registration;
  supplierRegistration: Registration;
  buyerRegistration: Registration;
  supplierRatings: MeetingRating[];
  buyerRatings: MeetingRating[];
  meetingPreferenceAssociated: MeetingPreference;
  eventRegistrationSharedMeetings: RegistrationSharedMeeting[];
  createdBy: Member;
  createdDate: Date;
  modifiedBy: Member;
  modifiedDate: Date;
  firstMeetingOfDayForBuyer: boolean;
  firstMeetingOfDayForSupplier: boolean;
  firstMeetingOfEventForBuyer: boolean;
  firstMeetingOfEventForSupplier: boolean;
  lastMeetingOfDayForBuyer: boolean;
  lastMeetingOfDayForSupplier: boolean;
  lastMeetingOfEventForBuyer: boolean;
  lastMeetingOfEventForSupplier: boolean;
  buyerHasDoneWebRtcTestDuringLast30Days: boolean;
  supplierHasDoneWebRtcTestDuringLast30Days: boolean;
  directMeetingUrlOrLocation: string;
  meetingProvider: string;
  meetingParticipantsHistory: MeetingParticipantHistory[];
  countCurrentlyConnectedSuppliers: number;
  countCurrentlyConnectedBuyers: number;
  countCurrentlyConnectedOthers: number;
  countCurrentlyDisconnectedSuppliers: number;
  countCurrentlyDisconnectedBuyers: number;
  countCurrentlyDisconnectedOthers: number;
  currentlyConnectedSuppliers: MeetingParticipantHistory[];
  currentlyConnectedBuyers: MeetingParticipantHistory[];
  currentlyConnectedOthers: MeetingParticipantHistory[];
  countMeetingChats: number;
  historyInfo: string;
  type: string;

  private startEventTZ: Date;
  private endEventTZ: Date;

  get id(): number {
    return +this.idmeeting;
  }

  set id(id: number) {
    this.idmeeting = id;
  }

  get formattedStartDate() {
    return this.startDate.toLocaleString();
  }

  get formattedEndDate() {
    return this.endDate.toLocaleString();
  }

  startDateEvent(timezone: string): Date {
    if (this.startEventTZ) {
      return this.startEventTZ;
    }
    let computerTimeZone = moment.tz.guess();
    let dateString = this.startDateStr.split('+')[0];
    const eventDateTz = moment.tz(dateString, timezone);
    this.startEventTZ = moment.tz(eventDateTz.format('YYYY-MM-DDTHH:mm:ss'), computerTimeZone).toDate();
    return this.startEventTZ;
  }

  endDateEvent(timezone: string) {
    if (this.endEventTZ) {
      return this.endEventTZ;
    }
    let computerTimeZone = moment.tz.guess();
    let dateString = this.endDateStr.split('+')[0];
    const eventDateTz = moment.tz(dateString, timezone);
    this.endEventTZ = moment.tz(eventDateTz.format('YYYY-MM-DDTHH:mm:ss'), computerTimeZone).toDate();
    return this.endEventTZ;
  }
}
