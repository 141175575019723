import {Tag} from './tag';
import {SourcingProjectProspect} from '@models/sourcing_project_prospect';
import {isNaN} from '@amcharts/amcharts4/core';
import {WabelModel} from '@models/wabel-model.abstract';
import {Member} from '@models/member';

export class SourcingProject extends WabelModel {
    constructor(obj: any) {
        super(obj);

        if (obj) {
            this.deadline = obj.deadline ? new Date(obj.deadline) : null;
            this.createdDate = obj.createdDate ? new Date(obj.createdDate) : null;
            this.member = obj.member ? new Member(obj.member) : null;
            this.tags = obj.tags?.items ? obj.tags.items.map(t => new Tag(t)) : [];
            this.prospects = obj.sourcingProjectProspects?.items ? obj.sourcingProjectProspects.items.map(t => new SourcingProjectProspect(t)) : [];
            this.prospectCount = isNaN(obj.sourcingProjectProspects?.count) ? null : obj.sourcingProjectProspects.count;
        }
    }

    id: number;
    name: string;
    description: string;
    status: string;
    deadline: Date;
    createdDate: Date;
    tags: Tag[];
    prospects: SourcingProjectProspect;
    prospectCount: number;
    member: Member;
}
