import {WabelModel} from './wabel-model.abstract';
import {Company} from './company';
import {EventWorkshop} from './event_workshop';

export class EventExpert extends WabelModel {
  constructor(obj?: any) {
    super(obj);
    if (obj) {
      this.ideventexpert = obj.ideventexpert ? +obj.ideventexpert : 0;
      this.company = obj.company ? new Company(obj.company) : null;
      this.workshops = obj.workshops && obj.workshops.items ? obj.workshops.items.map(workshop => new EventWorkshop(workshop)) : [];
      this.nbWorkshops = obj.workshops && obj.workshops.count ? obj.workshops.count : 0;
    }
  }

  ideventexpert: number;
  company: Company;
  workshops: EventWorkshop[] = [];
  nbWorkshops: number;
  description: string;
  acceptsMeetings: boolean;

  get id(): number {
    return this.ideventexpert;
  }
}
