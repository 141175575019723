import {Component, forwardRef, Input} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {InputAbstract} from '../input-abstract';
import {ClipboardService} from 'ngx-clipboard';

@Component({
  selector: 'wbo-datetime-picker',
  templateUrl: './datetime-picker.component.html',
  styleUrls: ['../input.scss', './datetime-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatetimePickerComponent),
      multi: true
    }
  ],
})
export class DatetimePickerComponent extends InputAbstract {
  constructor(clipboardService: ClipboardService) {
    super(clipboardService);
  }

  @Input('min') minDate: Date;
  @Input('max') maxDate: Date;
  @Input() type: "date" | "time" | "month" | "datetime" = 'date';
  @Input() timeInterval = 5;
}
