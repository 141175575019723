import {WabelModel} from './wabel-model.abstract';
import {EventType} from './event_type';
import {CompanyType} from './company_type';
import {SubscriptionPack} from './subscription_pack';
import {RolePermission} from './role_permission';

export class Role extends WabelModel {
  constructor(obj: any) {
    super(obj);
    if (obj) {
      this.eventType = obj.eventType ? new EventType(obj.eventType) : null;
      this.companyTypes = obj.companytypes ? obj.companytypes.map(type => new CompanyType(type)) : null;
      this.subscriptionPacks = obj.subscriptionPacks ? obj.subscriptionPacks.map(pack => new SubscriptionPack(pack)) : null;
      this.rolePermissions = obj.rolesPermissions && obj.rolesPermissions.items ? obj.rolesPermissions.items.map(rp => new RolePermission(rp)) : null;
      this.rolePermissionsNumber = obj.rolesPermissions && obj.rolesPermissions.count ? obj.rolesPermissions.count : null;
    }
  }

  name: string;
  eventType: EventType;
  companyTypes: CompanyType[];
  subscriptionPacks: SubscriptionPack[];
  rolePermissions: RolePermission[];
  rolePermissionsNumber: number;

  get id(): string {
    return this.name;
  }

  get scope(): string[] {
    const types = [];
    if (this.eventType) {
      types.push(RoleTypes.EVENT_PARTICIPATION);
    }
    if (this.companyTypes && this.companyTypes.length) {
      types.push(RoleTypes.COMPANY_TYPE);
    }
    if (this.subscriptionPacks && this.subscriptionPacks.length) {
      types.push(RoleTypes.SUBSCRIPTION_PACK);
    }
    if (!types.length) {
      types.push(RoleTypes.MEMBER);
    }
    return types;
  }
}

export const RoleTypes = {
  MEMBER: 'Member Role',
  COMPANY_TYPE: 'Company Type Role',
  EVENT_PARTICIPATION: 'Event Participation Role',
  SUBSCRIPTION_PACK: 'Subscription Pack Role'
}
