import {WabelModel} from './wabel-model.abstract';
import {Tag} from './tag';
import {Banner} from './banner';

export class BannerStore extends WabelModel {
  constructor(obj?: any) {
    super(obj);
    if (obj) {
      this.idbannerstore = obj.idbannerstore ? +obj.idbannerstore : 0;
      this.banner = obj.banner ? new Banner(obj.banner) : null;
      this.country = obj.country ? new Tag(obj.country) : null;
      this.hypers = obj.hypers ? obj.hypers : 0;
      this.conveniences = obj.conveniences ? obj.conveniences : 0;
      this.supermarkets = obj.supermarkets ? obj.supermarkets : 0;
      this.distributionCenters = obj.distributionCenters ? obj.distributionCenters : 0;
      this.warehouses = obj.warehouses ? obj.warehouses : 0;
      this.pointOfSales = obj.pointOfSales ? obj.pointOfSales : 0;
      this.cashAndCarries = obj.cashAndCarries ? obj.cashAndCarries : 0;
      this.total = obj.total ? obj.total : 0;
    }
  }

  idbannerstore: number;
  banner: Banner;
  country: Tag;
  hypers: number;
  conveniences: number;
  supermarkets: number;
  distributionCenters: number;
  warehouses: number;
  pointOfSales: number;
  cashAndCarries: number;
  total: number;

  get id(): number {
    return this.idbannerstore;
  }

}
