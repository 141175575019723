<h1 *ngIf="data.title" mat-dialog-title>
  {{data.title}}
</h1>

<div mat-dialog-content>
  <ng-container *ngTemplateOutlet="data.content; context:{$implicit: data.context}"></ng-container>
</div>

<mat-dialog-actions>
  <button *ngIf="data.validateText" mat-stroked-button mat-dialog-close color="accent" (click)="confirmService.confirm.next(confirmMessage)">
    {{data.validateText}}
  </button>
  <button *ngIf="data.cancelText" mat-stroked-button mat-dialog-close (click)="confirmService.cancel.next(confirmMessage)">
    {{data.cancelText}}
  </button>
</mat-dialog-actions>
