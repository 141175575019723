import {Observable} from 'rxjs';
import {GraphQLService} from '../graphql/graphql-service';
import {LoadingService} from 'wabel-bo';
import {catchError, filter, take, tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {NotificationService} from '../notification.service';
import {ApolloQueryResult} from '@apollo/client';
import {ResultIterator} from '@models/types/result-iterator';

@Injectable({
  providedIn: 'root'
})
export abstract class WabelService {

  abstract readonly defaultEntity;

  constructor(
    protected graphQLService: GraphQLService,
    protected loadingService: LoadingService,
    private notificationService: NotificationService
  ) {
  }

  static _ObjectToParams(obj) {
    const p = [];
    for (const key in obj) {
      if (typeof obj[key] === 'object') {
        for (const subkey in obj[key]) {
          if (typeof obj[key][subkey] === 'object') {
            for (const subsubkey in obj[key][subkey]) {
              p.push(key + '[' + subkey + '][' + subsubkey + ']' + '=' + encodeURIComponent(obj[key][subkey][subsubkey]));
            }
          } else {
            p.push(key + '[' + subkey + ']' + '=' + encodeURIComponent(obj[key][subkey]));
          }
        }
      } else {
        p.push(key + '=' + encodeURIComponent(obj[key]));
      }
    }
    return p.join('&');
  }

  query(
    graphQLQuery: any,
    data: any = null,
    fetchPolicy: string = 'cache-and-network',
    option: {errorAlert?: boolean, pollInterval?: number, nextFetchPolicy?: string} = {errorAlert: true, pollInterval: null, nextFetchPolicy: 'no-cache'}
  ): Observable<ApolloQueryResult<any>> {
    this.loadingService.startRequestLoading();
    return this.graphQLService.query(graphQLQuery, data, fetchPolicy, option?.pollInterval, option?.nextFetchPolicy)
      .valueChanges
      .pipe(
        filter((v) => v.data !== undefined),
        tap((v) => {
          if (!v.loading) {
            this.loadingService.endRequestLoading();
          }
        }),
        catchError((error) => {
          const errorMessages = this.detectErrorMessages(error);
          if (option.errorAlert && errorMessages.length) {
            this.notificationService.showError(errorMessages.join('. '));
          }
          this.loadingService.endRequestLoading();
          throw new Error(errorMessages.join('. '));
        })
      );
  }

  mutation(graphQLMutation: any, data: any = null, refetch?: any): Observable<any> {
    this.loadingService.startRequestLoading();
    return this.graphQLService.mutation(graphQLMutation, data, refetch)
      .pipe(
        take(1),
        tap(() => {
          this.loadingService.endRequestLoading();
        }),
        catchError((error) => {
          const errorMessages = this.detectErrorMessages(error);
          this.notificationService.showError(errorMessages.join('. '));
          this.loadingService.endRequestLoading();
          throw new Error(errorMessages.join('. '));
        })
      );
  }

  private detectErrorMessages(error: any): string[] {
    let errorList;
    if (error && error.networkError && error.networkError.result && error.networkError.result.errors) {
      errorList = error.networkError.result.errors.map((m) => m.message);
    } else if (error.message) {
      errorList = [error.message];
    } else {
      errorList = ['Unexpected error, please contact Tech Team'];
    }
    console.error(error);
    console.error(errorList);
    return errorList;
  }

  get busy() {
    return this.loadingService.requestInProcess;
  }

  protected objToEntity<T>(type: (new(a: any) => T), obj: any): T {
    return new type(obj);
  }

  protected objToEntities<T>(type: (new(a: any) => T), obj: any): T[] {
    return obj.map((e) => new type(e));
  }

  protected objToResultIterator<T>(type: (new(a: any) => T), obj: any): ResultIterator<T> {
    return {
      count: obj.count ? obj.count : 0,
      items: obj.items ? obj.items.map((e) => new type(e)) : []
    };
  }
}
