import {WabelModel} from './wabel-model.abstract';
import {Event} from './event';
import {Tag} from './tag';
import * as moment from 'moment/moment';

export class Summit extends WabelModel {

  private startEventTZ: Date;
  private endEventTZ: Date;

  constructor(obj?: any) {
    super(obj);
    if (obj) {
      this.certainEvent = obj.certainEvent ? new Event(obj.certainEvent) : null;
      if (obj.startDate) {
        this.startDateStr = obj.startDate;
        this.startDate = new Date(obj.startDate);
      }
      if (obj.endDate) {
        this.endDateStr = obj.endDate;
        this.endDate = new Date(obj.endDate);
      }
      this.rootCategories = obj.rootCategories && obj.rootCategories.items ? obj.rootCategories.items.map(category => new Tag(category)) : [];
      this.categories = obj.categories && obj.categories.items ? obj.categories.items.map(category => new Tag(category)) : [];
      this.tags = obj.tags && obj.tags.items ? obj.tags.items.map(tag => new Tag(tag)) : [];
    }
  }

  summitCode: string;
  name: string;
  startDate: Date;
  endDate: Date;
  startDateStr: string;
  endDateStr: string;
  timezone: string;
  eventCode: string;
  city: string;
  nodeUrl: string;
  bannerUrl: string;
  color: string;
  planningImageUrl: string;
  floorMapImageUrl: string;
  certainEvent: Event;
  categories: Tag[];
  rootCategories: Tag[];
  logoUrl: string;
  tags: Tag[];
  preregisterSentence: string;
  marketingPageUrl: string;

  // Field used only for uploading logo
  newSummitLogo: File;
  newBanner: File;

  get id(): string {
    return this.summitCode;
  }

  get formattedStartDate(): string {
    return this.startDate ? this.startDate.toLocaleString([], {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    }) : null;
  }

  get formattedEndDate(): string {
    return this.endDate ? this.endDate.toLocaleString([], {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    }) : null;
  }

  startDateEvent(): Date {
    if (this.startEventTZ) {
      return this.startEventTZ;
    }
    let computerTimeZone = moment.tz.guess();
    let dateString = this.startDateStr.split('+')[0];
    const eventDateTz = moment.tz(dateString, this.timezone);
    this.startEventTZ = moment.tz(eventDateTz.format('YYYY-MM-DDTHH:mm:ss'), computerTimeZone).toDate();
    return this.startEventTZ;
  }

  endDateEvent() {
    if (this.endEventTZ) {
      return this.endEventTZ;
    }
    let computerTimeZone = moment.tz.guess();
    let dateString = this.endDateStr.split('+')[0];
    const eventDateTz = moment.tz(dateString, this.timezone);
    this.endEventTZ = moment.tz(eventDateTz.format('YYYY-MM-DDTHH:mm:ss'), computerTimeZone).toDate();
    return this.endEventTZ;
  }
}
