import {Injectable, TemplateRef} from '@angular/core';
import {MatSidenav} from '@angular/material/sidenav';

@Injectable({
  providedIn: 'root'
})
export class SidePanelService {
  sidePanelRef: MatSidenav;

  position: 'start' | 'end';
  mode: 'over' | 'push' | 'side';
  width: string;
  fixedInViewport: boolean;
  fixedTopGap: number;
  fixedBottomGap: number;

  content: TemplateRef<any>;
  context: any;
  opened = false;

  constructor() {
  }

  open(content: TemplateRef<any>, context: any = null, width: string = '50%', position: 'left' | 'right' = "left", mode: 'over' | 'push' | 'side' = "over") {
    if (this.sidePanelRef) {
      this.content = content;
      this.context = context;
      this.mode = mode;
      this.width = width;
      this.position = (position === 'right') ? 'end' : 'start';
      this.opened = true;
      this.sidePanelRef.open();
    }
  }

  close() {
    this.sidePanelRef.close();
    this.content = null;
    this.context = null;
    this.mode = null;
    this.width = null;
    this.opened = false;
    this.position = null;
  }
}
