import {Directive, OnDestroy, OnInit, TemplateRef} from '@angular/core';
import {TopBarService} from '../services/top-bar.service';

@Directive({
  selector: '[wboTopBar]'
})
export class TopBarDirective implements OnInit, OnDestroy {
  templateId: number;

  constructor(private topBarService: TopBarService, private templateRef: TemplateRef<any>) {
  }

  ngOnInit(): void {
    this.templateId = this.topBarService.addContent(this.templateRef);
  }

  ngOnDestroy(): void {
    this.topBarService.removeContent(this.templateId);
    this.templateId = null;
  }
}
