import {WabelModel} from './wabel-model.abstract';
import {CompanyRelationshipStatus} from './company_relationship_status';

export class CompanyRelationshipTagReference extends WabelModel {

  constructor(obj?: any) {
    super(obj);
    if (obj) {
      this.idtagreference = obj.idtagreference ? +obj.idtagreference : 0;
      this.status = obj.status ? new CompanyRelationshipStatus(obj.status) : null;
    }
  }

  idtagreference: number;
  name: string;
  tagGroup: string;
  status: CompanyRelationshipStatus;

  get id(): number {
    return this.idtagreference;
  }
}
