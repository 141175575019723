import {WabelModel} from './wabel-model.abstract';
import {Member} from './member';
import {PrivateMessageThread} from './private_message_thread';
import {Company} from './company';
import {CompanyProfileSubmission} from './company_profile_submission';

export class PrivateMessage extends WabelModel {
  idprivatemessage: number;
  member: Member;
  company: Company;
  message_type: string;
  body: string;
  createdAt: string;
  thread: PrivateMessageThread;
  messageType: string;
  companyProfileSubmissions: CompanyProfileSubmission[];

  constructor(obj: any) {
    super(obj);

    if (obj) {
      this.idprivatemessage = obj.idprivatemessage ? +obj.idprivatemessage : 0;
      this.member = obj.member ? new Member(obj.member) : null;
      this.company = obj.company ? new Company(obj.company) : null;
      this.thread = obj.thread ? new PrivateMessageThread(obj.thread) : null;
      this.companyProfileSubmissions = obj.companyProfileSubmissions && obj.companyProfileSubmissions.items ? obj.companyProfileSubmissions.items.map((proposal: any) => new CompanyProfileSubmission(proposal)) : [];
    }
  }

  get id(): number {
    return this.idprivatemessage;
  }
}
