import {FilePreviewableInterface} from './file-previewable-interface';
import {File} from './file';
import {Company} from './company';
import {WabelModel} from './wabel-model.abstract';

export class CompanyFile extends WabelModel implements FilePreviewableInterface {
  file: File;
  company: Company;
  fileType: string;
  downloadLink: string;
  imageUrl: string;
  imageUrl123: string;
  imageUrl300: string;
  imageUrl450: string;

  constructor(obj: any) {
    super(obj);
    if (obj) {
      this.company = obj.company ? new Company(obj.company) : null;
      this.file = obj.file ? new File(obj.file) : null;
    }
  }

  get id() {
    return this.file.id;
  }

  get dataBase64(): string {
    return this.file.fileName;
  }

  previewExtension(): string {
    return this.file.fileExtension;
  }

  previewFileName(): string {
    return this.file.fileName;
  }

  previewFileSize(): number {
    return this.file.fileSize;
  }

  previewId(): string {
    return '' + this.file.idfile;
  }

  previewSelected(): boolean {
    return false;
  }

  previewUrl(): string {
    return this.imageUrl ? this.imageUrl : this.downloadLink;
  }
}
