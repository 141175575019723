import {Component, OnInit} from '@angular/core';
import {MatFormFieldAppearance} from '@angular/material/form-field';

@Component({
  selector: 'wbo-inputs-doc',
  templateUrl: './inputs-doc.component.html',
  styleUrls: ['./inputs-doc.component.scss']
})
export class InputsDocComponent implements OnInit {

  constructor() {
  }

  formElement = '<wbo-input>';
  formElementTypes = ['<wbo-input>', '<wbo-textarea>', '<wbo-select>', '<wbo-datetime-picker>', '<wbo-datetime-range-picker>'];
  inputValue: any;
  colors = ['primary', 'accent', 'warn'];
  inputType: 'text' | 'color' | 'date' | 'datetime-local' | 'email' | 'month' | 'number' | 'password' | 'search' | 'tel' | 'time' | 'url' | 'week' = 'text';
  inputTypes = ['text', 'color', 'date', 'datetime-local', 'email', 'month', 'number', 'password', 'search', 'tel', 'time', 'url', 'week'];
  dateTimeType: 'date' | 'datetime' | 'time' | 'month' = 'date';
  dateTimeTypes = ['date', 'datetime', 'time', 'month'];
  appearances = ['legacy', 'standard', 'outline', 'fill'];
  appearance: MatFormFieldAppearance = 'standard';
  minDate = null;
  maxDate = null;
  placeholder = "Placeholder";
  label = 'Label';
  hint = 'This is a hint';
  required = false;
  multiple = false;
  saveAction = false;
  historyAction = false;
  clearAction = false;
  color = null;

  ngOnInit() {
  }

  get output() {
    if (Array.isArray(this.inputValue)) return this.inputValue.join(', ')
    return this.inputValue;
  }

}
