import {WabelModel} from './wabel-model.abstract';
import {Company} from './company';

export class CompanyClient extends WabelModel {
  idCompanyClient: number;
  client: Company;
  label: string;

  constructor(obj?: any) {
    super(obj);
    if (obj) {
      this.idCompanyClient = obj.idCompanyClient ? +obj.idCompanyClient : 0;
      this.client = obj.client ? new Company(obj.client) : null;
    }
  }

  get id(): number {
    return this.idCompanyClient;
  }
}
