import {WabelModel} from './wabel-model.abstract';
import {Tag} from './tag';

export class BusinessMappingTag extends WabelModel {
  id: number;
  tag: Tag;
  type: string;

  constructor(obj: any) {
    super(obj);
    if (obj) {
      this.tag = obj.tag ? new Tag(obj.tag) : null;
    }
  }

}
