import {WabelModel} from './wabel-model.abstract';
import {Company} from './company';
import {Registration} from './registration';
import {Meeting} from './meeting';
import {Score} from './score';
import {Summit} from './summit';
import {ListRowInterface} from './interfaces/list-row.interface';
import {MeetingRecommendation} from '@models/meeting_recommendation';

export class MeetingPreference extends WabelModel implements ListRowInterface {

  rowIndex = 0;

  constructor(obj?: any) {
    super(obj);
    if (obj) {
      this.idmeetingpreference = obj.idmeetingpreference ? +obj.idmeetingpreference : 0;
      this.company = obj.company ? new Company(obj.company) : null;
      this.registration = obj.registration ? new Registration(obj.registration) : null;
      this.meeting = obj.meeting ? new Meeting(obj.meeting) : null;
      this.productPreferenceCount = obj.productPreferences && obj.productPreferences.count ? obj.productPreferences.count : null;
      this.supplierSummits = obj.supplierSummits ? obj.supplierSummits.map(s => new Summit(s)) : null;
      this.score = obj.score ? new Score(obj.score) : null;
      this.recommendation = obj.recommendation ? new MeetingRecommendation(obj.recommendation) : null;
    }
  }

  idmeetingpreference: number;
  registration: Registration;
  company: Company;
  status: 'selected' | 'excluded';
  position: number;
  meeting: Meeting;
  productPreferenceCount: number;
  hasAnyRelationshipBetweenBuyerAndSupplier: boolean;
  supplierSummits: Summit[];
  creationType: string;
  dateCreated: Date;
  score: Score;
  recommendation: MeetingRecommendation;

  get id(): number {
    return this.idmeetingpreference;
  }
}
