import {Company} from './company';
import {WabelModel} from './wabel-model.abstract';
import {CompanyRelationship} from './company_relationship';
import {Registration} from './registration';
import {Tag} from './tag';
import {WebRTCResult} from './webrtc_result';
import {Event} from './event';
import {MemberRejectionReason} from '@models/memberRejectionReason';

export class Member extends WabelModel {

  constructor(obj?: any) {
    super(obj);
    if (obj) {
      this.idmember = obj.idmember ? +obj.idmember : 0;
      this.company = obj.company ? new Company(obj.company) : null;
      this.relations = obj.relations ? obj.relations.map(relation => new CompanyRelationship(relation)) : null;
      this.categories = obj.categories && obj.categories.items ? obj.categories.items.map(category => new Tag(category)) : [];
      this.registration = obj.registration ? new Registration(obj.registration) : null;
      this.dateLastLogged = obj.dateLastLogged ? new Date(obj.dateLastLogged) : null;
      this.dateLastActivity = obj.dateLastActivity ? new Date(obj.dateLastActivity) : null;
      this.buyingScope = obj.buyingScope && obj.buyingScope.items ? obj.buyingScope.items.map(category => new Tag(category)) : [];
      this.webRTCResults = obj.latestWebRTCResults && obj.latestWebRTCResults.items ? obj.latestWebRTCResults.items.map(webRTCResult => new WebRTCResult(webRTCResult)) : [];
      this.allEventRegistrations = obj.allEventRegistrations && obj.allEventRegistrations.items ? obj.allEventRegistrations.items.map(event => new Event(event)) : [];
      this.countPrivateMessageThreads = obj.privateMessageThreads && obj.privateMessageThreads.count ? obj.privateMessageThreads.count : 0;
      this.buyingScopeCategories = obj.buyingScopeCategories && obj.buyingScopeCategories.items ? obj.buyingScopeCategories.items.map(category => new Tag(category)) : [];
      this.buyingScopeActivities = obj.buyingScopeActivities && obj.buyingScopeActivities.items ? obj.buyingScopeActivities.items.map(category => new Tag(category)) : [];
      this.statusExpiryDate = obj.statusExpiryDate ? new Date(obj.statusExpiryDate) : null;
      this.rejectionReason = obj.rejectionReason ? new MemberRejectionReason(obj.rejectionReason) : null;
    }
  }

  zohoId: string;
  idmember: number;
  firstname: string;
  fullname: string;
  name: string;
  email: string;
  timezone: string;
  company: Company;
  relations: CompanyRelationship[];
  buyingScope: Tag[] = [];
  categories: Tag[] = [];
  title: string;
  logoUrl: string;
  jobtitle: string;
  status: string;
  profileScore: number;
  createdDate: string;
  mobile: string;
  permissions: string[];
  mobile_event: string;
  hasEmailVerified: boolean;
  isMasquerading: boolean;
  registration: Registration;
  isCompanyOwner: boolean;
  dateLastLogged: Date;
  dateLastActivity: Date;
  webRTCResults: WebRTCResult[] = [];
  hasCompletedCompanyProfileSubmissionWithBuyerCompany: boolean;
  allEventRegistrations: Event[] = [];
  countPrivateMessageThreads: number = 0;
  joinMode: string;
  buyingScopeCategories: Tag[] = [];
  buyingScopeActivities: Tag[] = [];
  needlTcAccepted: boolean;
  statusExpiryDate: Date;
  rejectionReason: MemberRejectionReason;
  customRejectionReason: string;

  get id(): number {
    return this.idmember;
  }

}
