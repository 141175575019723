// import the required animation functions from the angular animations module
import {trigger, animate, transition, style} from '@angular/animations';

export const slideEnterLeaveAnimation =
  // trigger name for attaching this animation to an element using the [@triggerName] syntax
  trigger('slideEnterLeaveAnimation', [

    // route 'enter' transition
    transition(':enter', [

      // css styles at start of transition
      style({opacity: 0, transform: 'translate(20px)'}),

      // animation and styles at end of transition
      animate('0.5s ease', style({opacity: 1, transform: 'translate(0)'}))
    ]),
  ]);

export const slideEnterAnimation =
  // trigger name for attaching this animation to an element using the [@triggerName] syntax
  trigger('slideEnterAnimation', [

    // route 'enter' transition
    transition(':enter', [

      // css styles at start of transition
      style({opacity: 0, transform: 'translate(20px)'}),

      // animation and styles at end of transition
      animate('1s ease', style({opacity: 1, transform: 'translate(0)'}))
    ]),
  ]);
