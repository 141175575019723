// import the required animation functions from the angular animations module
import {trigger, animate, transition, style} from '@angular/animations';

export const slideInTopAnimation =
  // trigger name for attaching this animation to an element using the [@triggerName] syntax
  trigger('slideInTopAnimation', [

    // route 'enter' transition
    transition(':enter', [

      // css styles at start of transition
      style({opacity: 0, transform: 'translateY(-100%)'}),

      // animation and styles at end of transition
      animate('0.5s ease', style({opacity: 1, transform: 'translateY(0)'}))
    ]),

    // route 'enter' transition
    transition(':leave', [

      // css styles at start of transition
      style({opacity: 1, transform: 'translateY(0)'}),

      // animation and styles at end of transition
      animate('0.5s ease', style({opacity: 0, transform: 'translateY(-100%)'}))
    ]),
  ]);

export const slideInLeftAnimation =
  // trigger name for attaching this animation to an element using the [@triggerName] syntax
  trigger('slideInLeftAnimation', [

    // route 'enter' transition
    transition(':enter', [

      // css styles at start of transition
      style({transform: 'translateX(-100%)', opacity: 0}),

      // animation and styles at end of transition
      animate('0.2s ease-out', style({opacity: 1, transform: 'translateX(0)'}))
    ]),

    // route 'enter' transition
    transition(':leave', [

      // css styles at start of transition
      style({transform: 'translateX(0)'}),

      // animation and styles at end of transition
      animate('0.2s ease-in', style({transform: 'translateX(-100%)', opacity: 0}))
    ]),
  ]);

export const slideInRightAnimation =
  // trigger name for attaching this animation to an element using the [@triggerName] syntax
  trigger('slideInRightAnimation', [

    // route 'enter' transition
    transition(':enter', [

      // css styles at start of transition
      style({transform: 'translateX(100%)'}),

      // animation and styles at end of transition
      animate('0.2s ease-out', style({opacity: 1, transform: 'translateX(0)'}))
    ]),

    // route 'enter' transition
    transition(':leave', [

      // css styles at start of transition
      style({transform: 'translateX(0)'}),

      // animation and styles at end of transition
      animate('0.2s ease-in', style({transform: 'translateX(100%)'}))
    ]),
  ]);
