import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  pageLoading: boolean;
  jobNumber: number;
  requestInProcess: BehaviorSubject<boolean>;

  constructor() {
    this.jobNumber = 0;
    this.startPageLoading();
    this.requestInProcess = new BehaviorSubject<boolean>(false);
  }

  startRequestLoading(): void {
    if (!this.jobNumber) this.requestInProcess.next(true)
    this.jobNumber++;
  }

  endRequestLoading(): void {
    if (this.jobNumber) this.jobNumber--;
    if (!this.jobNumber) this.requestInProcess.next(false)
  }

  get requestInProcess$() {
    return this.requestInProcess.asObservable();
  }

  startPageLoading(): void {
    this.pageLoading = true;
  }

  endPageLoading(): void {
    this.pageLoading = true;
  }

  failpageLoading(): void {
    this.pageLoading = null;
  }
}
