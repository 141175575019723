import {BehaviorSubject, Observable, of, Subject, Subscription} from 'rxjs';
import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {filter, takeUntil} from 'rxjs/operators';
import {FilterCallback} from '../directives/filter-callback.directive';
import {TableDataRequest, TableDataResult} from '../../types';

export type TableDataSourceFilterMessage = { limit: number, offset: number, orderBy: string, filterBag: any };

export class TableDataSource<T> extends DataSource<T> {

  urlFiltering: boolean;
  filterCallbacks: FilterCallback[] = [];
  request: TableDataRequest<T>;
  loading = false;
  hasLoaded = false;
  endSubscription = new Subject<void>();
  requestSubscription: Subscription;

  _data: T[];
  set data(data: T[]) {
    this._data = data;
    this.filteredData = this.callbackFiltering(data);
  }

  get data(): T[] {
    return this._data;
  }

  _filteredData: T[];
  set filteredData(data: T[]) {
    this.filteredData$.next(data);
    this._filteredData = data;
    if (!this.hasLoaded) {
      this.loading = false;
      this.hasLoaded = true;
    }
  }

  get filteredData(): T[] {
    return this._filteredData;
  }

  private filteredData$: BehaviorSubject<T[]> = new BehaviorSubject<T[]>(undefined);


  _count: number;
  set count(count: number) {
    this.countSubject.next(count);
    this._count = count;
  }

  get count(): number {
    return this._count;
  }

  private countSubject = new BehaviorSubject<number>(undefined);

  get count$() {
    return this.countSubject.asObservable();
  }

  connect(collectionViewer: CollectionViewer): Observable<T[]> {
    return this.filteredData$.asObservable().pipe(filter(data => data !== undefined));
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.filteredData$.complete();
  }

  filter(queryParams: TableDataSourceFilterMessage): void {
    if (!this.hasLoaded) {
      this.loading = true;
    }
    if (this.request) {
      this.loading = true;
      this.remoteFilter(this.request, queryParams.limit, queryParams.offset, queryParams.orderBy, queryParams.filterBag);
    } else {
      this.localFilter();
    }
  }

  private localFilter(): void {
    this.filteredData = this.callbackFiltering(this.data);
  }

  private remoteFilter(request: TableDataRequest<T> = null, limit?: number, offset?: number, sort?: string, filterBag?: any): void {
    if (this.requestSubscription) {
      this.endSubscription.next();
      this.requestSubscription = null;
    }
    this.requestSubscription = request(limit, offset, sort, filterBag)
      .pipe(takeUntil(this.endSubscription))
      .subscribe((data: TableDataResult<T>) => {
        this.data = data.items;
        this.countSubject.next(data.count);
        this.loading = false;
      });
  }

  private callbackFiltering(data: T[]): T[] {
    let filteredData = data;
    if (filteredData && this.filterCallbacks.length) {
      this.filterCallbacks.forEach((filterCallback: FilterCallback) => {
        filteredData = filteredData.filter(data => filterCallback.wboFilterCallback(data, filterCallback.input?.value));
      });
      this.countSubject.next(filteredData.length);
    }
    return filteredData;
  }
}
