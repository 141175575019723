import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ConfirmService} from '../../services/confirm.service';

@Component({
  selector: 'wbo-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public confirmService: ConfirmService) {
  }

  get confirmMessage() {
    if (this.data.context === undefined) {
      return true;
    }
    return this.data.context;
  }
}
