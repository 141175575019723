import {WabelModel} from './wabel-model.abstract';
import {Member} from './member';

export class CompanyRelationshipComment extends WabelModel {

  constructor(obj?: any) {
    super(obj);
    if (obj) {
      this.idcomment = obj.idcomment ? +obj.idcomment : 0;
      this.member = obj.member ? new Member(obj.member) : null;
    }
  }

  idcomment: number;
  comment: string;
  createdDate: string;
  updatedDate: string;
  member: Member;

  get id(): number {
    return this.idcomment;
  }

}
