import {WabelModel} from './wabel-model.abstract';
import {SourcingNeed} from './sourcing_need';
import {Product} from './product';
import {SourcingNeedRecommendationMemberResponse} from './sourcing_need_recommendation_member_response';

export class SourcingNeedRecommendation extends WabelModel {
  constructor(obj: any) {
    super(obj);

    if (obj) {
      this.id = obj.id ? +obj.id : 0;
      this.sourcingNeed = obj.sourcingNeed ? new SourcingNeed(obj.sourcingNeed) : null;
      this.product = obj.product ? new Product(obj.product) : null;
      this.applications = obj.applications && obj.applications.items ? obj.applications.items.map(application => new SourcingNeedRecommendationMemberResponse(application)) : [];
      this.sourcingNeedRecommendationMemberResponses = obj.sourcingNeedRecommendationMemberResponses && obj.sourcingNeedRecommendationMemberResponses.items ? obj.sourcingNeedRecommendationMemberResponses.items.map(sourcingNeedRecommendationMemberResponse => new SourcingNeedRecommendationMemberResponse(sourcingNeedRecommendationMemberResponse)) : [];
      this.mainApplication = obj.mainApplication ? new SourcingNeedRecommendationMemberResponse(obj.mainApplication) : null;
    }
  }

  id: number;
  sourcingNeed: SourcingNeed;
  product: Product;
  createdDate: string;
  matchingScore: number;
  isNew: boolean;
  notificationSent: boolean;
  apparitionDateSeller: string;
  applications: SourcingNeedRecommendationMemberResponse[];
  mainApplication: SourcingNeedRecommendationMemberResponse;
  sourcingNeedRecommendationMemberResponses: SourcingNeedRecommendationMemberResponse[];
}
