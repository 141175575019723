import {Injectable} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Injectable()
export class ModalService {
  _modalRef: MatDialogRef<any>
  get modalRef() {
    return this._modalRef;
  }

  set modalRef(modalRef: MatDialogRef<any>) {
    if (this._modalRef) this.close()
    this._modalRef = modalRef
  }

  close() {
    this._modalRef.close();
  }
}
