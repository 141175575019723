import {Injectable} from '@angular/core';
import {Member} from '@models/member';

const ADMIN = 'ACCESS_BO';
const SUPER_ADMIN = 'SUPER_ADMIN';

@Injectable({
  providedIn: 'root'
})
export class RightsService {
  constructor() {
  }

  me: Member;
  urlBeforeAccessDenied: string = null;

  canAccesBo(member: Member) {
    this.me = member;
    return this.me && this.me.permissions && this.me.permissions.indexOf(ADMIN) !== -1;
  }

  isSuperAdmin(member: Member = null) {
    if (member) {
      return member && member.permissions.indexOf(SUPER_ADMIN) !== -1;
    }
    return this.me && this.me.permissions.indexOf(SUPER_ADMIN) !== -1;
  }

  canDisplayCopyIdButtons() {
    return this.me && this.me.permissions.indexOf(ADMIN) !== -1;
  }
}
