import {WabelModel} from './wabel-model.abstract';
import {Company} from './company';
import {Registration} from './registration';
import {Score} from './score';
import {MeetingPreference} from '@models/meeting_preference';

export class MeetingRecommendation extends WabelModel {

  constructor(obj?: any) {
    super(obj);
    if (obj) {
      this.company = obj.company ? new Company(obj.company) : null;
      this.registration = obj.registration ? new Registration(obj.registration) : null;
      this.preference = obj.meetingPreference ? new MeetingPreference(obj.meetingPreference) : null;
      this.score = obj.score ? new Score(obj.score) : null;
    }
  }

  registration: Registration;
  company: Company;
  preference: MeetingPreference;
  score: Score;

  get id(): number {
    return 0;
  }
}
