import {
  AfterViewInit,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  QueryList,
  ViewChild,
  Directive
} from '@angular/core';
import {MatFormFieldControl} from '@angular/material/form-field';
import {FormControl, Validators} from '@angular/forms';
import {InputAbstract} from './input-abstract';
import {OptionComponent} from './select/option/option.component';
import {Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {coerceBooleanProperty} from '@angular/cdk/coercion';

@Directive()
export abstract class InputWithOptions extends InputAbstract implements AfterViewInit, OnDestroy {
  options: OptionComponent[];
  protected subscription: Subscription;
  @ContentChildren(OptionComponent) _options: QueryList<OptionComponent>;

  search: string = null;

  private _searchable: boolean;
  get searchable(): boolean {
    return this._searchable;
  }

  @Input()
  set searchable(value: boolean) {
    this._searchable = coerceBooleanProperty(value);
  }

  private _selectAllOption: boolean;
  get selectAllOption(): boolean {
    return this._selectAllOption;
  }

  @Input()
  set selectAllOption(value: boolean) {
    this._selectAllOption = coerceBooleanProperty(value);
  }

  ngAfterViewInit(): void {
    this.options = this._options.toArray();
    this.subscription = this._options.changes
      .pipe(
        map((queryList: QueryList<OptionComponent>) => queryList.filter(option => {
          if (this.searchable) {
            const optionValue = this.removeSpecialChars(option.value + '');
            const optionSearchValue = this.removeSpecialChars(option.searchValue);
            const search = this.removeSpecialChars(this.search);
            return !search || (optionSearchValue && optionSearchValue.includes(search)) || optionValue.includes(search);
          }
          return true;
        }))
      )
      .subscribe(options => this.options = options);
  }

  protected removeSpecialChars(text?: string): string {
    return text ? text.toLowerCase().replace(/ /g, '').normalize("NFD").replace(/[\u0300-\u036f]/g, "") : null;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
