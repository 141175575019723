// import the required animation functions from the angular animations module
import {trigger, animate, transition, style} from '@angular/animations';

export const fadeAnimation =
  // trigger name for attaching this animation to an element using the [@triggerName] syntax
  trigger('fadeAnimation', [

    // route 'enter' transition
    transition(':enter', [

      // css styles at start of transition
      style({opacity: 0}),

      // animation and styles at end of transition
      animate('0.2s ease-in', style({opacity: 1}))
    ]),

    // route 'enter' transition
    transition(':leave', [
      style({opacity: 1}),
      // animation and styles at end of transition
      animate('0.2s ease-out', style({opacity: 0}))
    ]),
  ]);
