<mat-form-field #formField  class="wabel-bo-input" [color]="color" [appearance]="appearance" [class.filled]="appearance === 'fill'" [class.no-hint]="!hint" [class.no-label]="!label" [class.has-errors]="invalid && touched">
  <mat-label *ngIf="label">{{label}}</mat-label>

  <ng-container *ngIf="icon" matPrefix>
    <mat-icon>{{icon}}</mat-icon>
  </ng-container>

  <input #input [readonly]="readonly" [disabled]="disabled" [required]="required" matInput [type]="type" [email]="type === 'email'" [placeholder]="placeholder" [(ngModel)]="value"  [attr.maxlength]="maxlength > 0 ? maxlength : null"/>

  <wbo-wrapper-suffix matSuffix [touched]="touched" [invalid]="invalid" [unchanged]="unchanged" [required]="required" [dirty]="dirty" [empty]="empty" [saveAction]="saveAction" [clearAction]="clearAction" [historyAction]="historyAction" [copyAction]="copyAction" (copyEvent)="copy()" (cancelEvent)="cancel()" (validateEvent)="validate()" (clearEvent)="clear()" (click)="$event.stopPropagation()">
    <ng-content select="[wboSuffix]"></ng-content>
  </wbo-wrapper-suffix>

  <mat-error *ngIf="touched && invalid">
    <ng-container *ngIf="ngModel.errors.required">This field cannot be empty.</ng-container>
    <ng-container *ngIf="ngModel.errors.email">Please enter a valid email address.</ng-container>
    <ng-container *ngIf="ngModel.errors.url">Please enter a valid URL.</ng-container>
  </mat-error>

  <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
</mat-form-field>
