import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {ModalService} from './modal.service';
import {MatDialogRef} from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class ConfirmService {
  constructor(private modalService: ModalService) {
  }

  get modalRef() {
    return this.modalService.modalRef;
  }

  set modalRef(modalRef: MatDialogRef<any>) {
    this.modalService.modalRef = modalRef;
  }

  confirm = new Subject<any>()

  get confirm$(): Observable<any> {
    return this.confirm.asObservable();
  }

  cancel = new Subject<any>()

  get cancel$(): Observable<any> {
    return this.cancel.asObservable();
  }
}
