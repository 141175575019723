import {WabelModel} from './wabel-model.abstract';
import {Tag} from './tag';

export class TagType extends WabelModel {
  constructor(obj: any) {
    super(obj);
    if (obj) {
      this.id = obj.id ? +obj.id : 0;
      this.tags = obj.tags && obj.tags.items ? obj.tags.items.map(t => new Tag(t)) : [];
      this.tagsCount = obj.tags && obj.tags.count ? obj.tags.count : 0;
    }
  }

  id: number;
  name: string;
  internalName: string;
  tags: Tag[];
  tagsCount: number;
}
