import {Component, forwardRef, Input} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {InputAbstract} from '../input-abstract';
import {DatePipe} from '@angular/common';
import {ClipboardService} from 'ngx-clipboard';

@Component({
  selector: 'wbo-datetime-range-picker',
  templateUrl: './datetime-range-picker.component.html',
  styleUrls: ['../input.scss', './datetime-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatetimeRangePickerComponent),
      multi: true
    }
  ],
})
export class DatetimeRangePickerComponent extends InputAbstract {

  constructor(public datePipe: DatePipe, clipboardService: ClipboardService) {
    super(clipboardService);
  }

  @Input() type: "date" | "time" | "month" | "datetime" = 'date';
  @Input() timeInterval = 5;

  get startValue() {
    return this.value ? this.value[0] : null
  }

  set startValue(value) {
    this.setValue(value, 0)
  }

  get endValue() {
    return this.value ? this.value[1] : null
  }

  set endValue(value) {
    this.setValue(value, 1)
  }

  setValue(value, index) {
    let currentValue = this.value || [];
    currentValue[index] = value;
    this._value = currentValue;
    if (currentValue[0] && currentValue[1]) {
      this.writeValue(currentValue);
    }
  }

  get unchanged() {
    return this.previousValue === this.value;
  }

  @Input('min') startMinDate;

  get startMaxDate() {
    return this.endValue || this.endMaxDate;
  };

  get endMinDate() {
    return this.startValue || this.startMinDate;
  };

  @Input('max') endMaxDate;

  get formattedValue() {
    if (this.value && this.value[0] && this.value[1]) {
      switch (this.type) {
        case 'datetime':
          if (this.value[0].toDateString() == this.value[1].toDateString()) {
            return this.datePipe.transform(this.value[0], 'MMM d, y, h:mm a') + ' - ' + (this.value[1] ? this.datePipe.transform(this.value[1], 'h:mm a') : '')
          } else {
            return this.datePipe.transform(this.value[0], 'MMM d, y, h:mm a') + ' - ' + (this.value[1] ? this.datePipe.transform(this.value[1], 'MMM d, y, h:mm a') : '')
          }
        case 'date':
          return this.datePipe.transform(this.value[0]) + ' - ' + (this.value[1] ? this.datePipe.transform(this.value[1]) : '')
        case 'time':
          return this.datePipe.transform(this.value[0], 'h:mm a') + ' - ' + (this.value[1] ? this.datePipe.transform(this.value[1], 'h:mm a') : '')
        default :
          return this.value[0] + ' - ' + (this.value[1] || '')
      }
    } else return '';
  }
}
