import {Directive, HostListener} from '@angular/core';
import {BottomSheetService} from '../services/bottom-sheet.service';

@Directive({
  selector: '[wboBottomSheetClose]'
})
export class BottomSheetCloseDirective {
  constructor(private bottomSheetService: BottomSheetService) {
  }

  @HostListener('click')
  onClick() {
    this.bottomSheetService.close.next(true);
  }
}
