import {WabelModel} from './wabel-model.abstract';

export class MemberRejectionReason extends WabelModel {

  constructor(obj?: any) {
    super(obj);
    if (obj) {
      this.id = obj.id ? +obj.id : 0;
    }
  }

  id: number;
  internalReason: string;
  externalReason: string;
  priorityorder: number;
}
