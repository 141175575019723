<div class="container">
  <div class="card text-center">
    <div class="card-header">
      <h2>WABEL BO Inputs Sandbox</h2>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-6">
          <ng-container *ngIf="formElement == '<wbo-input>'">
            <wbo-input [color]="color" [(ngModel)]="inputValue" [type]='inputType' [appearance]="appearance" [label]="label" [placeholder]="placeholder" [hint]="hint" [required]="required" [saveAction]="saveAction" [clearAction]="clearAction" [historyAction]="historyAction"></wbo-input>
          </ng-container>
          <ng-container *ngIf="formElement == '<wbo-select>'">
            <wbo-select [color]="color" [(ngModel)]="inputValue" [label]="label" [appearance]="appearance" [placeholder]="placeholder" [hint]="hint" [required]="required" [saveAction]="saveAction" [clearAction]="clearAction" [historyAction]="historyAction" [multiple]="multiple">
              <wbo-option>Select an option</wbo-option>
              <wbo-option value="Option 1">Option 1</wbo-option>
              <wbo-option value="Option 2">Option 2</wbo-option>
              <wbo-option value="Option 3">Option 3</wbo-option>
            </wbo-select>
          </ng-container>
          <ng-container *ngIf="formElement == '<wbo-textarea>'">
            <wbo-textarea [color]="color" [(ngModel)]="inputValue" [label]="label" [appearance]="appearance" [placeholder]="placeholder" [hint]="hint" [required]="required" [saveAction]="saveAction" [clearAction]="clearAction" [historyAction]="historyAction">
            </wbo-textarea>
          </ng-container>
          <ng-container *ngIf="formElement == '<wbo-datetime-picker>'">
            <wbo-datetime-picker [color]="color" [type]="dateTimeType" [(ngModel)]="inputValue" [label]="label" [appearance]="appearance" [placeholder]="placeholder" [hint]="hint" [required]="required" [saveAction]="saveAction" [clearAction]="clearAction" [historyAction]="historyAction" [min]="minDate" [max]="maxDate">
            </wbo-datetime-picker>
          </ng-container>
          <ng-container *ngIf="formElement == '<wbo-datetime-range-picker>'">
            <wbo-datetime-range-picker [color]="color" [type]="dateTimeType" [(ngModel)]="inputValue" [label]="label" [appearance]="appearance" [placeholder]="placeholder" [hint]="hint" [required]="required" [saveAction]="saveAction" [clearAction]="clearAction" [historyAction]="historyAction" [min]="minDate" [max]="maxDate">
            </wbo-datetime-range-picker>
          </ng-container>
          <p class="lead"><strong>OUTPUT: </strong>{{ output }}</p>
        </div>
        <div class="code-demo col-6">
        </div>
      </div>
    </div>

    <!-- Card footer   -->
    <div class="card-footer text-muted">
      <div class="row">

        <!-- Tags -->
        <div class="options tag col-3 border-right">
          <h4>TAG</h4>
          <div class="row row-cols-1">
            <wbo-select class="col" #typeOption [label]='"Form Element"' [(ngModel)]="formElement"  appearance="outline">
              <wbo-option *ngFor="let formType of formElementTypes" [value]="formType"><code>{{formType}}</code></wbo-option>
            </wbo-select>
            <wbo-select class="col" [label]="'[appearance]=\''+appearance+'\''" [(ngModel)]="appearance" appearance="fill">
              <wbo-option *ngFor="let option of appearances" [value]="option">{{option}}</wbo-option>
            </wbo-select>
            <wbo-select class="col" [label]="'[color]=\''+color+'\''" [(ngModel)]="color" appearance="fill">
              <wbo-option [value]="null">null</wbo-option>
              <wbo-option *ngFor="let option of colors" [value]="option">{{option}}</wbo-option>
            </wbo-select>
          </div>
        </div>

        <!-- Attributes -->
        <div class="options attributes col">
          <h4>ATTRIBUTES</h4>
          <div class="row row-cols-1">
            <wbo-input class="col" [placeholder]="'Type a text as a plaholder.'" [label]="'[placeholder]=\''+placeholder+'\''" [(ngModel)]="placeholder" appearance="fill" clearAction="true"></wbo-input>
            <wbo-input class="col" [placeholder]="'Type a text as a label.'" [label]="'[label]=\''+label+'\''" [(ngModel)]="label" appearance="fill" clearAction="true"></wbo-input>
            <wbo-input class="col" [placeholder]="'Type a text as a hint .'" [label]="'[hint]=\''+hint+'\''" [(ngModel)]="hint" appearance="fill" clearAction="true"></wbo-input>
          </div>
        </div>

        <!-- Input attributes -->
        <ng-container *ngIf="formElement == '<wbo-input>'">
          <div class="options attributes col border-right">
            <h4><code>&lt;wbo-input&gt;</code></h4>
            <div class="row row-cols-1">
              <wbo-select class='col' [(ngModel)]="inputType" [label]="'[type]=\''+inputType+'\'' " [placeholder]="'Select an input attribute type to try.'" appearance="fill">
                <wbo-option *ngFor='let inputTypeValue of inputTypes' [value]="inputTypeValue">{{inputTypeValue}}</wbo-option>
              </wbo-select>
            </div>
          </div>
        </ng-container>

        <!-- Select attributes -->
        <ng-container *ngIf="formElement == '<wbo-select>'">
          <div class="options attributes col border-right">
            <h4><code>&lt;wbo-select&gt;</code> </h4>
            <div class="row row-cols-1">
              <mat-slide-toggle class='col' [(ngModel)]="multiple">[multiple]={{multiple}}</mat-slide-toggle>
            </div>
          </div>
        </ng-container>

        <!-- Datetime Picker attributes -->
        <ng-container *ngIf="formElement == '<wbo-datetime-picker>' || formElement == '<wbo-datetime-range-picker>'">
          <div class="options attributes col border-right">
            <h4><code>{{formElement}}</code> </h4>
            <div class="row row-cols-1">
              <wbo-select class='col' [(ngModel)]="dateTimeType" [label]="'[type]=\''+dateTimeType+'\'' " [placeholder]="'Select an input attribute type to try.'" appearance="fill">
                <wbo-option *ngFor='let dateTimeMode of dateTimeTypes' [value]="dateTimeMode">{{dateTimeMode}}</wbo-option>
              </wbo-select>
              <wbo-datetime-picker class='col' [(ngModel)]="minDate" [label]="'[min]=\''+(minDate | date: 'medium')+'\'' " [placeholder]="'Set a min date limiter'" appearance="fill" [max]="inputValue && inputValue[0] ? inputValue[0] : inputValue"></wbo-datetime-picker>
              <wbo-datetime-picker class='col' [(ngModel)]="maxDate" [label]="'[max]=\''+(maxDate | date: 'medium')+'\'' " [placeholder]="'Set a max date limiter'" appearance="fill" [min]="inputValue && inputValue[1] ? inputValue[1] : inputValue"></wbo-datetime-picker>
            </div>
          </div>
        </ng-container>

        <!-- Controls -->
        <div class="options controls col-2">
          <h4>CONTROLS</h4>
          <div class="row">
            <mat-slide-toggle class="col" [(ngModel)]="required">Required</mat-slide-toggle>
            <mat-slide-toggle class="col" [(ngModel)]="saveAction">Save mode</mat-slide-toggle>
            <mat-slide-toggle class="col" [(ngModel)]="historyAction">Cancel mode</mat-slide-toggle>
            <mat-slide-toggle class="col" [(ngModel)]="clearAction">Clear mode</mat-slide-toggle>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
