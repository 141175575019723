import {WabelModel} from './wabel-model.abstract';
import {Member} from './member';

export class WebRTCResult extends WabelModel {

  constructor(obj?: any) {
    super(obj);
    if (obj) {
      this.id = obj.id ? +obj.id : 0;
      this.member = obj.member ? new Member(obj.member) : null;
    }
  }

  id: number;
  member: Member;
  score: number;
  logs: string;
  dateCreated: string;
  downloadLogsLink: string;
  isFinished: boolean;
  errors: string;
}
