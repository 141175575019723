import {WabelModel} from './wabel-model.abstract';
import * as moment from "moment";

export class MeetingTimeRange extends WabelModel {

  constructor(obj?: any) {
    super(obj);
    if (obj) {
      this.startDateStr = obj.startDate ? obj.startDate : null;
      this.endDateStr = obj.endDate ? obj.endDate : null;
      this.startDate = obj.startDate ? new Date(obj.startDate) : null;
      this.endDate = obj.endDate ? new Date(obj.endDate) : null;
    }
  }

  startDate: Date;
  endDate: Date;
  startDateStr: string;
  endDateStr: string;

  private startEventTZ: Date;
  private endEventTZ: Date;

  get id() {
    return null;
  }

  startDateEvent(timezone: string): Date {
    if (this.startEventTZ) {
      return this.startEventTZ;
    }
    let computerTimeZone = moment.tz.guess();
    let dateString = this.startDateStr.split('+')[0];
    const eventDateTz = moment.tz(dateString, timezone);
    this.startEventTZ = moment.tz(eventDateTz.format('YYYY-MM-DDTHH:mm:ss'), computerTimeZone).toDate();
    return this.startEventTZ;
  }

  endDateEvent(timezone: string) {
    if (this.endEventTZ) {
      return this.endEventTZ;
    }
    let computerTimeZone = moment.tz.guess();
    let dateString = this.endDateStr.split('+')[0];
    const eventDateTz = moment.tz(dateString, timezone);
    this.endEventTZ = moment.tz(eventDateTz.format('YYYY-MM-DDTHH:mm:ss'), computerTimeZone).toDate();
    return this.endEventTZ;
  }
}

export const VIDEO_CALL_QUALITY_MEETING_RATING_INTERNAL_NAME = 'video_call_quality';
export const WANT_TO_FOLLOW_UP_MEETING_RATING_INTERNAL_NAME = 'want_to_follow_up';
export const PRODUCT_MATCH_CURRENT_NEEDS_MEETING_RATING_INTERNAL_NAME = 'products_match_current_needs';
export const PRODUCTS_ARE_APPEALING_MEETING_RATING_INTERNAL_NAME = 'products_are_appealing';
export const BUYER_HAD_TANGIBLE_PROJECT_MEETING_RATING_INTERNAL_NAME = 'buyer_had_tangible_purchasing_projects_for_my_category';
export const BUYER_TYPE_MATCH_MEETING_RATING_INTERNAL_NAME = 'buyer_type_match_target_distribution_channels';
export const TIMELINE_TO_FOLLOW_UP_INTERNAL_NAME = 'timeline_to_follow_up';
export const BUYER_SEEMS_INTERESTED_BY_MY_PRODUCTS_INTERNAL_NAME = 'buyer_seems_interested_by_my_products';
