<div class="content">
  <ng-container *ngTemplateOutlet="data.content; context:{$implicit: data.context}"></ng-container>
</div>

<div class="actions">
  <button mat-button *ngIf="data.moreDetails" [wboModal]="moreDetails" [wboContext]="data.context">{{data.moreDetailsText}}</button>
  <button mat-button (click)="alertService.closeSnackbar()">{{data.closeText}}</button>

  <ng-template #moreDetails>
    <p [innerHTML]="data.moreDetails"></p>
  </ng-template>
</div>
