import {WabelModel} from './wabel-model.abstract';
import {EventOrganizer} from './event_organizer';

export class SubscriptionPack extends WabelModel {
  constructor(obj?: any) {
    super(obj);
    if (obj) {
      this.idsubscriptionpack = obj.idsubscriptionpack ? +obj.idsubscriptionpack : 0;
      this.eventOrganizer = obj.eventOrganizer ? new EventOrganizer(obj.eventOrganizer) : null;
    }
  }

  idsubscriptionpack: number;
  label: string;
  eventOrganizer: EventOrganizer;
  duration: number;
  durationUnit: string;
  monthlyProductRequestsNumber: number;
  monthlyCategoryBenchmarksNumber: number;
  monthlyCompanyProfileSubmissionsNumber: number;

  get id(): number {
    return this.idsubscriptionpack;
  }
}
