import {Directive, HostListener, Input, TemplateRef} from '@angular/core';
import {MatBottomSheet, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {BottomSheetComponent} from '../components/bottom-sheet/bottom-sheet.component';
import {BottomSheetService} from '../services/bottom-sheet.service';

@Directive({
  selector: 'button[wboBottomSheet],a[wboBottomSheet]:not([routerLink])'
})
export class BottomSheetDirective {

  @Input('wboBottomSheet') content: TemplateRef<any>
  @Input('wboContext') context: any
  bottomSheetRef: MatBottomSheetRef<BottomSheetComponent>

  @HostListener('click')
  onClick() {
    this.bottomSheetRef = this.bottomSheet.open(BottomSheetComponent, {
      data: {
        content: this.content,
        context: this.context,
      }
    });
  }

  constructor(private bottomSheetService: BottomSheetService, private bottomSheet: MatBottomSheet) {
    bottomSheetService.close$.subscribe(close => {
      if (this.bottomSheetRef && close) {
        this.bottomSheetRef.dismiss()
      }
    });
  }

}
