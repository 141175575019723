export abstract class WabelModel {

  // tslint:disable-next-line:variable-name
  __typename: string;

  constructor(object?: any) {
    this.copy(object || {});
  }

  copy(object: any) {
    // tslint:disable-next-line:forin
    for (const prop in object) {
      try {
        this[prop] = object[prop];
      } catch (e) {
        console.error(e);
      }
    }
  }

  abstract get id();

  protected formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
}
