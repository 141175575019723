import {WabelModel} from './wabel-model.abstract';
import {Registration} from './registration';

export class Video extends WabelModel {
  constructor(obj?: any) {
    super(obj);
    if (obj) {
      this.idvideo = obj.idvideo ? +obj.idvideo : 0;
      this.registrations = obj.registrations ? obj.registrations.map(registration => new Registration(registration)) : [];
      if (this.registrations) {
        this.idRegistrations = this.registrations.map((registration) => {
          return registration.idcertaineventregistration;
        });
      }
    }
  }

  idvideo: number;
  hostId: string;
  description: string;
  dateCreated: string;
  dateUpdated: string;
  registrations: Registration[];
  idRegistrations: number[];

  get id() {
    return this.idvideo;
  }
}
