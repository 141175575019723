import {WabelModel} from './wabel-model.abstract';
import {EventExpert} from './event_expert';
import {Video} from './video';
import {Member} from '@models/member';

export class EventWorkshop extends WabelModel {
  constructor(obj?: any) {
    super(obj);
    if (obj) {
      this.ideventworkshop = obj.ideventworkshop ? +obj.ideventworkshop : 0;
      this.expert = obj.expert ? new EventExpert(obj.expert) : null;
      this.startDate = obj.startDate ? new Date(obj.startDate) : null;
      this.endDate = obj.startDate ? new Date(obj.endDate) : null;
      this.video = ((obj.video) ? new Video(obj.video) : new Video());
      this.member = obj.member ? new Member(obj.member) : new Member({});
    }
  }

  ideventworkshop: number;
  expert: EventExpert;
  description: string;
  startDate: Date;
  endDate: Date;
  title: string;
  optInOption: boolean;
  optInLabelYes: string;
  optInLabelNo: string;
  isExpertLab: boolean;
  video: Video;
  member: Member;
  conferenceType: string;
  idZoomWebinar: string;
  externalUrl: string;
  conferenceUrl: string;

  get id(): number {
    return this.ideventworkshop;
  }

  get formattedStartDate() {
    return new Date(this.startDate).toLocaleDateString();
  }
}
