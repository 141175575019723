<mat-sidenav-container>
  <!-- Side panel -->
  <mat-sidenav #leftPanel [fixedInViewport]="true" (closed)="sidePanelService.close()" [mode]="sidePanelService.mode" [position]="sidePanelService.position" [opened]="sidePanelService.opened" [style.width]="sidePanelService.width || 'auto'">
    <div class="wbo-sidepanel-content">
      <button mat-icon-button class="wbo-close-button" wboSidePanelClose><mat-icon>close</mat-icon></button>
      <ng-container *ngTemplateOutlet="sidePanelService.content; context: {$implicit: sidePanelService.context}"></ng-container>
    </div>
  </mat-sidenav>

  <!-- Main content -->
  <mat-sidenav-content>
    <!-- header -->
    <header class="sticky" id="wbo-layout-header">
      <!-- Progress bar -->
      <div class="request-loading-bar">
        <mat-progress-bar [mode]="(isRequestLoading | async) ? 'indeterminate' : 'determinate'" value="0" color="accent"></mat-progress-bar>
      </div>

      <!-- User Header -->
      <ng-content select="wbo-header"></ng-content>

      <!-- User Top Bar -->
      <mat-toolbar color="accent">
        <mat-toolbar-row *ngFor="let topbarContent of topBarService.content$ | async" class="wbo-top-bar">
          <ng-container [ngTemplateOutlet]="topbarContent.content"></ng-container>
        </mat-toolbar-row>
      </mat-toolbar>

    </header>

    <ng-content></ng-content>
  </mat-sidenav-content>

</mat-sidenav-container>

<!-- Footer -->
<ng-content select="wbo-footer"></ng-content>
