import {Component, Input, TemplateRef, ViewChild} from '@angular/core';

@Component({
  selector: 'wbo-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss']
})
export class PageComponent {
  solo = true;
  @Input() title: string;
  @ViewChild(TemplateRef, {static: true}) public content: TemplateRef<any>
}
