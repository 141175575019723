import {Component} from '@angular/core';
import {InfoService} from '@services/info-service';
import {animate, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-footer',
  templateUrl: 'footer.component.html',
  styleUrls: ['footer.component.scss'],
  animations: [
    trigger('loaded', [
      transition(':enter', [
        style({transform: 'translateY(100%)'}),
        animate('300ms ease-in', style({transform: 'translateY(0)'}))
      ]),
      transition(':enter', [
        style({transform: 'translateY(0)'}),
        animate('300ms ease-out', style({transform: 'translateY(100%)'}))
      ])
    ])
  ]
})
export class FooterComponent {


  constructor(
    public infoEngine: InfoService
  ) {
  }

  get text(): string {
    return this.infoEngine.text;
  }

  get hiddenOnTablet(): boolean {
    return this.infoEngine.hiddenOnTablet;
  }
}
