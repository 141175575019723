import {WabelModel} from './wabel-model.abstract';
import {File} from './file';

export class EventFile extends WabelModel {

  constructor(obj: any = null) {
    super(obj);
    if (obj) {
      this.file = new File(obj.file);
    }
  }

  file: File;
  visibleToSelectors: boolean;
  visibleToSelected: boolean;
  visibleToOthers: boolean;
  url: string;
  downloadLink: string;

  get id() {
    return this.file.id;
  }
}
