import {
  AfterContentInit,
  AfterViewInit,
  Component, ContentChild,
  ContentChildren, EventEmitter,
  Input, OnDestroy,
  OnInit, Output,
  ViewChild,
} from '@angular/core';
import {SelectionModel} from "@angular/cdk/collections";
import {Column} from '../../directives/column.directive';
import {TableAction} from '../../directives/table-action.directive';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort, SortDirection} from '@angular/material/sort';
import {NgForm} from '@angular/forms';
import {Subscription} from 'rxjs';
import {Filters} from '../../directives/filters.directive';
import {FilterCallback} from '../../directives/filter-callback.directive';
import {debounceTime, filter} from 'rxjs/operators';
import {TableDataSource, TableDataSourceFilterMessage} from '../../classes/table-data-source';
import {TableDataRequest} from '../../../types';
import {TableBulkActions} from '../../directives/table-bulk-actions.directive';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {AlertService} from '../../../dialog/services/alert.service';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {LoadingService} from '../../../layout/services/loading.service';

@Component({
  selector: 'wbo-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  exportAs: 'wboTable'
})
export class TableComponent<T> implements OnInit, OnDestroy, AfterContentInit, AfterViewInit {

  @Input() sticky = true;
  @Input() selected = [];
  @Input() inModal = false;
  initialSortBy: string;
  initialSortDirection: SortDirection = 'asc';

  showHeader = false;
  firstLoad = true;

  dataSource: TableDataSource<T> = new TableDataSource<T>();

  @ContentChildren(Column) private _columns;
  columns: Column[];

  @ContentChildren(TableAction) private _actions;
  actions: TableAction[];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  private subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private loadingService: LoadingService
  ) {
  }

  @Input() limitOptions = [5, 10, 20, 50, 100];
  @Input() checkForSelected = false;


  @Input() set data(data: T[]) {
    this.dataSource.data = data;
  }

  get data(): T[] {
    return this.dataSource.data;
  }

  @Input() set request(request: TableDataRequest<T>) {
    this.dataSource.request = request;
  }

  get request(): TableDataRequest<T> {
    return this.dataSource.request;
  }

  get loading() {
    return this.dataSource.loading;
  }

  private _paginate: boolean;
  get paginate(): boolean {
    return this._paginate;
  }

  @Input()
  set paginate(value: boolean) {
    this._paginate = coerceBooleanProperty(value);
  }

  private _urlFiltering: boolean;
  get urlFiltering(): boolean {
    return this._urlFiltering;
  }

  @Input()
  set urlFiltering(value: boolean) {
    this._urlFiltering = coerceBooleanProperty(value);
  }

  @Input() limit: number;

  set offset(offset) {
    if (this.paginator) {
      this.paginator.pageIndex = offset / this.limit;
    }
  }

  get offset() {
    return this.paginator ? this.paginator.pageSize * this.paginator.pageIndex : null;
  }

  private _orderBy: string;
  @Input() set orderBy(orderBy) {
    this._orderBy = orderBy;
    this.processOrderBy(orderBy)
  }

  get orderBy() {
    return this.sort && this.sort.active && this.sort.direction ? this.sort.active + ' ' + this.sort.direction : this._orderBy;
  }

  private processOrderBy(sortString: string) {
    if (this.sort && sortString) {
      const o = sortString.split(" ");
      this.initialSortBy = o[0];
      this.initialSortDirection = o[1].includes("desc") ? "desc" : "asc";
      this.sort.active = this.initialSortBy;
      this.sort.direction = this.initialSortDirection;
    }
  }

  selection: SelectionModel<any>;
  @Input() selectionMode: boolean;

  @Input() initialSelection = [];

  filterSubscription: Subscription;
  @Output('filtersChange') filterEvent = new EventEmitter();
  @ContentChild(Filters, {static: false}) public filters: Filters;
  @ContentChild(NgForm, {static: false}) public form: NgForm;
  @ContentChild(TableBulkActions, {static: false}) private bulkActions;

  filterCallbacks: FilterCallback[];

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected == numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.data.forEach((row: any) => this.selection.select(row.id));
  }

  displayedColumns = [];

  load(params?: any, orderId?: string, orderDirection?: string) {
    if (this.checkForSelected && !this.urlFiltering && !this.firstLoad) {
      if (this.selection.selected.length) {
        if (window.confirm('Your selection will be cancelled if you chose to apply filters !')) {
          this.selection.clear();
        } else {
          return;
        }
      }
    }
    const filterMessage = this.getFilterMessage(params);
    this.dataSource.filter(filterMessage);
    this.lastFilterMessage = filterMessage;
    this.firstLoad = false;
  }

  private _lastFilterMessage: TableDataSourceFilterMessage;
  get lastFilterMessage(): TableDataSourceFilterMessage {
    if (!this._lastFilterMessage) {
      this._lastFilterMessage = {
        limit: this.limit,
        offset: this.offset,
        orderBy: this.orderBy,
        filterBag: {}
      };
    }
    return this._lastFilterMessage;
  }

  set lastFilterMessage(filterMessage: TableDataSourceFilterMessage) {
    this._lastFilterMessage = filterMessage;
  }

  get filterBagLength(): number {
    return Object.keys(this.lastFilterMessage.filterBag).length;
  }

  private getFilterMessage(params?: any, orderId?: string, orderDirection?: string): TableDataSourceFilterMessage {
    return {
      limit: this.limit,
      offset: this.offset,
      orderBy: (orderId && orderDirection) ? orderId + " " + orderDirection : this.orderBy,
      filterBag: params || this.lastFilterMessage.filterBag
    };
  }

  private compareFilters(newFilters): boolean {
    const filters = this.trimParams(newFilters);
    const oldFilters = this.lastFilterMessage.filterBag;
    const keys1 = Object.keys(filters);
    const keys2 = Object.keys(oldFilters);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (let filterkey in filters) {
      if (!keys2.includes(filterkey) || filters[filterkey] !== oldFilters[filterkey]) {
        return false;
      }
    }

    return true;
  }

  private convertUrlToForm(params: Params): any {
    let convertedParams = {};
    for (let key in params) {
      if (params[key].includes('array:')) {
        const arraystring = params[key].substring(6);
        convertedParams[key] = arraystring.split('|').map(this.datetifyStringParam);
      } else {
        convertedParams[key] = this.datetifyStringParam(params[key]);
      }
    }
    return convertedParams;
  }

  private convertFormToUrl(params: any): any {
    let convertedParams = {};
    for (let key in params) {
      if (Array.isArray(params[key])) {
        convertedParams[key] = 'array:' + params[key].map(this.stringifyDateParam).join('|').toString();
      } else if (params[key]) {
        convertedParams[key] = this.stringifyDateParam(params[key]);
      }
    }
    return convertedParams;
  }

  private trimParams(params: any): any {
    let convertedParams = {};
    for (let key in params) {
      if (params[key]) {
        convertedParams[key] = params[key];
      }
    }
    return convertedParams;
  }

  private datetifyStringParam(param: string) {
    if (param.includes('datetime:')) {
      const datestring = param.substring(9);
      return new Date(datestring);
    }
    return param;
  }

  private stringifyDateParam(param: Date | string) {
    if (param instanceof Date) {
      return 'datetime:' + param.toString();
    }
    return param;
  }

  private patchFormValues(data: any, triggerLoading = true) {
    setTimeout(() => this.form.control.patchValue(data, {emitEvent: triggerLoading}));
  }

  isFormEmpty(formData: any) {
    for (let key in formData) {
      if (formData[key]) {
        return false;
      }
    }
    return true;
  }

  ngOnInit() {
    this.selection = new SelectionModel<T>(true, this.initialSelection);

    this.subscriptions.push(
      this.dataSource.endSubscription.asObservable().subscribe(params => {
        this.loadingService.endRequestLoading();
      })
    );
  }

  ngAfterContentInit(): void {
    this.columns = this._columns.toArray();

    this.displayedColumns = this.columns.map((field, index) => {
      if (field.name) {
        this.showHeader = true;
      }
      return field.sortColumn || field.name || 'Column' + (index + 1);
    });

    if (this.selectionMode) {
      this.displayedColumns.unshift('wbo-column-selection');
    }
  }

  ngAfterViewInit(): void {
    if (this.filters) {
      this.dataSource.filterCallbacks = this.filters.filterCallbacks.toArray();

      this.subscriptions.push(
        // TODO: The filtering is made to skip the first request when urlFiltering is on, in order to prevent resetting the filters. Need to look into it to fix this behaviour
        this.form.valueChanges.pipe(debounceTime(500), filter((data, index) => this.urlFiltering ? index > 0 : true)).subscribe(formValues => {
          this.offset = 0;
          if (this.urlFiltering) {
            if (!this.compareFilters(formValues)) {
              let urlValues = this.convertFormToUrl(formValues);
              if (this.checkForSelected && this.selection.selected.length) {
                if (window.confirm('Your selection will be cancelled if you chose to apply filters !')) {
                  this.selection.clear();
                } else {
                  urlValues = this.convertFormToUrl(this.lastFilterMessage.filterBag);
                  this.form.control.reset(this.lastFilterMessage.filterBag);
                }
              }
              this.router.navigate(['./', urlValues], {
                relativeTo: this.route,
                replaceUrl: true
              })
            }
          } else {
            this.load(formValues)
          }
        })
      );

      if (this.paginator) {
        this.subscriptions.push(
          this.paginator.page.pipe(debounceTime(500)).subscribe(pageEvent => {
            this.limit = pageEvent.pageSize;
            this.load()
          })
        );
      }

      if (this.sort) {
        this.subscriptions.push(
          this.sort.sortChange.pipe(debounceTime(500)).subscribe((value) => this.load(null, value.active, value.direction))
        );
      }

      if (this.urlFiltering) {
        const formValues = this.convertUrlToForm(this.route.snapshot.params);
        this.patchFormValues(formValues, false);
        this.subscriptions.push(
          this.route.params.subscribe(params => {
            const formValues = this.convertUrlToForm(this.route.snapshot.params);
            this.load(formValues);
          })
        );
      } else {
        this.load();
      }
    } else if (this.request) {
      this.load();
    }
  }

  add(entity: T) {
    let data = this.data;
    data.push(entity);
    this.data = data;
  }

  update(entity: T, accessor: string = 'id') {
    let data = this.data;
    const index = this.getRowIndex(entity, accessor)
    if (index >= 0) {
      data[index] = entity
      this.data = data;
    } else {
      this.alertService.sendAlert("No table row matches the updated entity. Please verify the provided accessor \"" + accessor + "\".");
    }
  }

  patch(entity: T, accessor: string = 'id') {
    let data = this.data;
    const index = this.getRowIndex(entity, accessor)
    if (index >= 0) {
      let copiedEntity = data[index];
      for (let key in entity) {
        copiedEntity[key] = entity[key];
      }
      data[index] = copiedEntity;
      this.data = data;
    } else {
      this.alertService.sendAlert("No table row matches the patched entity. Please verify the provided accessor \"" + accessor + "\".");
    }
  }

  delete(entity: T, accessor: string = 'id') {
    let data = this.data;
    const index = this.data.findIndex(row => row[accessor] === entity[accessor]);
    if (index >= 0) {
      data.splice(index, 1);
      this.data = data;
    } else {
      this.alertService.sendAlert('No table row matches the updated entity. Please verify the provided accessor "' + accessor + '".');
    }
  }

  setFilters(data: any) {
    this.patchFormValues(data);
  }

  private getRowIndex(entity: T, accessor: string): number {
    return this.data.findIndex(row => row[accessor] === entity[accessor]);
  }

  ngOnDestroy() {
    this.dataSource.endSubscription.next();
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }
}
