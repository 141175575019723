import {Component, OnInit} from '@angular/core';
import {slideEnterLeaveAnimation} from '../../animations/slide-enter-leave.animation';
import {HttpClient} from '@angular/common/http';
import {DomSanitizer, Title} from '@angular/platform-browser';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  animations: [slideEnterLeaveAnimation],
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  htmlContent: any;

  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private titleService: Title
  ) {
  }

  ngOnInit() {
    this.titleService.setTitle('needl BO');
    this.http.get(environment.needl_back_end_url + '/api/v2/utils/homepage-bo', {
      withCredentials: true
    }).subscribe(
      (data: string) => {
        if (!Object.keys(data).length) {
          this.htmlContent = this.sanitizer.bypassSecurityTrustHtml('<p>Content not found</p>');
        } else {
          this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(data);
        }
      }, (error) => {
        this.htmlContent = error.message;
      }
    );
  }
}
