<div>
  <button mat-icon-button mat-dialog-close class="wbo-close-button">
    <mat-icon>close</mat-icon>
  </button>

  <h1 *ngIf="data.title" mat-dialog-title>
    {{data.title}}
  </h1>

  <div mat-dialog-content>
    <ng-container *ngTemplateOutlet="data.content; context:{$implicit: data.context}"></ng-container>
  </div>
</div>
