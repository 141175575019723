import {Directive, ElementRef, Input, HostListener} from '@angular/core';
import {InfoService} from '@services/info-service';

@Directive({selector: '[wabelInfo]'})
export class InfoDirective {

  @Input('wabelInfo') infoText: string;

  constructor(
    private el: ElementRef,
    private infoEngine: InfoService
  ) {
  }

  @HostListener('mouseenter', ['$event']) onMouseEnter(event: MouseEvent) {
    this.infoEngine.top = (event && event.clientY >= window.innerHeight - 70);
    this.infoEngine.text = this.infoText;
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.infoEngine.text = '';
  }

  private highlight(color: string) {
    this.el.nativeElement.style.backgroundColor = color;
  }
}
