import {WabelModel} from './wabel-model.abstract';
import {Company} from './company';
import {SourcingRequest} from './sourcing_request';

export class SourcingRequestBlacklistedCompany extends WabelModel {
  constructor(obj: any) {
    super(obj);
    if (obj) {
      this.id = obj.id ? +obj.id : 0;
      this.company = obj.company ? new Company(obj.company) : null;
      this.sourcingRequest = obj.sourcingRequest ? new SourcingRequest(obj.sourcingRequest) : null;
    }
  }

  id: number;
  name: string;
  company: Company;
  sourcingRequest: SourcingRequest;
}
