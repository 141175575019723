import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BottomSheetService {
  close = new BehaviorSubject<boolean>(false)

  get close$(): Observable<boolean> {
    return this.close.asObservable();
  }
}
