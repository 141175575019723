import {
  AfterViewInit,
  ContentChildren,
  Directive, ElementRef,
  Input, OnDestroy, OnInit,
  QueryList,
} from '@angular/core';
import {NgForm, NgModel} from '@angular/forms';
import {FilterCallback} from './filter-callback.directive';
import {Subscription} from 'rxjs';
import {coerceNumberProperty} from '@angular/cdk/coercion';
import {ActivatedRoute} from "@angular/router";

@Directive({
  selector: 'form[wboFilters]',
})
export class Filters implements OnInit, AfterViewInit, OnDestroy {

  private routeSubscription$: Subscription;
  @ContentChildren(FilterCallback, {descendants: true}) filterCallbacks: QueryList<FilterCallback>;
  @ContentChildren(NgModel, {descendants: true}) inputs: QueryList<NgModel>;

  private _displayAll = false;
  get displayAll(): boolean {
    return this._displayAll;
  }

  private _displayMoreFiltersDiv = false;
  get displayMoreFiltersDiv(): boolean {
    return this._displayMoreFiltersDiv;
  }

  private _sticky: number;

  get stickyFilters(): number {
    return this._sticky;
  }

  @Input('wboFiltersSticky')
  set stickyFilters(value: number) {
    this._sticky = coerceNumberProperty(value);
  }

  constructor(
    private el: ElementRef,
    private _form: ElementRef<NgForm>,
    private activatedRoute: ActivatedRoute
  ) {
  }

  get form() {
    return this._form ? this._form.nativeElement : null;
  }

  ngOnInit() {
    this.routeSubscription$ = this.activatedRoute.queryParamMap.subscribe(queryParams => {
      if (queryParams.has('hideHeader') && (queryParams.get('hideHeader') === 'true' || queryParams.get('hideHeader') === '1')) {
        this._sticky = 53;
      }
      this.setSticky(this._sticky);
    });
  }

  ngAfterViewInit() {
    if (this.el.nativeElement.offsetHeight > 70) {
      this._displayMoreFiltersDiv = true;
      this.el.nativeElement.style.height = '60px';
      this.el.nativeElement.style.overflow = 'hidden';
    }
  }

  ngOnDestroy() {
    if (this.routeSubscription$) {
      this.routeSubscription$.unsubscribe();
    }
  }

  setSticky(sticky: number) {
    if (sticky !== undefined) {
      this.el.nativeElement.style.position = 'sticky';
      this.el.nativeElement.style.top = sticky ? sticky + 'px' : '68px';
      this.el.nativeElement.style.zIndex = 1;
    } else {
      this.el.nativeElement.style.position = 'relative';
      this.el.nativeElement.style.top = '0';
    }
  }

  get filterHeight() {
    return this.el.nativeElement.offsetHeight;
  }

  get filterTop() {
    return this.el.nativeElement.classList.contains('tableFiltersRemovePadding') ? 0 : this.el.nativeElement.style.top;
  }

  switchDisplayAllFilters() {
    if (this.displayAll) {
      this._displayAll = false;
      this.el.nativeElement.style.height = '60px';
      this.el.nativeElement.style.overflow = 'hidden';
    } else {
      this._displayAll = true;
      this.el.nativeElement.style.height = '';
      this.el.nativeElement.style.overflow = 'auto';
    }
  }
}
