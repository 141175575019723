<mat-form-field #formField  class="wabel-bo-input" [color]="color" [appearance]="appearance" [class.filled]="appearance === 'fill'" [class.no-hint]="!hint" [class.no-label]="!label" [class.has-errors]="invalid && touched">
  <mat-label *ngIf="label">{{label}}</mat-label>

  <ng-container matPrefix>
    <mat-icon *ngIf="icon">{{icon}}</mat-icon>
  </ng-container>

  <input #input [required]="required" readonly matInput [placeholder]="placeholder" [ngModel]="formattedValue" />

  <input type="hidden" #startInput [matDatetimepicker]="startDatePicker" [min]="startMinDate" [max]="startMaxDate" [(ngModel)]="startValue" (ngModelChange)="endDatePicker.open()"/>
  <mat-datetimepicker #startDatePicker [type]="type" mode="portrait" touchUi [timeInterval]="timeInterval" [disabled]="disabled"></mat-datetimepicker>
  <input type="hidden" #endInput [matDatetimepicker]="endDatePicker" [min]="endMinDate" [max]="endMaxDate" [(ngModel)]="endValue"/>
  <mat-datetimepicker #endDatePicker [type]="type" mode="portrait" touchUi [timeInterval]="timeInterval" [disabled]="disabled"></mat-datetimepicker>

  <wbo-wrapper-suffix matSuffix [touched]="touched" [invalid]="invalid" [unchanged]="unchanged" [required]="required" [dirty]="dirty" [empty]="empty" [saveAction]="saveAction" [clearAction]="clearAction" [historyAction]="historyAction" [copyAction]="copyAction" (copyEvent)="copy()" (cancelEvent)="cancel()" (validateEvent)="validate()" (clearEvent)="clear()" (click)="$event.stopPropagation()">
    <ng-content select="[wboSuffix]"></ng-content>
    <button mat-icon-button [color]="color" class="wbo-action-button select-date" [disabled]="disabled" (mousedown)="startDatePicker.open(); " ><mat-icon>{{ type === 'time' ? 'timer' : 'today'}}</mat-icon></button>
    <button mat-icon-button [color]="color" class="wbo-action-button select-date" (mousedown)="endDatePicker.open()" [disabled]="!endValue || disabled"><mat-icon>{{ type === 'time' ? 'alarm' : 'event'}}</mat-icon></button>
  </wbo-wrapper-suffix>

  <mat-error *ngIf="ngModel?.errors?.required">You must enter a start and end value.</mat-error>
  <mat-error *ngIf="startValue && !endValue">You must enter an end value.</mat-error>
  <mat-error *ngIf="!startValue && endValue">You must enter a start value.</mat-error>
  <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
</mat-form-field>
