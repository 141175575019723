import {Company} from './company';
import {WabelModel} from './wabel-model.abstract';
import {BannerStore} from './banner_store';

export class Banner extends WabelModel {
  id: number;
  name: string;
  company: Company;
  totalStores: number;
  totalCountries: number;
  bannerStores: BannerStore[];

  constructor(obj: any) {
    super(obj);
    if (obj) {
      this.id = obj.id ? +obj.id : 0;
      this.company = obj.company ? new Company(obj.company) : null;
      this.bannerStores = obj.bannerStores && obj.bannerStores.items ? obj.bannerStores.items.map((b) => new BannerStore(b)) : [];
    }
  }
}
