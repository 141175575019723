import {WabelModel} from './wabel-model.abstract';
import {SourcingNeedRecommendation} from './sourcing_need_recommendation';
import {Member} from './member';
import {PrivateMessage} from './private_message';

export class SourcingNeedRecommendationMemberResponse extends WabelModel {
  constructor(obj: any) {
    super(obj);

    if (obj) {
      this.sourcingNeedRecommendation = obj.sourcingNeedRecommendation ? new SourcingNeedRecommendation(obj.sourcingNeedRecommendation) : null;
      this.member = obj.member ? new Member(obj.member) : null;
      this.privateMessage = obj.privateMessage ? new PrivateMessage(obj.privateMessage) : null;
    }
  }

  sourcingNeedRecommendation: SourcingNeedRecommendation;
  member: Member;
  status: string;
  reason: string;
  privateMessage: PrivateMessage;

  get id() {
    return this.sourcingNeedRecommendation.id;
  }
}
