import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output
} from '@angular/core';
import {NG_VALUE_ACCESSOR, NgModel} from '@angular/forms';
import {InputWithOptions} from '../input-with-options-abstract';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {ClipboardService} from 'ngx-clipboard';
import {OptionComponent} from "./option/option.component";

@Component({
  selector: 'wbo-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss', '../input.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true
    }
  ],
})
export class SelectComponent extends InputWithOptions {
  constructor(clipboardService: ClipboardService) {
    super(clipboardService);
  }

  @Output() searchValueChanged = new EventEmitter();
  private _multiple: boolean;
  get multiple(): boolean {
    return this._multiple;
  }

  @Input()
  set multiple(value: boolean) {
    this._multiple = coerceBooleanProperty(value);
  }

  selectAll(inputSelect: NgModel, options: OptionComponent[]) {
    inputSelect.update.emit(options.map(option => option.value));
  }

  deselectAll(inputSelect: NgModel) {
    inputSelect.update.emit();
  }
}
