<div class="wbo-table-wrapper">

  <ng-content select="form"></ng-content>
  <div class="more-filters" *ngIf="filters && filters.displayMoreFiltersDiv"
       [ngStyle]="{'top': 'calc(' + filters.filterTop + ' + ' + filters.filterHeight + 'px)'}"
       (click)="filters.switchDisplayAllFilters()"
       title="Show {{(filters.displayAll ? 'less' : 'more')}} filters">
    <span><mat-icon>{{(filters.displayAll ? 'keyboard_arrow_up' : 'keyboard_arrow_down')}}</mat-icon>
      <span *ngIf="filterBagLength"> {{filterBagLength}} active filter{{filterBagLength > 1 ? 's' : ''}}</span>
    </span>
  </div>

  <table mat-table [dataSource]="dataSource" class="wbo-table" matSort matSortDisableClear>
    <!-- Selection Columns -->
    <!-- TODO: Selection is made over the 'id' property of the object in order to successfully track selection on pagination change. However, the 'id' property isn't always available from the datasource objects. This might cause application to crash. -->
    <ng-container matColumnDef="wbo-column-selection">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? masterToggle() : null"
                      [checked]="selection.hasValue() && isAllSelected()"
                      [indeterminate]="selection.hasValue() && !isAllSelected()"
                      class="wbo-table-selection-checkbox">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()"
                      (change)="$event ? selection.toggle(row.id) : null"
                      [checked]="selection.isSelected(row.id)"
                      class="wbo-table-selection-checkbox">
        </mat-checkbox>
      </td>
    </ng-container>

    <!--- Columns-->
    <ng-container *ngFor="let field of columns; let i = index"
                  [matColumnDef]="field.sortColumn || field.name || 'Column'+(i+1)">
        <ng-container *ngIf="field.sortColumn">
          <th mat-header-cell *matHeaderCellDef mat-sort-header title="{{(field.title ? field.title : '')}}" [class.wboHide]="!field.displayed">
            <ng-container [ngTemplateOutlet]="field.headerColumn || columnName"></ng-container>
          </th>
        </ng-container>

        <ng-container *ngIf="!field.sortColumn">
          <th mat-header-cell *matHeaderCellDef title="{{(field.title ? field.title : '')}}" [class.wboHide]="!field.displayed">
            <ng-container [ngTemplateOutlet]="field.headerColumn || columnName"></ng-container>
          </th>
        </ng-container>

        <td mat-cell *matCellDef="let element" [class.wboHide]="!field.displayed">
          <ng-container *ngTemplateOutlet="field.content; context: {$implicit: element}"></ng-container>
        </td>

        <ng-template #columnName>
          {{ field.name }}
        </ng-template>
    </ng-container>

    <ng-container *ngIf="showHeader">
      <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
    </ng-container>

    <tr mat-row *matRowDef="let myRowData; columns: displayedColumns"></tr>
  </table>

  <mat-paginator color="accent" [style.position]="sticky ? 'sticky' : 'relative'" [style.bottom]="0" *ngIf="paginate"
                 [pageSize]="limit" [pageSizeOptions]="limitOptions"
                 [length]="this.dataSource.count$ | async"></mat-paginator>
  <ng-content select="[wbo-table-bottom]"></ng-content>

  <wbo-overlay-loader *ngIf="loading" [inModal]="inModal"></wbo-overlay-loader>

</div>
