import {Directive, Input, TemplateRef} from '@angular/core';

@Directive({
  selector: '[wboColumn]',
})
export class Column {

  @Input('wboColumnName') name: string;
  @Input('wboColumnTitle') title: string;
  @Input('wboColumnSort') sortColumn: string;
  @Input('wboColumnHeader') headerColumn: TemplateRef<any>;
  @Input('wboColumnDisplayed') displayed = true;

  constructor(public content: TemplateRef<any>) {
  }
}
