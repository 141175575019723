<mat-drawer-container class="wbo-page">
  <mat-drawer mode="side" position="start" class="wbo-left-content" [opened]="true">
    <ng-content select="[wboLeftContent]"></ng-content>
  </mat-drawer>

  <mat-drawer-content>
    <ng-content></ng-content>
  </mat-drawer-content>

  <mat-drawer mode="side" position="end" class="wbo-right-content" [opened]="true">
    <ng-content select="[wboRightContent]"></ng-content>
  </mat-drawer>
</mat-drawer-container>
