import {WabelModel} from './wabel-model.abstract';
import {Member} from './member';
import {Summit} from './summit';
import {Registration} from './registration';
import {Tag} from './tag';

export class SummitRegistration extends WabelModel {
  constructor(obj?: any) {
    super(obj);
    if (obj) {
      this.summit = obj.summit ? new Summit(obj.summit) : null;
      this.registration = obj.eventRegistration ? new Registration(obj.eventRegistration) : null;
      this.categories = obj.categories && obj.categories.items ? obj.categories.items.map(category => new Tag(category)) : [];
      this.tags = obj.tags && obj.tags.items ? obj.tags.items.map(tag => new Tag(tag)) : [];
    }
  }

  summit: Summit;
  member: Member;
  registration: Registration;
  productsLookingFor: string;
  categories: Tag[] = [];
  tags: Tag[] = [];
  registrationMode: string;

  get id(): string {
    return this.summit.id + ',' + this.member.id;
  }
}
