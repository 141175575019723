import {TagType} from './tag_type';
import {WabelModel} from './wabel-model.abstract';
import {TagLocation} from './tag_location';
import {Company} from '@models/company';

export class Tag extends WabelModel {
  constructor(obj: any) {
    super(obj);
    if (obj) {
      this.id = obj.id ? +obj.id : 0;
      this.tagType = obj.type ? new TagType(obj.type) : null;
      this.parent = obj.parent ? new Tag(obj.parent) : null;
      this.children = obj.children && obj.children.items ? obj.children.items.map(t => new Tag(t)) : [];
      this.institutionsCount = obj.institutions && obj.institutions.count ? obj.institutions.count : 0;
      this.tagLocation = obj.tagLocation ? new TagLocation(obj.tagLocation) : null;
    }
  }

  id: number;
  name: string;
  machineName: string;
  tagType: TagType;
  parent: Tag;
  children: Tag[];
  institutionsCount: number;
  depth: number;
  tagLocation: TagLocation;
  companies: Company[];

  rootTag(): Tag {
    let rootTag: Tag = this;
    while (rootTag.parent) {
      rootTag = rootTag.parent;
    }
    return rootTag;
  }
}
