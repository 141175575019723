import {WabelModel} from './wabel-model.abstract';
import {Registration} from './registration';
import {Meeting} from './meeting';

export class RegistrationSharedMeeting extends WabelModel {
  constructor(obj?: any) {
    super(obj);
    if (obj) {
      this.idsharedmeeting = obj.idsharedmeeting ? +obj.idsharedmeeting : 0;
      this.date_created = obj.date_created ? new Date(obj.date_created) : null;
      this.registration = obj.certainEventRegistration ? new Registration(obj.certainEventRegistration) : null;
      this.meeting = obj.meeting ? new Meeting(obj.meeting) : null;
    }
  }

  idsharedmeeting: number;
  date_created: Date;
  meeting: Meeting;
  registration: Registration;

  get id() {
    return this.idsharedmeeting;
  }
}
