import gql from 'graphql-tag';

export class ErrorGraphQL {
  static fragments = {
    error: gql`
      fragment error on WabelApiException {
        error,
        code,
        message,
        hint,
        action {
          type,
          text,
          link,
          to,
          subject,
          multiple
        }
        data {
          zohoId,
          eventCode
        }
      }
    `
  };
}
