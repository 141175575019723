import {ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, Directive} from '@angular/core';
import {MatFormFieldControl} from '@angular/material/form-field';
import {NgModel, ValidationErrors} from '@angular/forms';
import {ThemePalette} from '@angular/material/core';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {ClipboardService} from 'ngx-clipboard';

@Directive()
export class InputAbstract implements OnInit {

  constructor(private clipboardService: ClipboardService) {
  }

  // Value congtrol
  @Input() _value: any;

  previousValue;

  set value(value) {
    this._value = value;
    this.onChange(value);
  };

  get value() {
    return this._value
  };

  ngOnInit(): void {
    this.previousValue = this.value;
  }

  // Implementing ngModel
  writeValue(value) {
    if (value !== undefined || !this.disabled) {
      this.value = value;
    }
  }

  onChange = (value: any) => {
  };
  onTouched = () => {
  };

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }


  // Input attributes

  // The input placeholder
  @Input() placeholder;

  // The input placeholder
  @Input() color: ThemePalette = null;

  // The input disable state
  private _disabled: boolean;
  get disabled(): boolean {
    return this._disabled;
  }

  @Input()
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
  }

  // The input required state
  private _required: boolean;
  get required(): boolean {
    return this._required;
  }

  @Input()
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
  }

  // The input required state
  private _readonly: boolean;
  get readonly(): boolean {
    return this._readonly;
  }

  @Input()
  set readonly(value: boolean) {
    this._readonly = coerceBooleanProperty(value);
  }

  // The input disable state
  @Input() appearance: 'standard' | "outline" | 'fill' = 'standard';

  // The input label
  @Input() label;

  // The input label
  @Input() hint: string;

  // If set to true, Magic Input will froze on edit mode
  @Input() icon: string;

  // If set to true, Magic Input will froze on edit mode
  private _saveAction: boolean;
  get saveAction(): boolean {
    return this._saveAction;
  }

  @Input()
  set saveAction(value: boolean) {
    this._saveAction = coerceBooleanProperty(value);
  }

  private _historyAction: boolean;
  get historyAction(): boolean {
    return this._historyAction;
  }

  @Input()
  set historyAction(value: boolean) {
    this._historyAction = coerceBooleanProperty(value);
  }

  private _clearAction: boolean;
  get clearAction(): boolean {
    return this._clearAction;
  }

  @Input()
  set clearAction(value: boolean) {
    this._clearAction = coerceBooleanProperty(value);
  }

  private _copyAction: boolean;
  get copyAction(): boolean {
    return this._copyAction;
  }

  @Input()
  set copyAction(value: boolean) {
    this._copyAction = coerceBooleanProperty(value);
  }

  @Input() maxlength = 0;


  // Save control
  @Output('validate') validateEvent = new EventEmitter();
  @Output('cancel') cancelEvent = new EventEmitter();
  @Output('clear') clearEvent = new EventEmitter();
  @Output('copy') copyEvent = new EventEmitter();

  validate() {
    this.previousValue = this.value;
    this.validateEvent.emit(this.value);
  }

  cancel() {
    this.value = this.previousValue;
    this.cancelEvent.emit();
  }

  clear() {
    this.value = null;
    // setTimeout(() => this.inputElement.nativeElement.focus());
    this.clearEvent.emit();
  }

  copy() {
    this.clipboardService.copy(this.value);
    this.copyEvent.emit(this.value);
  }

  // Field control
  @ViewChild(MatFormFieldControl) control: MatFormFieldControl<any>;
  // @ViewChild("input") inputElement: ElementRef;
  @ViewChild(NgModel) ngModel: NgModel;

  get unchanged(): boolean {
    return this.ngModel && (!this.ngModel.dirty || this.previousValue === this.value);
  }

  get dirty(): boolean {
    return this.ngModel && this.ngModel.dirty;
  }

  get empty(): boolean {
    return this.control && this.control.empty;
  }

  get touched(): boolean {
    return this.ngModel && this.ngModel.touched;
  }

  get invalid(): boolean {
    return this.ngModel && !!this.ngModel.control.errors;
  }

  get errors(): ValidationErrors {
    return this.ngModel ? this.ngModel.errors : null;
  }
}
