import {WabelModel} from './wabel-model.abstract';
import {BusinessMappingTag} from './business_mapping_tag';
import {Member} from './member';

export class BusinessMapping extends WabelModel {
  id: number;
  businessMappingTags: BusinessMappingTag[];
  member: Member;

  constructor(obj: any) {
    super(obj);
    if (obj) {
      this.businessMappingTags = obj.businessMappingTags && obj.businessMappingTags.items ? obj.businessMappingTags.items.map(bmt => new BusinessMappingTag(bmt)) : [];
      this.member = obj.member ? new Member(obj.member) : null;
    }
  }

}
