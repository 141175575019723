import {WabelModel} from './wabel-model.abstract';
import {File} from './file';

export class EventOrganizer extends WabelModel {

  constructor(obj: any = null) {
    super(obj);
    if (obj) {
      this.ideventorganizer = obj.ideventorganizer ? +obj.ideventorganizer : 0;
      this.fileLogo = obj.fileLogo ? new File(obj.fileLogo) : null;
      this.fileSmallLogo = obj.fileSmallLogo ? new File(obj.fileSmallLogo) : null;
    }
  }

  ideventorganizer: number;
  name: string;
  urlname: string;
  fileLogo: File;
  fileSmallLogo: File;
  mainColor: string;
  linkTc: string;
  link: string;
  helpdeskKey: string;
  events: Event[];

  get id() {
    return this.ideventorganizer;
  }
}
