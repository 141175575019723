import {WabelModel} from './wabel-model.abstract';

export class EcoPackProject extends WabelModel {
  idecopackproject: number;
  name: string;

  constructor(obj: any) {
    super(obj);
    if (obj) {
      this.idecopackproject = obj.idecopackproject ? +obj.idecopackproject : 0;
    }
  }

  get id(): number {
    return this.idecopackproject;
  }
}
