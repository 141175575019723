import {Component} from '@angular/core';
import {slideEnterLeaveAnimation} from '../../animations/slide-enter-leave.animation';
import {environment} from '../../../environments/environment';
import {RightsService} from '@services/rights.service';

@Component({
  selector: 'app-access-denied',
  templateUrl: './access-denied.component.html',
  animations: [slideEnterLeaveAnimation],
  styleUrls: ['./access-denied.component.scss']
})
export class AccessDeniedComponent {

  constructor(public rightService: RightsService) {
  }

  get switchbackUrl() {
    return environment.needl_back_end_url + '/wabel-admin/member/switchback?redirect=' + encodeURIComponent(this.rightService.urlBeforeAccessDenied);
  }

  get loginUrl() {
    return environment.needl_front_end_url + '/user/login?destination=' + encodeURIComponent(this.rightService.urlBeforeAccessDenied);
  }

}
