import {Observable} from 'rxjs';
import {Apollo, QueryRef} from 'apollo-angular';
import {Injectable} from '@angular/core';
import {FetchPolicy} from '@apollo/client';

@Injectable({
  providedIn: 'root'
})
export class GraphQLService {

  constructor(protected apollo: Apollo) {
  }

  query(
    graphQLQuery: any,
    data: any = null,
    fetchPolicy: string = 'cache-and-network',
    pollInterval?: number,
    nextFetchPolicy: string = 'no-cache'
  ): QueryRef<any> {
    return this.apollo.watchQuery(
      {
        query: graphQLQuery,
        variables: data,
        fetchPolicy: fetchPolicy as FetchPolicy,
        nextFetchPolicy: nextFetchPolicy as FetchPolicy,
        pollInterval: pollInterval
      }
    );
  }

  mutation(graphQLMutation: any, data: any = {}, refetch?: any): Observable<any> {
    return this.apollo.mutate(
      {
        mutation: graphQLMutation,
        variables: data,
        refetchQueries: refetch
      }
    );
  }
}
