import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HeaderComponent} from './shared/components/header/header.component';
import {HomeComponent} from './pages/home/home.component';
import {AccessDeniedComponent} from './pages/access-denied/access-denied.component';
import {FooterModule} from './shared/footer.module';
import {HttpClientModule} from '@angular/common/http';
import {DatePipe} from '@angular/common';
import {WabelBODialogModule, WabelBOFormModule, WabelBOLayoutModule, WabelBOTableModule} from 'wabel-bo';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {GraphQLModule} from './graphql.module';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    AccessDeniedComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FooterModule,
    HttpClientModule,
    WabelBOLayoutModule,
    WabelBOTableModule,
    WabelBOFormModule,
    WabelBODialogModule,
    MatButtonModule,
    MatIconModule,
    GraphQLModule,
  ],
  providers: [
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
