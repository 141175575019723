import {WabelModel} from './wabel-model.abstract';
import {Registration} from './registration';

export class RegistrationPresenceday extends WabelModel {
  constructor(obj?: any) {
    super(obj);
    if (obj) {
      this.date = obj.date ? new Date(obj.date) : null;
      this.registration = obj.certainEventRegistration ? new Registration(obj.certainEventRegistration) : null;
    }
  }

  date: Date;
  slot: string;

  registration: Registration;

  get id() {
    return 0;
  }
}
