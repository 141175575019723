import {WabelModel} from './wabel-model.abstract';
import {Company} from './company';
import {CompanyRelationshipStatus} from './company_relationship_status';
import {Member} from './member';
import {CompanyRelationshipComment} from './company_relationship_comment';
import {CompanyRelationshipTagReference} from './company_relationship_tag_reference';

export class CompanyRelationship extends WabelModel {

  constructor(obj?: any) {
    super(obj);
    if (obj) {
      this.idcompanyRelationship = obj.idcompanyRelationship ? +obj.idcompanyRelationship : 0;
      this.fromIdcompany = obj.fromIdcompany ? new Company(obj.fromIdcompany) : null;
      this.fromIdmember = obj.fromIdmember ? new Member(obj.fromIdmember) : null;
      this.toIdcompany = obj.toIdcompany ? new Company(obj.toIdcompany) : null;
      this.toIdmember = obj.toIdmember ? new Member(obj.toIdmember) : null;
      this.companiesRelationshipComments = obj.companiesRelationshipComments && obj.companiesRelationshipComments.items ? obj.companiesRelationshipComments.items.map(companiesRelationshipComment => new CompanyRelationshipComment(companiesRelationshipComment)) : [];
      this.tags = obj.tags ? obj.tags.map(tag => new CompanyRelationshipTagReference(tag)) : [];
      this.statusFrom = obj.statusFrom ? new CompanyRelationshipStatus(obj.statusFrom) : null;
      this.statusTo = obj.statusTo ? new CompanyRelationshipStatus(obj.statusTo) : null;
      this.currentStatus = obj.currentStatus ? new CompanyRelationshipStatus(obj.currentStatus) : null;
      this.createdDate = obj.createdDate ? new Date(obj.createdDate) : null;
      this.updatedDate = obj.updatedDate ? new Date(obj.updatedDate) : null;
    }
  }

  idcompanyRelationship: number;
  fromIdcompany: Company;
  toIdcompany: Company;
  fromIdmember: Member;
  toIdmember: Member;
  scoreFrom: number;
  scoreTo: number;
  statusFrom: CompanyRelationshipStatus;
  currentStatus: CompanyRelationshipStatus;
  companiesRelationshipComments: CompanyRelationshipComment[] = [];
  statusTo: CompanyRelationshipStatus;
  type: string;
  eventCode: string;
  createdDate: Date;
  updatedDate: Date;
  isFromMember: boolean = null;
  tags: CompanyRelationshipTagReference[] = [];
  commentWabel: string;

  get id(): number {
    return this.idcompanyRelationship;
  }
}
