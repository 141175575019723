import {WabelModel} from './wabel-model.abstract';

export class CompanyType extends WabelModel {

  constructor(obj?: any) {
    super(obj);
    if (obj) {
      this.idcompanytype = obj.idcompanytype ? +obj.idcompanytype : 0;
    }
  }

  idcompanytype: number;
  name: string;
  badgeLabel: string;

  get id(): number {
    return this.idcompanytype;
  }
}
