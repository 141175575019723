import {WabelModel} from './wabel-model.abstract';
import {Member} from './member';
import {Meeting} from './meeting';

export class MeetingParticipantHistory extends WabelModel {

  constructor(obj?: any) {
    super(obj);
    if (obj) {
      this.id = obj.id ? +obj.id : 0;
      this.meeting = obj.meeting ? new Meeting(obj.meeting) : null;
      this.member = obj.member ? new Member(obj.member) : null;
    }
  }

  id: number;
  meeting: Meeting;
  member: Member;
  displayName: string;
  action: string;
  actionDate: string;
}
