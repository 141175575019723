import {AfterViewInit, ContentChild, Directive, Input} from '@angular/core';
import {NgModel} from '@angular/forms';

@Directive({
  selector: '[wboFilterCallback]'
})
export class FilterCallback implements AfterViewInit {
  @Input() wboFilterCallback: (subject, filterValue) => boolean;
  @ContentChild(NgModel, {static: false}) input: NgModel;

  ngAfterViewInit() {
  }
}
