import {Component, EventEmitter, forwardRef, Input, Output} from '@angular/core';
import {InputAbstract} from '../input-abstract';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {ClipboardService} from 'ngx-clipboard';

@Component({
  selector: 'wbo-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UploadComponent),
      multi: true
    }
  ],
})
export class UploadComponent extends InputAbstract {
  constructor(clipboardService: ClipboardService) {
    super(clipboardService);
  }

  @Input() label: string = "Select file(s)";
  @Input() selectText: string = "Select";
  @Input() uploadText: string = "Upload";
  @Input() multiple: boolean = false;
  @Input() fileUrl: string = null;
  @Input() showUploadButton: boolean = true;
  @Output() fileSelected = new EventEmitter();

  predefinedType: "document" | "image" = null;
  private _acceptedTypes: string = "*.*";
  @Input() set acceptedTypes(fileTypes: "document" | "image" | string) {
    switch (fileTypes) {
      case 'image':
        this._acceptedTypes = ".jpg, .png, .gif";
        this.predefinedType = fileTypes;
        break;
      case 'document':
        this._acceptedTypes = ".doc, .docx, .pdf, .xls, .xlsx, .odt, .odf"
        this.predefinedType = fileTypes;
        break;
      default:
        this._acceptedTypes = fileTypes;
    }
  }

  selectFile($event) {
    if (!this.multiple && $event) {
      this.writeValue($event[0]);
      this._readInputFileUrl();
    } else {
      this.writeValue($event);
    }
    this.fileSelected.emit($event);
  }

  get acceptedTypes(): string {
    return this._acceptedTypes;
  }

  private _readInputFileUrl() {
    var reader = new FileReader();

    reader.readAsDataURL(this.value); // read file as data url

    reader.onload = (event) => { // called once readAsDataURL is completed
      this.fileUrl = event.target.result + '';
    }
  }
}
