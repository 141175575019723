<mat-form-field #formField class="wabel-bo-input" [color]="color" [appearance]="appearance"
                [class.filled]="appearance === 'fill'" [class.no-hint]="!hint" [class.no-label]="!label"
                [class.has-errors]="invalid && touched">
  <mat-label *ngIf="label">{{label}}</mat-label>

  <ng-container matPrefix>
    <mat-icon *ngIf="icon">{{icon}}</mat-icon>
  </ng-container>

  <input #input [required]="required" matInput [placeholder]="placeholder" [(ngModel)]="value" [min]='minDate'
         [max]='maxDate' [matDatetimepicker]="datetimePicker"/>
  <mat-datetimepicker #datetimePicker mode="portrait" touchUi='true' [type]="type" openOnFocus="true"
                      [timeInterval]="timeInterval"></mat-datetimepicker>

  <wbo-wrapper-suffix matSuffix [touched]="touched" [invalid]="invalid" [unchanged]="unchanged" [required]="required"
                      [dirty]="dirty" [empty]="empty" [saveAction]="saveAction" [clearAction]="clearAction"
                      [historyAction]="historyAction" [copyAction]="copyAction" (copyEvent)="copy()"
                      (cancelEvent)="cancel()" (validateEvent)="validate()" (clearEvent)="clear()"
                      (click)="$event.stopPropagation()">
    <ng-content select="[wboSuffix]"></ng-content>
    <button mat-icon-button [color]="color" class="wbo-action-button select-date" (mousedown)="datetimePicker.open();">
      <mat-icon>{{ type === 'time' ? 'schedule' : 'today'}}</mat-icon>
    </button>
  </wbo-wrapper-suffix>

  <mat-error *ngIf="touched && invalid">
    <ng-container *ngIf="ngModel.errors.required">This field cannot be empty.</ng-container>
  </mat-error>

  <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
</mat-form-field>
