import {WabelModel} from './wabel-model.abstract';

export class Sponsor extends WabelModel {
  constructor(obj?: any) {
    super(obj);
    if (obj) {
      this.id = obj.id ? +obj.id : 0;
    }
  }

  id: number;
  name: string;
}
