import {Company} from './company';
import {WabelModel} from './wabel-model.abstract';
import {Member} from './member';
import {CompanyProfileFeedback} from './company_profile_feedback';


export class CompanyProfileSubmission extends WabelModel {
  idcompanyprofilesubmission: number;
  createdByCompany: Company;
  createdForCompany: Company;
  createdByMember: Member;
  createdForMember: Member;
  profileTitle: string;
  profileDescription: string;
  isExportingToBuyerCountry: boolean;
  isFeaturedProducts: boolean;
  profileStatus: string;
  profileStep: string;
  createdAt: string;
  updatedAt: string;
  buyerStatus: string;
  companyProfileFeedbacks: CompanyProfileFeedback[];

  constructor(obj: any) {
    super(obj);
    if (obj) {
      this.idcompanyprofilesubmission = obj.idcompanyprofilesubmission ? +obj.idcompanyprofilesubmission : 0;
      this.profileStatus = obj.profileStatus ? obj.profileStatus : null;
      this.profileStep = obj.profileStep ? obj.profileStep : null;
      this.createdByCompany = obj.createdByCompany ? new Company(obj.createdByCompany) : null;
      this.createdForCompany = obj.createdForCompany ? new Company(obj.createdForCompany) : null;
      this.createdByMember = obj.createdByMember ? new Member(obj.createdByMember) : null;
      this.createdForMember = obj.createdForMember ? new Member(obj.createdForMember) : null;
      this.companyProfileFeedbacks = obj.companyProfileFeedbacks && obj.companyProfileFeedbacks.items ? obj.companyProfileFeedbacks.items.map((feedback: any) => new CompanyProfileFeedback(feedback)) : [];
    }
  }

  get id(): number {
    return this.idcompanyprofilesubmission;
  }

  get buyerStatusSortPriority(): number {
    switch (this.buyerStatus) {
      case 'interested':
        return 1;
      case 'pending':
        return 2;
      case 'not interested':
        return 3;
      default:
        return 0;
    }
  }

}
