import {WabelModel} from './wabel-model.abstract';
import {Member} from './member';
import {PrivateMessageThread} from './private_message_thread';
import {Company} from './company';

export class PrivateMessageRecipient extends WabelModel {
  id: number;
  member: Member;
  company: Company;
  addedAt: number;
  thread: PrivateMessageThread;

  constructor(obj: any) {
    super(obj);

    if (obj) {
      this.id = obj.id ? +obj.id : 0;
      this.member = obj.member ? new Member(obj.member) : null;
      this.company = obj.company ? new Company(obj.company) : null;
      this.thread = obj.thread ? new PrivateMessageThread(obj.thread) : null;
    }
  }
}
