import {Directive, HostListener, Input, OnDestroy, TemplateRef} from '@angular/core';
import {pipe, Subscription} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {ModalComponent} from '../components/modal/modal.component';
import {ModalService} from '../services/modal.service';

@Directive({
  selector: 'button[wboModal], a[wboModal]:not([routerLink])'
})
export class ModalDirective implements OnDestroy {

  @Input('wboModal') content: TemplateRef<any>
  @Input('wboContext') context: any
  @Input('wboTitle') title: string
  @Input('wboCloseText') closeText: string = 'Close'
  @Input('wboMinWidth') minWidth: string = '0'
  @Input('wboMinHeight') minHeight: string = '0'
  closeSubscription: Subscription;

  @HostListener('click')
  onClick() {
    if (this.content) {
      this.modalService.modalRef = this.dialog.open(ModalComponent, {
        data: {
          content: this.content,
          context: this.context,
          title: this.title,
          closeText: this.closeText
        },
        minWidth: this.minWidth,
        minHeight: this.minHeight
      });
      document.body.style.overflow = 'hidden';
    }
    this.closeSubscription = this.dialog.afterAllClosed.subscribe(pipe(source => {
      document.body.style.overflow = 'auto';
      this.closeSubscription.unsubscribe();
    }));
  }

  constructor(public dialog: MatDialog, private modalService: ModalService) {
  }

  ngOnDestroy(): void {
    if (this.closeSubscription) {
      this.closeSubscription.unsubscribe();
    }
  }

}
