import {WabelModel} from './wabel-model.abstract';
import {Registration} from './registration';
import * as moment from "moment";

export class RegistrationAvailability extends WabelModel {
  constructor(obj?: any) {
    super(obj);
    if (obj) {
      this.availabilityStart = obj.availabilityStart ? new Date(obj.availabilityStart) : null;
      this.rawAvailabilityStart = obj.availabilityStart ? obj.availabilityStart : null;
      this.availabilityEnd = obj.availabilityEnd ? new Date(obj.availabilityEnd) : null;
      this.rawAvailabilityEnd = obj.availabilityEnd ? obj.availabilityEnd : null;
      this.startDate = obj.startDate ? new Date(obj.startDate) : null;
      this.endDate = obj.endDate ? new Date(obj.endDate) : null;
      this.registration = obj.certainEventRegistration ? new Registration(obj.certainEventRegistration) : null;
    }
  }

  availabilityStart: Date;
  availabilityEnd: Date;
  rawAvailabilityStart: string;
  rawAvailabilityEnd: string;
  startDate: Date;
  endDate: Date;
  text: string;

  private startEventTZ: Date;
  private endEventTZ: Date;

  registration: Registration;

  get id() {
    return 0;
  }

  startDateEvent(timezone: string): Date {
    if (this.startEventTZ) {
      return this.startEventTZ;
    }
    let computerTimeZone = moment.tz.guess();
    let dateString = this.rawAvailabilityStart.split('+')[0];
    const eventDateTz = moment.tz(dateString, timezone);
    this.startEventTZ = moment.tz(eventDateTz.format('YYYY-MM-DDTHH:mm:ss'), computerTimeZone).toDate();
    return this.startEventTZ;
  }

  endDateEvent(timezone: string) {
    if (this.endEventTZ) {
      return this.endEventTZ;
    }
    let computerTimeZone = moment.tz.guess();
    let dateString = this.rawAvailabilityEnd.split('+')[0];
    const eventDateTz = moment.tz(dateString, timezone);
    this.endEventTZ = moment.tz(eventDateTz.format('YYYY-MM-DDTHH:mm:ss'), computerTimeZone).toDate();
    return this.endEventTZ;
  }
}
