import {Injectable} from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot, Router
} from '@angular/router';
import {MemberService} from '@services/wabel-client/member.service';
import {Observable} from 'rxjs';
import {Member} from '@models/member';
import {RightsService} from '@services/rights.service';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IsWabelAdminGuard implements CanActivate {

  get me() {
    return this.rightsService.me;
  }

  set me(member: Member) {
    this.rightsService.me = member;
  }

  constructor(
    private router: Router,
    private memberService: MemberService,
    private rightsService: RightsService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.me) {
      return this.redirect();
    }
    return this.memberService.getCurrentMemberForGuard().pipe(map((data: Member) => {
      this.me = data;
      return this.redirect();
    }));
  }

  redirect() {
    if (!this.rightsService.canAccesBo(this.me)) {
      this.rightsService.urlBeforeAccessDenied = window.location.href;
      this.router.navigate(['/access-denied']);
      return false;
    }
    return true;
  }
}
