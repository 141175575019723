import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {InputsDocComponent} from './inputs-doc/inputs-doc.component';
import {DocsComponent} from './docs.component';
import {DocsRoutingModule} from './docs-routing.module';
import {FormsModule} from '@angular/forms';
import {MatCardModule} from '@angular/material/card';
import {MatOptionModule} from '@angular/material/core';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {WabelBOFormModule} from '../forms/form.module';
import {MatTabsModule} from '@angular/material/tabs';
import {WabelBOLayoutModule} from '../layout/layout.module';


@NgModule({
  declarations: [InputsDocComponent, DocsComponent],
  imports: [
    CommonModule,
    FormsModule,
    DocsRoutingModule,
    WabelBOFormModule,
    MatCardModule,
    MatOptionModule,
    MatSlideToggleModule,
    MatTabsModule,
    WabelBOLayoutModule
  ],
  exports: [
    DocsComponent
  ]
})
export class WabelBODocsModule {
}
