import {Directive, HostListener, Input, TemplateRef} from '@angular/core';
import {MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';
import {AlertService} from '../services/alert.service';

@Directive({
  selector: 'button[wboAlert], a[wboAlert]:not([routerLink])'
})
export class AlertDirective {

  @Input('wboAlert') content: TemplateRef<any>
  @Input('wboContext') context: any
  @Input('wboDismissText') closeText: string = 'Close'
  @Input('wboMoreDetails') moreDetails: string = null
  @Input('wboMoreDetailsText') moreDetailsText: string = 'More details...'
  @Input('wboClass') classes: string = ''
  @Input('wboXPosition') xPosition: MatSnackBarHorizontalPosition = 'right'
  @Input('wboYPosition') yPosition: MatSnackBarVerticalPosition = 'top'

  @HostListener('click')
  onClick() {
    this.alertService.openAlert(this.content, this.context, {
      closeText: this.closeText,
      moreDetails: this.moreDetails,
      moreDetailsText: this.moreDetailsText,
      classes: this.classes,
      xPosition: this.xPosition,
      yPosition: this.yPosition,
    })
  }

  constructor(public alertService: AlertService) {
  }

}
