import {WabelModel} from '@models/wabel-model.abstract';

export class SourcingProjectProspect extends WabelModel {
    constructor(obj: any) {
        super(obj);

        if (obj) {
        }
    }

    id: number;
}
