/**
 * Created by Michael on 20/01/2017.
 */
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DocsComponent} from './docs.component';
import {InputsDocComponent} from './inputs-doc/inputs-doc.component';

const docsRoutes: Routes = [
  {
    path: '',
    component: DocsComponent,
    children: [
      {
        path: 'forms',
        component: InputsDocComponent
      },
      {
        path: '',
        redirectTo: "forms",
        pathMatch: "full"
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(docsRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class DocsRoutingModule {
}
