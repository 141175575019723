import {Injectable} from '@angular/core';
import {AlertService} from 'wabel-bo';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private alertService: AlertService) {
  }

  showSuccess(message: string, dismissText: string = 'Dismiss', duration: number = 5000) {
    this.alertService.sendAlert(message, dismissText, null, duration, ['alert', 'alert-success']);
  }

  showError(message: string, dismissText: string = 'Dismiss') {
    this.alertService.sendAlert(message, dismissText, 'An error occured', null, ['alert', 'alert-danger']);
  }

  showWarning(message: string, dismissText: string = 'Dismiss') {
    this.alertService.sendAlert(message, dismissText, 'Warning', null, ['alert', 'alert-warning']);
  }
}
