import {Component, Inject} from '@angular/core';
import {MAT_SNACK_BAR_DATA} from '@angular/material/snack-bar';
import {AlertService} from '../../services/alert.service';

@Component({
  selector: 'wbo-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent {

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any, public alertService: AlertService) {
  }
}
