import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ThemePalette} from '@angular/material/core';

@Component({
  selector: 'wbo-wrapper-suffix',
  templateUrl: './wrapper-suffix.component.html',
  styleUrls: ['./wrapper-suffix.component.scss']
})
export class WrapperSuffixComponent {
  @Input() disabled: boolean;
  @Input() dirty: boolean;
  @Input() invalid: boolean;
  @Input() empty: boolean;
  @Input() touched: boolean;
  @Input() unchanged: boolean;
  @Input() saveAction: boolean;
  @Input() historyAction: boolean;
  @Input() clearAction: boolean;
  @Input() copyAction: boolean;
  @Input() required: boolean;
  @Input() color: ThemePalette = null;

  @Output() clearEvent = new EventEmitter();
  @Output() cancelEvent = new EventEmitter();
  @Output() validateEvent = new EventEmitter();
  @Output() copyEvent = new EventEmitter();
}
