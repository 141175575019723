import {Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';

@Component({
  selector: 'wbo-option',
  templateUrl: './option.component.html',
  styleUrls: ['./option.component.scss']
})
export class OptionComponent implements OnInit {

  @Input() value: any;
  @Input() disabled: boolean;
  @Input() searchValue: string;
  @Input() projectedClass: string;
  @Input() projectedTitle: string;
  @ViewChild(TemplateRef) content: TemplateRef<any>;

  constructor() {
  }

  ngOnInit() {
  }

}
