import {Tag} from './tag';
import {WabelModel} from './wabel-model.abstract';

export class TagLocation extends WabelModel {
  constructor(obj: any) {
    super(obj);
    if (obj) {
      this.tag = obj.tag ? new Tag(obj.tag) : null;
    }
  }

  tag: Tag;
  code: string;
  backgroundImage: string;
  nbPurchasingGroups: number;
  nbImporters: number;
  nbCoPackers: number;

  get id() {
    return this.code;
  }
}
