import {
  Component,
  forwardRef,
  Input,
} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {InputAbstract} from '../input-abstract';
import {ClipboardService} from 'ngx-clipboard';

@Component({
  selector: 'wbo-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss', '../input.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true
    }
  ],
})
export class InputComponent extends InputAbstract {
  constructor(clipboardService: ClipboardService) {
    super(clipboardService);
  }

  @Input() type = 'text';
}
