import {WabelModel} from './wabel-model.abstract';
import {CompanyProfileSubmission} from './company_profile_submission';
import {Member} from './member';

export class CompanyProfileFeedback extends WabelModel {

  constructor(obj: any) {
    super(obj);
    if (obj) {
      this.idfeedback = obj.idfeedback ? +obj.idfeedback : 0;
      this.companyprofilesubmission = obj.companyprofilesubmission ? new CompanyProfileSubmission(obj.companyprofilesubmission) : null;
      this.member = obj.member ? new Member(obj.member) : null;
    }
  }

  idfeedback: number;
  companyprofilesubmission: CompanyProfileSubmission;
  member: Member;
  status: string;
  rejectionReason: string;
  createdAt: string;
  updatedAt: string;

  get id(): number {
    return this.idfeedback;
  }
}
