import {WabelModel} from './wabel-model.abstract';
import {Member} from './member';
import {Tag} from './tag';
import {PackagingUnit} from './packaging_unit';
import {SourcingRequestBlacklistedCompany} from './sourcing_request_blacklisted_company';
import {SourcingRequestKeyword} from './sourcing_request_keyword';
import {File} from '@models/file';

export class SourcingRequest extends WabelModel {
  constructor(obj: any) {
    super(obj);
    if (obj) {
      this.id = obj.id ? +obj.id : 0;
      this.memberBuyer = obj.memberBuyer ? new Member(obj.memberBuyer) : null;
      this.expiryDate = obj.expiryDate ? new Date(obj.expiryDate) : null;
      this.reviewStartDate = obj.reviewStartDate ? new Date(obj.reviewStartDate) : null;
      this.reviewEndDate = obj.reviewEndDate ? new Date(obj.reviewEndDate) : null;
      this.categories = obj.categories && obj.categories.items ? obj.categories.items.map(cat => new Tag(cat)) : [];
      this.activities = obj.activities && obj.activities.items ? obj.activities.items.map(activity => new Tag(activity)) : [];
      this.pricePositioning = obj.pricePositioning && obj.pricePositioning.items ? obj.pricePositioning.items.map(pp => new Tag(pp)) : [];
      this.productAttributes = obj.productAttributes && obj.productAttributes.items ? obj.productAttributes.items.map(productAttribute => new Tag(productAttribute)) : [];
      this.certificationsAndLabels = obj.certificationsAndLabels && obj.certificationsAndLabels.items ? obj.certificationsAndLabels.items.map(tag => new Tag(tag)) : [];
      this.distributionCountries = obj.distributionCountries && obj.distributionCountries.items ? obj.distributionCountries.items.map(country => new Tag(country)) : [];
      this.volumeTags = obj.volumeTags && obj.volumeTags.items ? obj.volumeTags.items.map(volumeTag => new Tag(volumeTag)) : [];
      this.blacklistedCompanies = obj.blacklistedCompanies && obj.blacklistedCompanies.items ? obj.blacklistedCompanies.items.map(comp => new SourcingRequestBlacklistedCompany(comp)) : [];
      this.preferredSupplierCountries = obj.preferredSupplierCountries && obj.preferredSupplierCountries.items ? obj.preferredSupplierCountries.items.map(country => new Tag(country)) : [];
      this.packagingUnit = obj.packagingUnit ? new PackagingUnit(obj.packagingUnit) : null;
      this.successDate = obj.successDate ? new Date(obj.successDate) : null;
      this.successMember = obj.successIdMember ? new Member(obj.successIdMember) : null;
      this.keywords = obj.keywords && obj.keywords.items ? obj.keywords.items.map(keyword => new SourcingRequestKeyword(keyword)) : [];
      this.file = obj.file ? new File(obj.file) : null;
    }
  }

  id: number;
  name: string;
  status: string;
  memberBuyer: Member;
  expiryDate: Date;
  reviewStartDate: Date;
  reviewEndDate: Date;
  createdDate: Date;
  categories: Tag[];
  dates: string;
  recommendationsCount: number;
  suppliersBuyerInterestedInCount: number;
  suppliersBuyerNotInterestedInCount: number;
  distributionChannels: string;
  distributionCountries: Tag[];
  preferredSupplierCountries: Tag[];
  activities: Tag[];
  pricePositioning: Tag[];
  productAttributes: Tag[];
  certificationsAndLabels: Tag[];
  volumeTags: Tag[];
  packagingValue: string;
  packagingUnit: PackagingUnit;
  packageWidth: number;
  packageHeight: number;
  packageLength: number;
  productSpec: string;
  volume: string;
  suppliersInterestedBySourcingRequestCount: number;
  suppliersNotInterestedBySourcingRequestCount: number;
  blacklistedCompanies: SourcingRequestBlacklistedCompany[];
  keywords: SourcingRequestKeyword[];

  successMember: Member;
  successStatus: string;
  successReason: string;
  successDate: Date;
  lastSuppliersEnteredDate: string;
  dateLastSupplierFoundNotificationSent: string;
  file: File;
}
