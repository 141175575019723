import gql from 'graphql-tag';
import {ErrorGraphQL} from './error.graphql';
import {TagTypeGraphql} from './tag_type.graphql';
import {TagTypeService} from '../wabel-client/tag_type.service';

export class MemberGraphQL {
  static fragments = {
    me: gql`
      fragment me on Member {
        idmember,
        firstname,
        name,
        fullname,
        nameInitials
        permissions,
        company {
          name
          idcompany
        }
        isMasquerading
      }
    `,
    light: gql`
      fragment lightMember on Member {
        idmember,
        fullname,
        profileScore,
        zohoId,
        company {
          idcompany,
          name,
          type,
          zohoId
        }
      }
    `,
    full: gql`
      fragment fullMember on Member {
        idmember,
        firstname,
        name,
        fullname,
        zohoId,
        jobtitle,
        title,
        status,
        profileScore,
        email,
        mobile,
        logoUrl(size: "123")
        company {
          idcompany,
          name,
          type,
          status,
          country {
            name
          },
          company_type_machine_name,
          zohoId,
          products(published: true) {
            iduserproduct
          },
          categories: tags(tagTypeInternalName: "${TagTypeService.CATEGORY_INTERNAL_NAME}") {
            items {
              ...tag
            }
          }
          eventParticipations {
            certainEvent {
              eventCode
            }
          },
          parentCompany {
            idcompany,
            name,
            type
          },
          allMembers(active: true) {
            idmember
          }
          buyingOffices {
            idcompany
          }
          purchasingGroups {
            idcompany
          }
          onMembers {
            idmember
          }
        },
        relations (sortField: "created_date", sortOrder: "ASC"){
          idcompanyRelationship,
          type,
          eventCode,
          fromIdcompany {
            idcompany,
            name,
            urlname
          },
          toIdcompany {
            idcompany,
            name,
            urlname
          },
          fromIdmember {
            idmember,
            fullname
          },
          toIdmember {
            idmember,
            fullname
          },
          scoreFrom,
          scoreTo,
          statusFrom {
            idrelationshipStatus,
            name
          },
          statusTo {
            idrelationshipStatus,
            name
          },
          currentStatus {
            idrelationshipStatus,
            name
          },
          updatedDate,
          createdDate
        }
      }
      ${TagTypeGraphql.fragments.tag}
    `,
    list: gql`
      fragment listMember on Member {
        idmember,
        firstname,
        name,
        fullname,
        email,
        mobile,
        status,
        hasEmailVerified,
        isCompanyOwner,
        profileScore,
        createdDate,
        zohoId,
        isCompanyOwner,
        joinMode
        statusExpiryDate
        company {
          idcompany,
          name,
          type,
          membership,
          urlname,
          status
          companytype {
            idcompanytype
          }
          purchasingGroup {
            idcompany
            organisation
            privateLabel
            totalSales
          }
          buyingOffice {
            idcompany
            storeCheckPath
            logisticInfo
            wabelExpertise
          }
          registrationStep
        }
      }
    `,
    menuActionsMember: gql`
      fragment menuActionsMember on Member {
        idmember
        fullname
        email
        mobile
        mobile_event
        status
        hasEmailVerified
        isCompanyOwner
        zohoId
        dateLastLogged
        dateLastActivity
        timezone
        createdDate
        company {
          idcompany
          name
          urlname
          zohoId
          website
          status
          companytype {
            idcompanytype
            name
          }
          activeMemberships {
            subscriptionpack {
              label
            }
          }
        }
      }
    `,
    memberStatusInfo: gql`
      fragment memberStatusInfo on Member {
        idmember
        status
        statusExpiryDate
        fullname
        company {
          idcompany
          status
        }
        rejectionReason {
          id
          internalReason
          externalReason
        }
        customRejectionReason
      }
    `
  };

  static queries = {

    currentMemberForGuard: gql`
      query BOQueryGetCurrentMemberForGuard {
        me {
          ...me
        }
      }
      ${MemberGraphQL.fragments.me}
    `,

    currentMemberForResolver: gql`
      query BOQueryGetCurrentMemberForResolver($idmember: Int!) {
        member(idmember: $idmember) {
          idmember
          email
          fullname
          mobile
          logoUrl(size: "123")
          status
          zohoId
          company {
            idcompany
            name
            type
          }
        }
      }
    `,

    findMembersByFilter: gql`
      query BOQueryFindMembersByFilter($limit: Int, $offset: Int, $order: String, $search: String, $idcompanytype: Int, $status: [String!], $membership: String, $creationSource: [String!], $idcompany: Int) {
        findMembersByFilter(search: $search, idcompanytype: $idcompanytype, status: $status, membership: $membership, creationSource: $creationSource, order: $order, idcompany: $idcompany) {
          count,
          items(limit: $limit, offset: $offset) {
            ...memberStatusInfo
            ...listMember
            ...menuActionsMember
          }
        }
      }
      ${MemberGraphQL.fragments.list}
      ${MemberGraphQL.fragments.menuActionsMember}
      ${MemberGraphQL.fragments.memberStatusInfo}
    `,

    findMembersByCompany: gql`
      query BOQueryFindMembersByCompany($idcompany: Int) {
        findMembersByFilter(idcompany: $idcompany) {
          count
          items {
            idmember
            firstname
            name
            fullname
            status
          }
        }
      }
    `,

    findMemberByZohoId: gql`
      query BOQueryGetMemberByZohoId($zoho_id: String!) {
        findMemberByZohoId(id: $zoho_id) {
          ...fullMember
        }
      }
      ${MemberGraphQL.fragments.full}
    `,

    memberByEmail: gql`
      query BOQueryGetMemberByEmail($email: String!) {
        memberByEmail(email: $email) {
          idmember,
          email,
          firstname,
          name,
          mobile_event
          company {
            idcompany,
            name,
            website,
            companytype {
              idcompanytype
            }
          }
        }
      }
    `,

    getMemberPermissions: gql`
      query BOQueryGetMemberPermissions($idmember: Int!) {
        member(idmember: $idmember) {
          idmember,
          permissions(noCache: true)
        }
      }
    `,

    findMemberInfoAboutRelationships: gql`
      query BOQueryGetMemberInfoForMerge($idmember: Int!) {
        member(idmember: $idmember) {
          idmember
          fullname
          company {
            idcompany
            name
            companytype {
              idcompanytype
              name
            }
          }
          allEventRegistrations {
            items {
              eventCode
              name
            }
          }
          privateMessageThreads {
            count
          }
        }
      }
    `,

    memberByEmailAndCompanyType: gql`
      query BOQueryGMemberByEmailAndCompanyType($email: String!, $type: String!) {
        memberByEmailAndCompanyType(email: $email, type: $type) {
          idmember,
          firstname,
          name,
          email,
          status
        }
      }
    `,

    memberByEmailAndCompanyAndNotInSourcingRequestRecommendation: gql`
      query BOQueryGetMemberByEmailAndCompanyAndNotInSourcingRequestRecommendation($email: String!, $idcompany: Int!, $idsourcingrequest: Int!) {
        memberByEmailAndCompanyAndNotInSourcingRequestRecommendation(email: $email, idcompany: $idcompany, idsourcingrequest: $idsourcingrequest) {
          idmember,
          firstname,
          fullname,
          name,
          email
        }
      }
    `,

    findMembersInTangoByZohoId: gql`
      query BOQueryGetMembersByZohoId($zoho_id_list: [String!]!) {
        findMembersInTangoByZohoId(zoho_id_list: $zoho_id_list) {
          members {
            ...fullMember
          },
          errors {
            ...error
          }
        }
      }
      ${MemberGraphQL.fragments.full}
      ${ErrorGraphQL.fragments.error}
    `,

    memberOverview: gql`
      query BOQueryGetMemberInfoForOverview($idmember: Int!) {
        member(idmember: $idmember) {
          idmember
          email
          fullname
          firstname
          name
          title
          mobile
          jobtitle
          joinMode
          logoUrl(size: "123")
          status
          zohoId
          hasEmailVerified
          dateLastActivity
          createdDate
          timezone
          needlTcAccepted
          company {
            idcompany
            name
            type
          }
        }
      }
    `,

    memberBuyingScope: gql`
      query BOQueryGetMemberBuyingScope($idmember: Int!) {
        member(idmember: $idmember) {
          idmember
          buyingScopeCategories: buyingScopeTags(tagTypeInternalName: "${TagTypeService.CATEGORY_INTERNAL_NAME}") {
            items {
              id
              name
              depth
              parent {
                id
                name
                depth
              }
            }
          }
          buyingScopeActivities: buyingScopeTags(tagTypeInternalName: "${TagTypeService.ACTIVITY_INTERNAL_NAME}") {
            items {
              id
              name
              parent {
                id
                name
              }
            }
          }
        }
      }
    `,

    hermesMemberNotifications: gql`
      query BOQueryGetHermesMemberNotifications($idmember: Int!) {
        hermesMemberNotifications(idmember: $idmember)
      }
    `,

    memberSessions: gql`
      query BOQueryGetMemberSessions($idmember: Int!) {
        memberSessions(idmember: $idmember)
      }
    `,
  };

  static mutations = {

    insertMember: gql`
      mutation BOMutationCreateMemberFromZohoId($zohoId: String!, $cascade: Boolean!) {
        insertMember(zohoId: $zohoId, cascade: $cascade) {
          ...fullMember
        }
      }
      ${MemberGraphQL.fragments.full}
    `,

    insertMembers: gql`
      mutation BOMutationCreateMembersFromZohoId($zoho_id_list: [String!]!, $cascade: Boolean!) {
        insertMembers(zoho_id_list: $zoho_id_list, cascade: $cascade) {
          ...lightMember
        }
      }
      ${MemberGraphQL.fragments.light}
    `,

    sendEmail: gql`
      mutation BOMutationSendEmailToMember($id: Int!, $email_id: Int!, $eventCode: String) {
        sendEmail(id: $id, email_id: $email_id, eventCode: $eventCode) {
          ...lightMember
        }
      }
      ${MemberGraphQL.fragments.light}
    `,

    sendEmails: gql`
      mutation BOMutationSendEmailsToMember($ids: [Int!]!, $email_id: Int!, $eventCode: String) {
        sendEmails(ids: $ids, email_id: $email_id, eventCode: $eventCode) {
          ...lightMember
        }
      }
      ${MemberGraphQL.fragments.light}
    `,

    changeMemberStatus: gql`
      mutation BOMutationUpdateMemberStatus($idmember: Int!, $status: String!, $rejectedReason: Int, $customRejectionReason: String, $rejectCompany: Boolean) {
        changeMemberStatus(idmember: $idmember, status: $status, rejectedReason: $rejectedReason, customRejectionReason: $customRejectionReason, rejectCompany: $rejectCompany) {
          ...memberStatusInfo
        }
      }
      ${MemberGraphQL.fragments.memberStatusInfo}
    `,

    updateCompanyOwnerStatus: gql`
      mutation BOMutationUpdateMemberCompanyOwnership($idmember: Int!, $status: Boolean!) {
        updateCompanyOwnerStatus(idmember: $idmember, status: $status) {
          ...listMember
        }
      }
      ${MemberGraphQL.fragments.list}
    `,

    updateMemberBasicInformation: gql`
      mutation BOMutationUpdateMemberBasicInformation($idmember: Int!, $firstname: String!, $lastname: String!, $email: String!, $mobile: String!, $zohoId: String) {
        updateMemberBasicInformation(idmember: $idmember, firstname: $firstname, lastname: $lastname, email: $email, mobile: $mobile, zohoId: $zohoId) {
          ...listMember
        }
      }
      ${MemberGraphQL.fragments.list}
    `,

    updateMemberName: gql`
      mutation BOMutationUpdateMemberName($idmember: Int!, $firstname: String!, $lastname: String!) {
        updateMemberName(idmember: $idmember, firstname: $firstname, lastname: $lastname) {
          idmember
          firstname
          name
          fullname
        }
      }
    `,

    changePassword: gql`
      mutation BOMutationUpdateMemberPassword($idmember: Int!, $password: String!) {
        savePassword(idmember: $idmember, password: $password) {
          idmember
        }
      }
    `,

    sendEmailPasswordRecovery: gql`
      mutation BOMutationSendEmailPasswordRecoveryToMember($email: String!) {
        sendEmailPasswordRecovery(email: $email) {
          idmember
        }
      }
    `,

    validateEmailOfMember: gql`
      mutation BOMutationValidateEmailOfMember($idmember: Int!) {
        validateEmailOfMember(idmember: $idmember) {
          ...listMember
        }
      }
      ${MemberGraphQL.fragments.list}
    `,

    changeMemberCompany: gql`
      mutation BOMutationChangeCompanyOfMember($idmember: Int!, $idcompany: Int!) {
        changeMemberCompany(idmember: $idmember, idcompany: $idcompany) {
          ...listMember
        }
      }
      ${MemberGraphQL.fragments.list}
    `,
    updateMemberScore: gql`
      mutation BOMutationRecalculateMemberScore($memberId: Int!) {
        updateMemberScore(memberId: $memberId) {
          name,
          percent,
          children {
            name,
            percent,
            error,
            weight
          }
        }
      }
    `,
    disconnectMember: gql`
      mutation BOMutationDisconnectMember($idmember: Int!) {
        disconnectMember(idmember: $idmember) {
          idmember
        }
      }
    `
  };
}
