import {WabelModel} from './wabel-model.abstract';
import {CompanyRelationshipTagReference} from './company_relationship_tag_reference';

export class CompanyRelationshipStatus extends WabelModel {

  constructor(obj?: any) {
    super(obj);
    if (obj) {
      this.idrelationshipStatus = obj.idrelationshipStatus ? +obj.idrelationshipStatus : 0;
      this.tags = obj.tags ? obj.tags.map(t => new CompanyRelationshipTagReference(t)) : [];
    }
  }

  idrelationshipStatus: number;
  name: string;
  color: string;
  tags: CompanyRelationshipTagReference[];
  canHaveMultipleTags: boolean;

  get id(): number {
    return this.idrelationshipStatus;
  }
}
