import {WabelModel} from './wabel-model.abstract';

export class EventPackage extends WabelModel {

  constructor(obj: any = null) {
    super(obj);
    if (obj) {
      this.id = obj.id ? +obj.id : 0;
    }
  }

  id: number;
  name: string;
  nbMaxMeetingsPerSummit: number;
}
