import gql from 'graphql-tag';

export class TagTypeGraphql {
  static fragments = {
    fullTag: gql`
      fragment fullTag on Tag {
        id
        name
        depth
        type {
          name
          internalName
        }
        parent {
          name
          id
          depth
          parent {
            name
            id
            depth
          }
        }
        children {
          items {
            name
            id
            depth
            parent {
              name
              id
              depth
            }
            children {
              items {
                name
                id
                depth
                parent {
                  name
                  id
                  depth
                }
              }
            }
          }
        }
      }
    `,
    tag: gql`
      fragment tag on Tag {
        id
        name
        depth
        parent {
          id
          name
        }
      }
    `,
    customTag: gql`
      fragment customTag on CustomTag {
        id
        name
      }
    `,
  };
  static queries = {
    fullTagTypeByInternalName: gql`
      query BOQueryGetTagTypesByInternalName($tagTypeInternalName: String!) {
        tagTypeByInternalName(tagTypeInternalName: $tagTypeInternalName){
          id
          name
          tags {
            items {
              ...fullTag
            }
          }
        }
      }${TagTypeGraphql.fragments.fullTag}
    `,
    twoLevelsTagsByInternalNameAndDepth: gql`
      query BOQueryGetTagsByInternalNameAndDepth($tagTypeInternalName: String!, $depth: Int!) {
        tagsByInternalNameAndDepth(tagTypeInternalName: $tagTypeInternalName, depth: $depth) {
          items {
            id
            name
            children {
              items {
                id
                name
              }
            }
          }
        }
      }
    `,
    tagTypeByInternalName: gql`
      query BOQueryGetTagTypesByInternalNameWithTagChild($tagTypeInternalName: String!, $onlyRoot: Boolean) {
        tagTypeByInternalName(tagTypeInternalName: $tagTypeInternalName){
          id
          name
          tags(onlyLevel0: $onlyRoot) {
            items {
              ...tag
            }
          }
        }
      }${TagTypeGraphql.fragments.tag}
    `,
    allTags: gql`
      query BOQueryGetTags($excludedInternalNames: [String!]) {
        allTags(excludedInternalNames: $excludedInternalNames) {
          items {
            ...fullTag
          }
        }
      }${TagTypeGraphql.fragments.fullTag}
    `,
  };

  static mutations = {
    addNewTag: gql`
      mutation BOMutationAddTag($name: String!, $typeId: Int!, $parentId: Int) {
        addNewTag(name: $name, typeId: $typeId, parentId: $parentId) {
          id
          name
        }
      }
    `,
    updateTagName: gql`
      mutation BOMutationUpdateTagName($name: String!, $tagId: Int!) {
        updateTagName(name: $name, tagId: $tagId) {
          id
          name
        }
      }
    `,
    regenerateTagTrees: gql`
      mutation BOMutationRegenerateTagTrees {
        regenerateTagTrees
      }
    `,
  };
}
