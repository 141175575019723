import {Injectable} from '@angular/core';
import {Event} from '@models/event';
import {Member} from '@models/member';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class EventNavigationService implements Resolve<Date> {

  private internalEvent: Event = null;
  days: Date[];
  startHour: number;
  endHour: number;
  liveMode = false;

  get event() {
    return this.internalEvent;
  }

  set event(event: Event) {
    this.internalEvent = event;
    this.days = [];
    this.startHour = null;
    this.endHour = null;
    if (this.event) {
      const endDate = moment(this.event.end);
      this.startHour = this.event.start.getHours();
      this.endHour = endDate.hours();
      for (const startDate = moment(this.event.start); endDate.diff(startDate, 'days', true) > 0; startDate.add(1, 'd')) {
        this.days.push(startDate.startOf('day').toDate());
      }
    }
  }

  get eventCode() {
    return this.event ? this.event.eventCode : null;
  }

  resolve(route: ActivatedRouteSnapshot): Date {
    return route.params.days ? this.days[route.params.days - 1] : null;
  }

  generateMasqueradeLinks(member: Member) {
    const links = [
      {url: this.event.eventOrganizer.link + '/' + this.event.eventCode, label: 'Summit Lobby'},
      {url: this.event.eventOrganizer.link, label: 'My Event dashboard'},
    ];
    if (member.company.isRetailer) {
      links.push(
        {url: this.event.eventOrganizer.link + '/' + this.event.eventCode + '/selection', label: 'Selection tool'},
      );
      links.push(
        {url: this.event.eventOrganizer.link + '/register', label: 'Event registration'}
      );
    }
    return links;
  }
}
