import {Event} from './event';
import {WabelModel} from './wabel-model.abstract';
import {EventOrganizer} from './event_organizer';
import {Member} from './member';
import {Role} from './role';

export class MemberRole extends WabelModel {
  constructor(obj: any) {
    super(obj);
    if (obj) {
      this.id = obj.id ? +obj.id : 0;
      this.member = obj.member ? new Member(obj.member) : null;
      this.role = obj.role ? new Role(obj.role) : null;
      this.event = obj.certainEvent ? new Event(obj.certainEvent) : null;
      this.eventOrganizer = obj.eventOrganizer ? new EventOrganizer(obj.eventOrganizer) : null;
    }
  }

  id: number;
  member: Member;
  role: Role;
  event: Event;
  eventOrganizer: EventOrganizer;
}
