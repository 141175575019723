import {WabelModel} from './wabel-model.abstract';
import {Registration} from './registration';
import {EventWorkshop} from './event_workshop';

export class EventWorkshopPreference extends WabelModel {

  constructor(obj?: any) {
    super(obj);
    if (obj) {
      this.ideventworkshoppreference = obj.ideventworkshoppreference ? +obj.ideventworkshoppreference : 0;
      this.registration = obj.registration ? new Registration(obj.registration) : null;
      this.eventWorkshop = obj.eventworkshop ? new EventWorkshop(obj.eventworkshop) : null;
    }
  }

  ideventworkshoppreference: number;
  registration: Registration;
  eventWorkshop: EventWorkshop;
  creationType: string;
  dateCreated: Date;

  get id(): number {
    return +this.ideventworkshoppreference;
  }
}
