import {Member} from './member';
import {Tag} from './tag';
import {WabelModel} from './wabel-model.abstract';
import {CompanyProfileFeedback} from './company_profile_feedback';
import {SourcingNeedRecommendationMemberResponse} from './sourcing_need_recommendation_member_response';

export class SourcingNeed extends WabelModel {
  constructor(obj: any) {
    super(obj);

    if (obj) {
      this.id = obj.id ? +obj.id : 0;
      this.member = obj.member ? new Member(obj.member) : null;
      this.activities = obj.activities && obj.activities.items ? obj.activities.items.map(activity => new Tag(activity)) : [];
      this.certifications = obj.certifications && obj.certifications.items ? obj.certifications.items.map(certification => new Tag(certification)) : [];
      this.specifications = obj.specifications && obj.specifications.items ? obj.specifications.items.map(specification => new Tag(specification)) : [];
      this.countries = obj.countries ? obj.countries.map(country => new Tag(country)) : [];
      this.categorytag = obj.categorytag ? new Tag(obj.categorytag) : null;
      this.buyerSubmissionFeedbacks = obj.buyerSubmissionFeedbacks && obj.buyerSubmissionFeedbacks.items ? obj.buyerSubmissionFeedbacks.items.map(feedback => new CompanyProfileFeedback(feedback)) : [];
      this.continents = obj.continents ? obj.continents.map(continent => new Tag(continent)) : [];
      this.sourcingNeedRecommendationMemberResponseByIdMember = obj.sourcingNeedRecommendationMemberResponseByIdMember ? new SourcingNeedRecommendationMemberResponse(obj.sourcingNeedRecommendationMemberResponseByIdMember) : null;
      this.newRecommendationsNumber = obj.newRecommendations && obj.newRecommendations.count ? obj.newRecommendations.count : null;
      this.newRecommendationsWithOnlyActiveMembersNumber = obj.newRecommendationsWithOnlyActiveMembers && obj.newRecommendationsWithOnlyActiveMembers.count ? obj.newRecommendationsWithOnlyActiveMembers.count : null;
    }
  }

  id: number;
  name: string;
  member: Member;
  active: boolean;
  timeline: string;
  deadline: string;
  createdDate: string;
  updatedDate: string;
  specifications: Tag[];
  certifications: Tag[];
  activities: Tag[];
  countries: Tag[];
  daysLeft: number;
  searchEngineCalled: boolean;
  categorytag: Tag;
  recommendationsNumber: number;
  newRecommendationsNumber: number;
  newRecommendationsWithOnlyActiveMembersNumber: number;
  acceptedSubmissionNumber: number;
  declinedSubmissionNumber: number;
  allSubmissionNumber: number;
  buyerSubmissionFeedbacks: CompanyProfileFeedback[];
  continents: Tag[];
  sourcingNeedRecommendationMemberResponseByIdMember: SourcingNeedRecommendationMemberResponse;
}
