import {Injectable, TemplateRef} from '@angular/core';
import {AlertComponent} from '../components/alert/alert.component';
import {AlertConfig} from '../../types';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarRef,
  MatSnackBarVerticalPosition
} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  constructor(private snackbar: MatSnackBar) {
  }

  snackbarRef: MatSnackBarRef<any>;

  openAlert(content: TemplateRef<any>, context: any, data: AlertConfig) {
    if (content) {
      this.snackbarRef = this.snackbar.openFromComponent(AlertComponent, {
        data: {
          content: content,
          context: context,
          closeText: data && data.closeText ? data.closeText : null,
          moreDetails: data && data.moreDetails ? data.moreDetails : null,
          moreDetailsText: data && data.moreDetailsText ? data.moreDetails : null,
        },
        horizontalPosition: data && data.xPosition ? data.xPosition : 'right',
        verticalPosition: data && data.yPosition ? data.yPosition : 'top',
        panelClass: data && data.classes ? data.classes : ''
      });
    }
  }

  sendAlert(text: string, dismissText: string = "Dismiss", announcement: string = null, duration: number = null, panelClass: string | string[] = null, xPosition: MatSnackBarHorizontalPosition = 'right', yPosition: MatSnackBarVerticalPosition = 'top') {
    this.snackbarRef = this.snackbar.open(text, dismissText, {
      horizontalPosition: xPosition,
      verticalPosition: yPosition,
      panelClass: panelClass,
      duration: duration,
      announcementMessage: announcement
    });
  }

  closeSnackbar() {
    if (this.snackbarRef) {
      this.snackbarRef.dismiss()
    }
  }
}
