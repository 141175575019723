import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatTableModule} from '@angular/material/table';
import {CdkTableModule} from '@angular/cdk/table';
import {Column} from './directives/column.directive';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {TableAction} from './directives/table-action.directive';
import {MatSortModule} from '@angular/material/sort';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {Filters} from './directives/filters.directive';
import {FilterCallback} from './directives/filter-callback.directive';
import {TableBulkActions} from './directives/table-bulk-actions.directive';
import {TableComponent} from './components/table/table.component';
import {WabelBOLayoutModule} from '../layout/layout.module';

@NgModule({
  imports: [
    CommonModule,
    MatTableModule,
    MatPaginatorModule,
    CdkTableModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatSortModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    WabelBOLayoutModule
  ],
  declarations: [
    TableComponent,
    Column,
    Filters,
    TableAction,
    TableBulkActions,
    FilterCallback,
  ],
  exports: [
    TableComponent,
    Column,
    Filters,
    TableAction,
    TableBulkActions,
    FilterCallback,
  ],
})
export class WabelBOTableModule {
}
