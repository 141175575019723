import {Component} from '@angular/core';
import {slideInLeftAnimation, slideInRightAnimation, slideInTopAnimation} from './animations/slide-in.animation';
import {fadeAnimation} from './animations/fade.animation';
import {ActivatedRoute} from '@angular/router';
import {CacheService} from '@services/cache.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  animations: [fadeAnimation, slideInLeftAnimation, slideInRightAnimation, slideInTopAnimation],
})
export class AppComponent {

  showTargetInfo: boolean;
  searchOpened: boolean;
  hideHeader: boolean;

  constructor(
    private activatedRoute: ActivatedRoute,
    private cacheService: CacheService
  ) {
    this.showTargetInfo = true;
    this.searchOpened = false;
    this.hideHeader = false;
    this.cacheService.hideHeader = this.hideHeader;
    this.activatedRoute.queryParamMap.subscribe(queryParams => {
      if (queryParams.has('hideHeader')) {
        this.hideHeader = queryParams.get('hideHeader') === 'true' || queryParams.get('hideHeader') === '1';
        this.cacheService.hideHeader = this.hideHeader;
      }
    });
    this.cacheService.loadCategories();
  }
}
