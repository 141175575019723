import {Injectable} from '@angular/core';
import Timer = NodeJS.Timer;

@Injectable({
  providedIn: 'root'
})
export class InfoService {
  hiddenOnTablet = true;
  top = false;
  private internalText: string = null;
  private internalTimer: Timer = null;

  get text(): string {
    return this.internalText;
  }

  set text(value: string) {
    if (this.internalTimer) {
      clearTimeout(this.internalTimer);
    }
    this.internalText = value;
    // Remove the banner after 15 sec to avoid issue when navigating
    this.internalTimer = setTimeout(() => this.internalText = null, 15000);
  }

}
