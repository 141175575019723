import {WabelModel} from './wabel-model.abstract';

export class File extends WabelModel {
  constructor(obj?: any) {
    super(obj);
    if (obj) {
      this.idfile = obj.idfile ? +obj.idfile : 0;
    }
  }

  idfile: number;
  summitCode: string;
  filePath: string;
  fileSize: number;
  fileExtension: string;
  description: string;
  fileName: string;
  dateCreated: string;
  dateUpdated: string;
  createdBy: number;
  updatedBy: number;
  downloadLink: string;
  url: string;

  get id() {
    return this.idfile;
  }

  getFileName() {
    return this.filePath.replace('/var/www/html/sites/all/custom/resources/summit_files/', '');
  }
}
