import {WabelModel} from './wabel-model.abstract';

export class Score extends WabelModel {
  constructor(obj: any) {
    super(obj);

    if (obj) {
      this.children = obj.children ? obj.children.map(child => new Score(child)) : [];
    }
  }

  name: string;
  value: number;
  percent: number;
  weight: number;
  isEliminatory: boolean;
  error: string;
  children: Score[];

  get id() {
    return this.name;
  }
}
