import {WabelModel} from './wabel-model.abstract';

export class PackagingUnit extends WabelModel {

  constructor(obj: any = null) {
    super(obj);
    if (obj) {
      this.idpackagingunit = obj.idpackagingunit ? +obj.idpackagingunit : 0;
    }
  }
  idpackagingunit: number;
  position: number;
  status: string;
  name: string;

  get id() {
    return this.idpackagingunit;
  }
}
