import {Injectable} from '@angular/core';
import {TagTypeService} from './wabel-client/tag_type.service';
import {TagType} from '@models/tag_type';
import {Observable, of} from 'rxjs';
import {Tag} from '@models/tag';

@Injectable({
  providedIn: 'root'
})
export class CacheService {

  private categories: TagType;
  private parentCategoriesByIdCategories: { [id: number]: Tag } = {};
  private flatCategories: Tag[];
  public hideHeader = false;

  constructor(private tagTypeService: TagTypeService) {
  }

  loadCategories() {
    this.tagTypeService.getFullTagTypeByInternalName(TagTypeService.CATEGORY_INTERNAL_NAME).subscribe((data) => {
      if (!data) {
        return;
      }
      data.tags = data.tags.filter(tag => tag.machineName !== 'packaging');
      this.categories = data;
      this.buildParentCategoriesByIdCategory();
    });
  }

  private buildParentCategoriesByIdCategory() {
    this.parentCategoriesByIdCategories = {};
    this.flatCategories = [];
    for (const cat1 of this.categories.tags) {
      this.parentCategoriesByIdCategories[cat1.id] = null;
      this.flatCategories.push(cat1);
      for (const cat2 of cat1.children) {
        this.parentCategoriesByIdCategories[cat2.id] = cat1;
        this.flatCategories.push(cat2);
        for (const cat3 of cat2.children) {
          this.parentCategoriesByIdCategories[cat3.id] = cat2;
          this.flatCategories.push(cat3);
        }
      }
    }
  }

  getCategoriesByDepth(depth: number): Tag[] {
    return this.flatCategories.filter(tag => +tag.depth === depth);
  }

  getParentCategoryByIdCategory(id: number): Tag {
    return this.parentCategoriesByIdCategories[id];
  }

  getCategories(): Observable<TagType> {
    if (!this.categories) {
      return this.tagTypeService.getFullTagTypeByInternalName(TagTypeService.CATEGORY_INTERNAL_NAME);
    }
    return of(this.categories);
  }
}
