import {NgModule} from '@angular/core';
import {APOLLO_OPTIONS, ApolloModule} from 'apollo-angular';
import {ApolloClientOptions, InMemoryCache} from '@apollo/client/core';
import {HttpLink} from 'apollo-angular/http';
import {environment} from '../environments/environment';
import {HttpClientModule} from '@angular/common/http';
import {createUploadLink} from 'apollo-upload-client';
import {persistCache} from 'apollo-cache-persist';

const uri = environment.needl_back_end_url + '/graphql';
const cache = new InMemoryCache({
  possibleTypes: {
    CompanyInterface: ['Company', 'PurchasingGroup', 'BuyingOffice'],
    CertainEventRegistrationInterface: ['CertainEventRegistration', 'BuyerEventRegistration']
  },
  typePolicies: {
    PorpaginasResult_TagInterface: {
      merge: true,
    },
    PorpaginasResult_CertainEventRegistrationInterface: {
      merge: true,
    },
    PurchasingGroup: {
      keyFields: ['idcompany'],
      merge: true,
    },
    Company: {
      keyFields: ['idcompany'],
      merge: true,
    },
    BuyingOffice: {
      keyFields: ['idcompany'],
      merge: true,
    },
    Member: {
      keyFields: ['idmember'],
      merge: true,
    },
    CertainEvent: {
      keyFields: ['eventCode'],
      merge: true,
    },
    Companytype: {
      keyFields: ['idcompanytype'],
      merge: true,
    },
    EventOrganizer: {
      keyFields: ['ideventorganizer'],
      merge: true,
    },
  },
});

persistCache({
  cache,
  storage: localStorage,
});

export function createApollo(): ApolloClientOptions<any> {
  return {
    link: createUploadLink({
      uri,
      withCredentials: true,
      credentials: 'include',
    }),
    cache,
  };
}

@NgModule({
  imports: [
    HttpClientModule,
    ApolloModule
  ],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule {
}
