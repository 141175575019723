import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './pages/home/home.component';
import {AccessDeniedComponent} from './pages/access-denied/access-denied.component';
import {IsWabelAdminGuard} from './guards/isWabelAdmin.guard';

const appRoutes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [IsWabelAdminGuard]
  },
  {
    path: 'company',
    loadChildren: () => import('./pages/companies/companies.module').then(mod => mod.CompaniesModule),
    canActivate: [IsWabelAdminGuard]
  },
  {
    path: 'member',
    loadChildren: () => import('./pages/members/members.module').then(mod => mod.MembersModule),
    canActivate: [IsWabelAdminGuard]
  },
  {
    path: 'event',
    loadChildren: () => import('./pages/events/events.module').then(mod => mod.EventsModule),
    canActivate: [IsWabelAdminGuard]
  },
  {
    path: 'relationships',
    loadChildren: () => import('./pages/relationships/relationships.module').then(mod => mod.RelationshipsModule),
    canActivate: [IsWabelAdminGuard]
  },
  {
    path: 'tools',
    loadChildren: () => import('./pages/tools/tools.module').then(mod => mod.ToolsModule),
    canActivate: [IsWabelAdminGuard]
  },
  {
    path: 'sourcing',
    loadChildren: () => import('./pages/sourcing/sourcing.module').then(mod => mod.SourcingModule),
    canActivate: [IsWabelAdminGuard]
  },
  {
    path: 'doc',
    loadChildren: () => import('./pages/documentation/documentation.module').then(mod => mod.DocumentationModule),
    canActivate: [IsWabelAdminGuard]
  },
  {
    path: 'eee',
    loadChildren: () => import('./pages/eee/eee.module').then(mod => mod.EeeModule),
    canActivate: [IsWabelAdminGuard]
  },
  {
    path: 'institutions',
    loadChildren: () => import('./pages/institutions/institutions.module').then(mod => mod.InstitutionsModule),
    canActivate: [IsWabelAdminGuard]
  },
  {
    path: 'video-meetings',
    loadChildren: () => import('./pages/video-meetings/video-meetings.module').then(mod => mod.VideoMeetingsModule),
    canActivate: [IsWabelAdminGuard]
  },
  {
    path: 'access-denied',
    component: AccessDeniedComponent,
    data: {
      breadcrumbs: null
    }
  }
];


@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {enableTracing: false, relativeLinkResolution: 'legacy'})
  ],
  exports: [
    RouterModule,
  ]
})
export class AppRoutingModule {
}
