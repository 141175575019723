import {Directive, HostListener, Input, TemplateRef} from '@angular/core';
import {SidePanelService} from '../services/side-panel.service';

@Directive({
  selector: '[wboSidePanel]'
})
export class SidePanelDirective {
  @Input("wboSidePanel") content: TemplateRef<any>;
  @Input("wboContext") context: any;
  @Input("wboSidePanelPosition") position: "left" | "right" = "left";
  @Input("wboSidePanelMode") mode: "over" | "push" | "side" = "over";
  @Input("wboSidePanelWidth") width: string = "50%";

  constructor(private sidePanelService: SidePanelService) {
  }

  @HostListener('click') onClick() {
    this.sidePanelService.open(this.content, this.context, this.width, this.position, this.mode);
  }
}
