import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {SidePanelService} from '../../services/side-panel.service';
import {MatSidenav} from '@angular/material/sidenav';
import {LoadingService} from '../../services/loading.service';
import {TopBarService} from '../../services/top-bar.service';
import {Observable} from 'rxjs';

@Component({
  selector: 'wbo-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements AfterViewInit {

  @ViewChild(MatSidenav, {static: true}) sideNav: MatSidenav;

  constructor(
    public sidePanelService: SidePanelService,
    public loadingService: LoadingService,
    public topBarService: TopBarService
  ) {
  }

  get isRequestLoading(): Observable<boolean> {
    return this.loadingService.requestInProcess$;
  }

  ngAfterViewInit(): void {
    this.sidePanelService.sidePanelRef = this.sideNav;
  }
}
