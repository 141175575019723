import {Component, HostBinding, Input} from '@angular/core';

@Component({
  selector: 'wbo-overlay-loader',
  templateUrl: './overlay-loader.component.html',
  styleUrls: ['./overlay-loader.component.scss']
})
export class OverlayLoaderComponent {
  @Input() inModal = false;

  @HostBinding('style.position') get position() {
    return this.inModal ? 'absolute' : 'fixed';
  }

  @HostBinding('style.z-index') get zIndex() {
    return this.inModal ? '3' : '0';
  }

  constructor() {
  }

}
