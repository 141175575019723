<nav *wboTopBar mat-tab-nav-bar class="w-100">
  <a mat-tab-link
     [routerLink]="'forms'"
     routerLinkActive #rla="routerLinkActive"
     [active]="rla.isActive">
    Forms Components
  </a>
</nav>

<router-outlet></router-outlet>
